import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { TranslateBase } from '~/bases/translate.base';

@Component({
    selector: 'history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss']
})
export class HistoryComponent extends TranslateBase implements OnInit, OnDestroy {

    constructor(
        injector: Injector,
    ) {
        super(injector);
    }
}
