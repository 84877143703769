import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ILanguages } from '~/interfaces/languages';

@Injectable({
    providedIn: 'root'
})
export class TranslatorService {

    readonly langs: ILanguages = {
        en: { name: 'language', content: 'English' },
        ru: { name: 'language', content: 'Russian' },
        ua: { name: 'language', content: 'Ukrainian' },
    };

    constructor(
        @Inject(TranslateService) private translateService: TranslateService,
        @Inject(Router) private router: Router,
    ) { }

    /** value takes from actual url */
    get segments() {
        const children = this.router.parseUrl(this.router.url).root.children;
        return children && children.primary && children.primary.segments;
    }

    /** value takes from actual url */
    get activated() {
        const segments = this.segments;
        return Array.isArray(segments) && segments[0] && segments[0].path;
    }

    use = () => this.translateService.use(this.activated);

    navigate = (lang: string): void => {
        const tree = this.segments.map(x => x.path);
        this.router.navigate([lang, ...tree.slice(1)]);
    }
}
