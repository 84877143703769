<div class="clearfix"></div>
<section id="home">
    <header id="theanchor">
        <div class="header-content-inner text-center" [class.russian]="prefix === '/ru' || prefix === '/ua'">
            <h1>{{'HOME_INNOVATIVE_H1' | translate}}</h1>
            <h2>{{'HOME_IS_HOW_WE_WORK_H2' | translate}}</h2>
        </div>
        <div class="header-content-bottom text-center">
            <a pageScroll href="#product" class="btn btn-link">
                <img src="/assets/images/scroll-icon.png?v={{version}}" alt="{{'HOME_SCROLL_DOWN_ICON' | translate}}" />
            </a>
        </div>
        <div class="clearfix"></div>
    </header>
</section>

<section id="product" [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <article>
        <div class="container">
            <h2 class="text-center">{{'HOME_WE_ARE_MASTERS_IN_THE_FOLLOWING_H2' | translate}}</h2>
            <div class="row">
                <div class="col-md-4 text-center pull-left">
                    <a title="{{'HOME_IMG_WEB_APPLICATION_TITLE' | translate}}" [routerLink]="[prefix, 'web-applications']">
                        <img data-src="/assets/images/web-application.png?v={{version}}" alt="{{'HOME_IMG_WEB_APPLICATION_ALT' | translate}}" title="{{'HOME_IMG_WEB_APPLICATION_TITLE' | translate}}" class="lazyload wow bounceIn" data-wow-delay=".2s" />
                    </a>
                    <h3>{{'HOME_IMG_WEB_APPLICATION' | translate}}</h3>
                </div>
                <div class="col-md-4 text-center pull-left">
                    <a title="{{'HOME_IMG_SOFTWARE_DEVELOPMENT_TITLE' | translate}}" [routerLink]="[prefix, 'custom-software']">
                        <img data-src="/assets/images/software-development.png?v={{version}}" alt="{{'HOME_IMG_SOFTWARE_DEVELOPMENT_ALT' | translate}}" title="{{'HOME_IMG_SOFTWARE_DEVELOPMENT_TITLE' | translate}}" class="lazyload wow bounceIn" data-wow-delay=".3s" />
                    </a>
                    <h3>{{'HOME_IMG_SOFTWARE_DEVELOPMENT' | translate}}</h3>
                </div>

                <div class="col-md-4 text-center pull-left">
                    <a title="{{'HOME_IMG_IT_CONSULTING_TITLE' | translate}}" [routerLink]="[prefix, 'it-consulting']">
                        <img data-src="/assets/images/it-consulting.png?v={{version}}" alt="{{'HOME_IMG_IT_CONSULTING_ALT' | translate}}" title="{{'HOME_IMG_IT_CONSULTING_TITLE' | translate}}" class="lazyload wow bounceIn" data-wow-delay=".4s" />
                    </a>
                    <h3>{{'HOME_IMG_IT_CONSULTING' | translate}}</h3>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </article>
</section>

<section id="testimonials" [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2 class="text-center">{{'HOME_WHAT_CLIENTS_SAY_H2' | translate}}</h2>
                <div id="carousel-example-generic" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                        <ngb-carousel [interval]="interval" role="listbox" aria-labelledby="carousel-example-generic">
                            <ng-template ngbSlide>
                                <div class="item" role="option">
                                    <blockquote>
                                        <div class="testimonial-content"><p>{{'HOME_TESTIMONIAL_SARAH' | translate}}</p></div>
                                        <footer><span class="testimonial-name">{{'HOME_TESTIMONIAL_POSITION_SARAH' | translate}}</span></footer>
                                    </blockquote>
                                </div>
                            </ng-template>

                            <ng-template ngbSlide>
                                <div class="item" role="option">
                                    <blockquote>
                                        <div class="testimonial-content"><p>{{'HOME_TESTIMONIAL_INTEL_MANAGER' | translate}}</p></div>
                                        <footer><span class="testimonial-name">{{'HOME_TESTIMONIAL_INTEL_POSITION' | translate}}</span></footer>
                                    </blockquote>
                                </div>
                            </ng-template>

                            <ng-template ngbSlide>
                                <div class="item" role="option">
                                    <blockquote>
                                        <div class="testimonial-content"><p>{{'HOME_TESTIMONIAL_ROBERT' | translate}}</p></div>
                                        <footer>
                                            <span class="testimonial-name">{{'HOME_TESTIMONIAL_NAME_ROBERT' | translate}}</span><br />
                                            {{'HOME_TESTIMONIAL_POSITION_ROBERT' | translate}}
                                        </footer>
                                    </blockquote>
                                </div>
                            </ng-template>

                            <ng-template ngbSlide>
                                <div class="item" role="option">
                                    <blockquote>
                                        <div class="testimonial-content"><p>{{'HOME_TESTIMONIAL_JONATHAN' | translate}}</p></div>
                                        <footer>
                                            <span class="testimonial-name">{{'HOME_TESTIMONIAL_NAME_JONATHAN' | translate}}</span><br />
                                            {{'HOME_TESTIMONIAL_POSITION_JONATHAN' | translate}}<br />
                                            {{'HOME_TESTIMONIAL_ADDRESS_JONATHAN' | translate}}
                                        </footer>
                                    </blockquote>
                                </div>
                            </ng-template>

                            <ng-template ngbSlide>
                                <div class="item" role="option">
                                    <blockquote>
                                        <div class="testimonial-content"><p>{{'HOME_TESTIMONIAL_DON' | translate}}</p></div>
                                        <footer>
                                            <span class="testimonial-name">{{'HOME_TESTIMONIAL_NAME_DON' | translate}}</span><br />
                                            {{'HOME_TESTIMONIAL_POSITION_DON' | translate}}<br />
                                            {{'HOME_TESTIMONIAL_ADDRESS_DON' | translate}}
                                        </footer>
                                    </blockquote>
                                </div>
                            </ng-template>

                            <ng-template ngbSlide>
                                <div class="item" role="option">
                                    <blockquote>
                                        <div class="testimonial-content"><p>{{'HOME_TESTIMONIAL_MICHAEL' | translate}}</p></div>
                                        <footer>
                                            <span class="testimonial-name">{{'HOME_TESTIMONIAL_NAME_MICHAEL' | translate}}</span>
                                        </footer>
                                    </blockquote>
                                </div>
                            </ng-template>
                        </ngb-carousel>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</section>

<section id="technologies" [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <div class="container">
        <div class="row block-box">

            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6 pull-left">
                <a title="{{'HOME_IMG_CSHARP_PROGRAMMING_LANGUAGE_TITLE' | translate}}" href="#" preventDefault (click)="scrollToRaute('technologies', 'csharp')">
                    <img class="lazyload" data-src="/assets/images/technologies-logo/csharp.png?v={{version}}" alt="{{'HOME_IMG_CSHARP_PROGRAMMING_LANGUAGE_ALT' | translate}}" title="{{'HOME_IMG_CSHARP_PROGRAMMING_LANGUAGE_TITLE' | translate}}" />
                </a>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6 pull-left">
                <a title="{{'HOME_IMG_TYPESCRIPT_PROGRAMMING_LANGUAGE_TITLE' | translate}}" href="#" preventDefault (click)="scrollToRaute('technologies', 'typescript')">
                    <img class="lazyload" data-src="/assets/images/technologies-logo/typescript.png?v={{version}}" alt="{{'HOME_IMG_TYPESCRIPT_PROGRAMMING_LANGUAGE_ALT' | translate}}" title="{{'HOME_IMG_TYPESCRIPT_PROGRAMMING_LANGUAGE_TITLE' | translate}}" />
                </a>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6 pull-left">
                <a title="{{'HOME_IMG_TYPESCRIPT_PROGRAMMING_LANGUAGE_TITLE' | translate}}" href="#" preventDefault (click)="scrollToRaute('technologies', 'angular')">
                    <img class="lazyload" data-src="/assets/images/technologies-logo/angular.png?v={{version}}" alt="{{'HOME_IMG_ANGULAR_FRAMEWORK_ALT' | translate}}" title="{{'HOME_IMG_ANGULAR_FRAMEWORK_TITLE' | translate}}" />
                </a>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6 pull-left">
                <a title="{{'HOME_IMG_JAVASCRIPT_PROGRAMMING_LANGUAGE_TITLE' | translate}}" href="#" preventDefault (click)="scrollToRaute('technologies', 'javascript')">
                    <img class="lazyload" data-src="/assets/images/technologies-logo/javascript.png?v={{version}}" alt="{{'HOME_IMG_JAVASCRIPT_PROGRAMMING_LANGUAGE_ALT' | translate}}" title="{{'HOME_IMG_JAVASCRIPT_PROGRAMMING_LANGUAGE_TITLE' | translate}}" />
                </a>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6 pull-left">
                <a title="{{'HOME_IMG_ANGULARJS_FRAMEWORK_TITLE' | translate}}" href="#" preventDefault (click)="scrollToRaute('technologies', 'angular')">
                    <img class="lazyload" data-src="/assets/images/technologies-logo/angular-js.png?v={{version}}" alt="{{'HOME_IMG_ANGULARJS_FRAMEWORK_ALT' | translate}}" title="{{'HOME_IMG_ANGULARJS_FRAMEWORK_TITLE' | translate}}" />
                </a>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6 pull-left">
                <a title="{{'HOME_IMG_WORDPRESS_FRAMEWORK_TITLE' | translate}}" href="#" preventDefault (click)="scrollToRaute('technologies', 'wordpress')">
                    <img class="lazyload" data-src="/assets/images/technologies-logo/wordpress.png?v={{version}}" alt="{{'HOME_IMG_WORDPRESS_FRAMEWORK_ALT' | translate}}" title="{{'HOME_IMG_WORDPRESS_FRAMEWORK_TITLE' | translate}}" />
                </a>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6 pull-left">
                <a title="{{'HOME_IMG_ASP_DOT_NET_FRAMEWORK_TITLE' | translate}}" href="#" preventDefault (click)="scrollToRaute('technologies', 'aspdotnet')">
                    <img class="lazyload" data-src="/assets/images/technologies-logo/asp-dot-net.png?v={{version}}" alt="{{'HOME_IMG_ASP_DOT_NET_FRAMEWORK_ALT' | translate}}" title="{{'HOME_IMG_ASP_DOT_NET_FRAMEWORK_TITLE' | translate}}" />
                </a>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6 pull-left">
                <a title="{{'HOME_IMG_MICROSOFT_AZURE_CLOUD_TITLE' | translate}}" href="#" preventDefault (click)="scrollToRaute('technologies', 'microsoftAzure')">
                    <img class="lazyload" data-src="/assets/images/technologies-logo/microsoft-azure.png?v={{version}}" alt="{{'HOME_IMG_MICROSOFT_AZURE_CLOUD_ALT' | translate}}" title="{{'HOME_IMG_MICROSOFT_AZURE_CLOUD_TITLE' | translate}}" />
                </a>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6 pull-left">
                <a title="{{'HOME_IMG_AMAZON_WEB_SERVICES_TITLE' | translate}}" href="#" preventDefault (click)="scrollToRaute('technologies', 'amazonwebservices')">
                    <img class="lazyload" data-src="/assets/images/technologies-logo/amazon-web-services.png?v={{version}}" alt="{{'HOME_IMG_AMAZON_WEB_SERVICES_ALT' | translate}}" title="{{'HOME_IMG_AMAZON_WEB_SERVICES_TITLE' | translate}}" />
                </a>
            </div>
            <div class="col-lg-1 col-md-3 col-sm-4 col-xs-6 pull-left">
                <a title="{{'HOME_IMG_DOCKER_TITLE' | translate}}" href="#" preventDefault (click)="scrollToRaute('technologies', 'docker')">
                    <img class="lazyload" data-src="/assets/images/technologies-logo/docker.png?v={{version}}" alt="{{'HOME_IMG_DOCKER_ALT' | translate}}" title="{{'HOME_IMG_DOCKER_TITLE' | translate}}" />
                </a>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6 pull-left">
                <a title="{{'HOME_IMG_WINDOWS_SERVER_TITLE' | translate}}" href="#" preventDefault (click)="scrollToRaute('technologies', 'windowsServer2016')">
                    <img class="lazyload" data-src="/assets/images/technologies-logo/windows-server-2016.png?v={{version}}" alt="{{'HOME_IMG_WINDOWS_SERVER_ALT' | translate}}" title="{{'HOME_IMG_WINDOWS_SERVER_TITLE' | translate}}" />
                </a>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6 pull-left">
                <a title="{{'HOME_IMG_SONICWALL_TITLE' | translate}}" href="#" preventDefault (click)="scrollToRaute('technologies', 'sonicWall')">
                    <img class="lazyload" data-src="/assets/images/technologies-logo/sonic-wall.png?v={{version}}" alt="{{'HOME_IMG_SONICWALL_ALT' | translate}}" title="{{'HOME_IMG_SONICWALL_TITLE' | translate}}" />
                </a>
            </div>
            <div class="col-lg-1 col-md-3 col-sm-4 col-xs-6 pull-left">
                <a title="{{'HOME_IMG_REMOTE_IT_MANAGEMENT_TITLE' | translate}}" href="#" preventDefault (click)="scrollToRaute('technologies', 'remoteManagement')">
                    <img class="lazyload" data-src="/assets/images/technologies-logo/remote-management.png?v={{version}}" alt="{{'HOME_IMG_REMOTE_IT_MANAGEMENT_ALT' | translate}}" title="{{'HOME_IMG_REMOTE_IT_MANAGEMENT_TITLE' | translate}}" />
                </a>
            </div>

            <div class="clearfix"></div>
        </div>
    </div>
</section>
