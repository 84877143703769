import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService {

    static readonly duration = 300;
    readonly positionHideGoUpBtn = 500;
    readonly mobileWidth = 768;
    readonly captchaUrl = new BehaviorSubject('');

    constructor() { }
}
