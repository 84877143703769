import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { TranslateBase } from '~/bases/translate.base';
import { VERSION } from '~/tokens/version.token';

@Component({
    selector: 'custom-software',
    templateUrl: './custom.software.component.html',
    styleUrls: ['./custom.software.component.scss']
})
export class CustomSoftwareComponent extends TranslateBase implements OnInit, OnDestroy {

    constructor(
        injector: Injector,
        @Inject(VERSION) public version: string
    ) {
        super(injector);
    }
}
