/* tslint:disable:max-line-length */
import { Route } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { CareersComponent } from './components/careers/careers.component';
import { CloudComputingComponent } from './components/cloud.computing/cloud.computing.component';
import { CustomSoftwareComponent } from './components/custom.software/custom.software.component';
import { HistoryComponent } from './components/history/history.component';
import { HomeComponent } from './components/home/home.component';
import { IamsapienComponent } from './components/iamsapien/iamsapien.component';
import { ITConsultingComponent } from './components/it.consulting/it.consulting.component';
import { NotFoundComponent } from './components/not.found/not.found.component';
import { OurTeamComponent } from './components/our.team/our.team.component';
import { PrinciplesComponent } from './components/principles/principles.component';
import { CFIComponent } from './components/profiles/alex.sirant/portfolio/cfi/cfi.component';
import { AEMComponent } from './components/profiles/helen.doroshenko/portfolio/aem/aem.component';
import { EcologyComponent } from './components/profiles/helen.doroshenko/portfolio/ecology/ecology.component';
import { OneginBluesComponent } from './components/profiles/helen.doroshenko/portfolio/onegin.blues/onegin.blues.component';
import { PromiseProjectComponent } from './components/profiles/helen.doroshenko/portfolio/promise/promise.component';
import { PulseElectronicsComponent } from './components/profiles/helen.doroshenko/portfolio/pulse.electronics/pulse.electronics.component';
import { YZPNComponent } from './components/profiles/helen.doroshenko/portfolio/yzpn/yzpn.component';
import { HelenDoroshenkoProfileComponent } from './components/profiles/helen.doroshenko/profile/helen.doroshenko.component';
import { PromiseComponent } from './components/promise/promise.component';
import { RockAwayBeachResortComponent } from './components/rockawaybeachresort/rockawaybeachresort.component';
import { TechnologiesComponent } from './components/technologies/technologies.component';
import { WebApplicationsComponent } from './components/web.applications/web.applications.component';

export const RU_ROUTES: Route[] = [
    {
        path: 'home', component: HomeComponent,
        data: {
            title: 'Renet Consulting, специализированная компания по программному обеспечению',
            meta: [
                { name: 'description', content: 'Предоставление услуг ИТ-консалтинга на национальном уровне, включая веб-дизайн, веб-и мобильные приложения, веб-приложения, управление проектами, пользовательское программное обеспечение и пользовательские ИТ-решения. Находится в Портленде, штат Орегон.' },
                { name: 'keywords', content: 'Portland Renet Consulting, разработка пользовательского программного обеспечения, разработка веб-сайтов, Портленд, веб-разработка, разработка баз данных, разработка веб-приложений, разработка мобильных приложений, онлайн-разработка, веб-дизайн, техника юзабилити, AngularJS, Dot Net Core, .NET Core, Entity Framework , JavaScript, Wordpress, разработка веб-приложений, мобильное веб-приложение, сайт для приложения' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/home' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Renet Consulting, специализированная компания по программному обеспечению' },
                { property: 'og:description', content: 'Предоставление услуг ИТ-консалтинга на национальном уровне, включая веб-дизайн, веб-и мобильные приложения, веб-приложения, управление проектами, пользовательское программное обеспечение и пользовательские ИТ-решения. Находится в Портленде, штат Орегон.' },
                { property: 'og:image', content: '/images/bg-header.jpg' }
            ]
        }
    },
    {
        path: 'about', component: AboutComponent,
        data: {
            title: 'О Нас',
            meta: [
                { name: 'description', content: 'Renet Consulting работает с клиентами более 20 лет, создавая пользовательские веб-приложения, работая над веб-программированием, разрабатывая веб-приложения на национальном и местном уровне в Портленде, OR.' },
                { name: 'keywords', content: 'Portland Renet Consulting, разработка пользовательского программного обеспечения, разработка веб-сайтов, Портленд, веб-разработка, разработка баз данных, разработка веб-приложений, разработка мобильных приложений, онлайн-разработка, веб-дизайн, техника юзабилити, AngularJS, Dot Net Core, .NET Core, Entity Framework , JavaScript, Wordpress, разработка веб-приложений, мобильное веб-приложение, сайт для приложения' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/about' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'О Нас' },
                { property: 'og:description', content: 'Renet Consulting работает с клиентами более 20 лет, создавая пользовательские веб-приложения, работая над веб-программированием, разрабатывая веб-приложения на национальном и местном уровне в Портленде, OR.' },
                { property: 'og:image', content: '/images/we-build-custom-software.png' }
            ]
        }
    },
    {
        path: 'history', component: HistoryComponent,
        data: {
            title: 'История Renet Consulting',
            meta: [
                { name: 'description', content: 'Компания Renet Consulting уже более 20 лет предоставляет КАЧЕСТВЕННЫЕ услуги и БЫСТРУЮ разработку веб-приложений, пользовательское программное обеспечение и веб-дизайн более 20 лет на национальном и местном уровне в Портленде, штат Орегон.' },
                { name: 'keywords', content: 'Portland Renet Consulting, разработка пользовательского программного обеспечения, разработка веб-сайтов, Портленд, веб-разработка, разработка баз данных, разработка веб-приложений, разработка мобильных приложений, онлайн-разработка, веб-дизайн, техника юзабилити, AngularJS, Dot Net Core, .NET Core, Entity Framework , JavaScript, Wordpress, разработка веб-приложений, мобильное веб-приложение, сайт для приложения' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/history' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'История Renet Consulting' },
                { property: 'og:description', content: 'Компания Renet Consulting уже более 20 лет предоставляет КАЧЕСТВЕННЫЕ услуги и БЫСТРУЮ разработку веб-приложений, пользовательское программное обеспечение и веб-дизайн более 20 лет на национальном и местном уровне в Портленде, штат Орегон.' },
                { property: 'og:image', content: '/images/bg-header.jpg' }
            ]
        }
    },
    {
        path: 'contact-us', loadChildren: () => import('./components/contact/contact.module').then(m => m.ContactModule),
        data: {
            title: 'Как связаться с Renet Consulting',
            meta: [
                { name: 'description', content: 'Renet Consulting владеет веб-дизайном, разработкой веб-приложений, мобильным веб-приложением, веб-программированием и пользовательским программным обеспечением на национальном и местном уровне, в Портленде, Орегон.' },
                { name: 'keywords', content: 'Portland Renet Consulting, разработка пользовательских программных продуктов, разработка веб-сайтов, Portland, веб-разработка, разработка баз данных, разработка веб-приложений, разработка мобильных приложений, онлайн-разработка, веб-дизайн, юзабилити, Angular, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, разработка веб-приложений, мобильное веб-приложение, сайт для приложения.' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/contact-us' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Как связаться с Renet Consulting' },
                { property: 'og:description', content: 'Renet Consulting владеет веб-дизайном, разработкой веб-приложений, мобильным веб-приложением, веб-программированием и пользовательским программным обеспечением на национальном и местном уровне, в Портленде, Орегон.' },
                { property: 'og:image', content: '/images/bg-contact-us.jpg' }
            ]
        }
    },
    {
        path: 'principles', component: PrinciplesComponent,
        data: {
            title: 'Основные принципы Renet Consulting',
            meta: [
                { name: 'description', content: 'Renet Consulting имеет несколько основных принципов, когда дело доходит до разработки специализированного программного обеспечения' },
                { name: 'keywords', content: 'Portland Renet Consulting, разработка пользовательского программного обеспечения, разработка веб-сайтов, Портленд, веб-разработка, разработка баз данных, разработка веб-приложений, разработка мобильных приложений, онлайн-разработка, веб-дизайн, техника юзабилити, AngularJS, Dot Net Core, .NET Core, Entity Framework , JavaScript, Wordpress, разработка веб-приложений, мобильное веб-приложение, сайт для приложения' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/principles' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Основные принципы Renet Consulting' },
                { property: 'og:description', content: 'Renet Consulting имеет несколько основных принципов, когда дело доходит до разработки специализированного программного обеспечения' },
                { property: 'og:image', content: '/images/individuals-over-tools.png' }
            ]
        }
    },
    {
        path: 'web-applications', component: WebApplicationsComponent,
        data: {
            title: 'Пользовательские веб-приложения',
            meta: [
                { name: 'description', content: 'Renet Consulting владеет разработкой веб-приложений, веб-дизайном, мобильным веб-приложением, веб-программированием и пользовательским программным обеспечением' },
                { name: 'keywords', content: 'Portland Renet Consulting, разработка пользовательского программного обеспечения, разработка веб-сайтов, Портленд, веб-разработка, разработка баз данных, разработка веб-приложений, разработка мобильных приложений, онлайн-разработка, веб-дизайн, техника юзабилити, AngularJS, Dot Net Core, .NET Core, Entity Framework , JavaScript, Wordpress, разработка веб-приложений, мобильное веб-приложение, сайт для приложения' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/web-applications' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Пользовательские веб-приложения' },
                { property: 'og:description', content: 'Renet Consulting владеет разработкой веб-приложений, веб-дизайном, мобильным веб-приложением, веб-программированием и пользовательским программным обеспечением' },
                { property: 'og:image', content: '/images/web-application-img.png' }
            ]
        }
    },
    {
        path: 'it-consulting', component: ITConsultingComponent,
        data: {
            title: 'Пользовательский ИТ-сервис',
            meta: [
                { name: 'description', content: 'Помимо разработки специализированного программного обеспечения, Renet Consulting также предлагает ИТ-услуги, включая удаленное управление' },
                { name: 'keywords', content: 'Portland Renet Consulting, разработка пользовательского программного обеспечения, разработка веб-сайтов, Портленд, веб-разработка, разработка баз данных, разработка веб-приложений, разработка мобильных приложений, онлайн-разработка, веб-дизайн, техника юзабилити, AngularJS, Dot Net Core, .NET Core, Entity Framework , JavaScript, Wordpress, разработка веб-приложений, мобильное веб-приложение, сайт для приложения' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/it-consulting' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Пользовательский ИТ-сервис' },
                { property: 'og:description', content: 'Помимо разработки специализированного программного обеспечения, Renet Consulting также предлагает ИТ-услуги, включая удаленное управление' },
                { property: 'og:image', content: '/images/server-management.png' }
            ]
        }
    },
    {
        path: 'custom-software', component: CustomSoftwareComponent,
        data: {
            title: 'Разработка пользовательского программного обеспечения',
            meta: [
                { name: 'description', content: 'Renet Consulting предлагает специализированную разработку программного обеспечения, разработку веб-приложений, веб-программирование, разработку веб-приложений' },
                { name: 'keywords', content: 'Portland Renet Consulting, разработка пользовательского программного обеспечения, разработка веб-сайтов, Портленд, веб-разработка, разработка баз данных, разработка веб-приложений, разработка мобильных приложений, онлайн-разработка, веб-дизайн, техника юзабилити, AngularJS, Dot Net Core, .NET Core, Entity Framework , JavaScript, Wordpress, разработка веб-приложений, мобильное веб-приложение, сайт для приложения' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/custom-software' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Разработка пользовательского программного обеспечения' },
                { property: 'og:description', content: 'Renet Consulting предлагает специализированную разработку программного обеспечения, разработку веб-приложений, веб-программирование, разработку веб-приложений' },
                { property: 'og:image', content: '/images/you-need-custom-software.png' }
            ]
        }
    },
    {
        path: 'our-team', component: OurTeamComponent,
        data: {
            title: 'Интернациональная Команда Renet Consulting',
            meta: [
                { name: 'description', content: 'Команда Renet Consulting состоит из опытных веб-разработчиков, дизайнеров веб-приложений, разработчиков пользовательских программ' },
                { name: 'keywords', content: 'Portland Renet Consulting, разработка пользовательского программного обеспечения, разработка веб-сайтов, Портленд, веб-разработка, разработка баз данных, разработка веб-приложений, разработка мобильных приложений, онлайн-разработка, веб-дизайн, техника юзабилити, AngularJS, Dot Net Core, .NET Core, Entity Framework , JavaScript, Wordpress, разработка веб-приложений, мобильное веб-приложение, сайт для приложения' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/our-team' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Интернациональная Команда Renet Consulting' },
                { property: 'og:description', content: 'Команда Renet Consulting состоит из опытных веб-разработчиков, дизайнеров веб-приложений, разработчиков пользовательских программ' },
                { property: 'og:image', content: '/images/bg-header.jpg' }
            ]
        }
    },
    {
        path: 'cloud-computing', component: CloudComputingComponent,
        data: {
            title: 'Облачные вычислительные службы',
            meta: [
                { name: 'description', content: 'Помимо разработки специального программного обеспечения, Renet Consulting также предлагает услуги Cloud Computing на Microsoft Azure или Amazon Web Services' },
                { name: 'keywords', content: 'Portland Renet Consulting, разработка пользовательского программного обеспечения, разработка веб-сайтов, Портленд, веб-разработка, разработка баз данных, разработка веб-приложений, разработка мобильных приложений, онлайн-разработка, веб-дизайн, техника юзабилити, AngularJS, Dot Net Core, .NET Core, Entity Framework , JavaScript, Wordpress, разработка веб-приложений, мобильное веб-приложение, сайт для приложения' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/cloud-computing' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Облачные вычислительные службы' },
                { property: 'og:description', content: 'Помимо разработки специального программного обеспечения, Renet Consulting также предлагает услуги Cloud Computing на Microsoft Azure или Amazon Web Services' },
                { property: 'og:image', content: '/images/bg-header.jpg' }
            ]
        }
    },
    {
        path: 'technologies', component: TechnologiesComponent,
        data: {
            title: 'Технологии веб-разработки, с которыми мы работаем',
            meta: [
                { name: 'description', content: 'Пользовательская веб-разработка, веб-приложение для мобильных устройств, технологии веб-дизайна, такие как Angular, Javascript, Typcript, WordPress, .NET Core, jQuery, SQL, Renet Consulting' },
                { name: 'keywords', content: 'Portland Renet Consulting, разработка пользовательского программного обеспечения, разработка веб-сайтов, Портленд, веб-разработка, разработка баз данных, разработка веб-приложений, разработка мобильных приложений, онлайн-разработка, веб-дизайн, техника юзабилити, AngularJS, Dot Net Core, .NET Core, Entity Framework , JavaScript, Wordpress, разработка веб-приложений, мобильное веб-приложение, сайт для приложения' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/technologies' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Технологии веб-разработки, с которыми мы работаем' },
                { property: 'og:description', content: 'Пользовательская веб-разработка, веб-приложение для мобильных устройств, технологии веб-дизайна, такие как Angular, Javascript, Typcript, WordPress, .NET Core, jQuery, SQL, Renet Consulting' },
                { property: 'og:image', content: '/images/technologies-logo/angular-js-logo.png' }
            ]
        }
    },
    {
        path: 'careers', component: CareersComponent,
        data: {
            title: 'Веб-разработка Карьера в Renet Consulting',
            meta: [
                { name: 'description', content: 'Карьера веб-разработчика в Renet Consulting на национальном или местном уровне в Портленде, OR' },
                { name: 'keywords', content: 'Portland Renet Consulting, разработка пользовательского программного обеспечения, разработка веб-сайтов, Портленд, веб-разработка, разработка баз данных, разработка веб-приложений, разработка мобильных приложений, онлайн-разработка, веб-дизайн, техника юзабилити, AngularJS, Dot Net Core, .NET Core, Entity Framework , JavaScript, Wordpress, разработка веб-приложений, мобильное веб-приложение, сайт для приложения' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/careers' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Веб-разработка Карьера в Renet Consulting' },
                { property: 'og:description', content: 'Карьера веб-разработчика в Renet Consulting на национальном или местном уровне в Портленде, OR' },
                { property: 'og:image', content: '/images/join-our-web-development-team.png' }
            ]
        }
    },
    {
        path: 'not-found', component: NotFoundComponent,
        data: {
            title: 'Страница не найдена Renet Consulting',
            meta: [
                { name: 'description', content: 'Страница не найдена Renet Consulting' },
                { name: 'keywords', content: 'Веб-разработчики Карьера в Renet Consulting на национальном или местном уровне в Портленде, OR' },
                { name: 'keywords', content: 'Portland Renet Consulting, разработка пользовательского программного обеспечения, разработка веб-сайтов, Портленд, веб-разработка, разработка баз данных, разработка веб-приложений, разработка мобильных приложений, онлайн-разработка, веб-дизайн, техника юзабилити, AngularJS, Dot Net Core, .NET Core, Entity Framework , JavaScript, Wordpress, разработка веб-приложений, мобильное веб-приложение, сайт для приложения' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/not-found' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Страница не найдена Renet Consulting' },
                { property: 'og:description', content: 'Страница не найдена Renet Consulting' },
                { property: 'og:image', content: '/images/404-page-not-found.png' }
            ]
        }
    },
    {
        path: 'request-a-quote', loadChildren: () => import('./components/request-quote/request-quote.module').then(m => m.RequestQuoteModule),
        data: {
            title: 'Форма Запроса в Renet Consulting',
            meta: [
                { name: 'description', content: 'Форма Запроса. Мы можем разрабатывать и создавать веб-сайт с использованием WordPress или Angular/.NET core. Мы можем перенести ваш сайт на мобильный.' },
                { name: 'keywords', content: 'Portland Renet Consulting, разработка пользовательского программного обеспечения, разработка веб-сайтов, Портленд, веб-разработка, разработка баз данных, разработка веб-приложений, разработка мобильных приложений, онлайн-разработка, веб-дизайн, техника юзабилити, AngularJS, Dot Net Core, .NET Core, Entity Framework , JavaScript, Wordpress, разработка веб-приложений, мобильное веб-приложение, сайт для приложения' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/en/request-a-quote' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Форма Запроса' },
                { property: 'og:description', content: 'Форма Запроса. Мы можем разрабатывать и создавать веб-сайт с использованием WordPress или Angular/.NET core. Мы можем перенести ваш сайт на мобильный.' },
                { property: 'og:image', content: '/images/request-a-quote-img.jpg' }
            ]
        }
    },
    {
        path: 'helen-doroshenko',
        children: [
            { path: '', redirectTo: 'profile', pathMatch: 'full' },

            { path: 'profile', component: HelenDoroshenkoProfileComponent, data: { title: 'Профиль Елены Дорошенко', } },
            { path: 'promise-project', component: PromiseProjectComponent, data: { title: 'ProMISe - пример пользовательского веб-приложения.', } },

            {
                path: 'prev-projects',
                children: [
                    { path: '', redirectTo: 'onegin-blue', pathMatch: 'full' },

                    { path: 'onegin-blues', component: OneginBluesComponent, data: { title: 'Онегин-Блюз', } },
                    { path: 'ad-empire-media', component: AEMComponent, data: { title: 'Ad Empire Media', } },
                    { path: 'ecology', component: EcologyComponent, data: { title: 'Экологика', } },

                    { path: '**', redirectTo: 'onegin-blue' }
                ]
            },

            { path: '**', redirectTo: 'profile' }
        ]
    },
    {
        path: 'projects',
        children: [
            { path: '', redirectTo: 'carroll-food-intolerance-app', pathMatch: 'full' },

            { path: 'carroll-food-intolerance-app', component: CFIComponent, data: { title: 'Carroll Food Intolerance приложение', } },
            {
                path: 'iamsapien', component: IamsapienComponent,
                data: {
                    title: 'IAmSapinen - пример пользовательского веб-приложения, разработанный Renet Consulting',
                    meta: [
                        {
                            name: 'description', content: 'Renet Consulting"\s пример дизайна веб-приложений и разработки веб-приложений, пользовательское веб-программирование'
                        },
                        { name: 'keywords', content: 'Portland Renet Consulting, разработка пользовательского программного обеспечения, разработка веб-сайтов, Портленд, веб-разработка, разработка баз данных, разработка веб-приложений, разработка мобильных приложений, онлайн-разработка, веб-дизайн, техника юзабилити, AngularJS, Dot Net Core, .NET Core, Entity Framework , JavaScript, Wordpress, разработка веб-приложений, мобильное веб-приложение, сайт для приложения' },
                        { property: 'og:site_name', content: 'Renet Consulting' },
                        { property: 'og:url', content: '//renetusa.com/ru/iamsapien' },
                        { property: 'og:type', content: 'article' },
                        { property: 'og:title', content: 'IAmSapinen - пример пользовательского веб-приложения, разработанный Renet Consulting' },
                        { property: 'og:description', content: 'Renet Consulting"\s пример дизайна веб-приложений и разработки веб-приложений, пользовательское веб-программирование' },
                        { property: 'og:image', content: '/images/bg-iamsapien.jpg' }
                    ]
                }
            },
            {
                path: 'rockawaybeachresort', component: RockAwayBeachResortComponent,
                data: {
                    title: 'Rockaway Beach Resort, один из клиентов Renet Consulting',
                    meta: [
                        { name: 'description', content: 'Обновление веб-приложения Renet Consulting, веб-дизайн и разработка веб-приложений, пользовательское веб-программирование' },
                        { name: 'keywords', content: 'Portland Renet Consulting, разработка пользовательского программного обеспечения, разработка веб-сайтов, Портленд, веб-разработка, разработка баз данных, разработка веб-приложений, разработка мобильных приложений, онлайн-разработка, веб-дизайн, техника юзабилити, AngularJS, Dot Net Core, .NET Core, Entity Framework , JavaScript, Wordpress, разработка веб-приложений, мобильное веб-приложение, сайт для приложения' },
                        { property: 'og:site_name', content: 'Renet Consulting' },
                        { property: 'og:url', content: '//renetusa.com/ru/rockawaybeachresort' },
                        { property: 'og:type', content: 'article' },
                        { property: 'og:title', content: 'Rockaway Beach Resort, один из клиентов Renet Consulting' },
                        { property: 'og:description', content: 'Обновление веб-приложения Renet Consulting, веб-дизайн и разработка веб-приложений, пользовательское веб-программирование' },
                        { property: 'og:image', content: '/images/bg-rockawaybeachresort.jpg' }
                    ]
                }
            },
            {
                path: 'promise', component: PromiseComponent,
                data: {
                    title: 'ProMISe - пример пользовательского веб-приложения, разработанный Renet Consulting',
                    meta: [
                        { name: 'description', content: 'ProMISe - проект разработки веб-проектов Renet Consulting' },
                        { name: 'keywords', content: 'Portland Renet Consulting, разработка пользовательского программного обеспечения, разработка веб-сайтов, Портленд, веб-разработка, разработка баз данных, разработка веб-приложений, разработка мобильных приложений, онлайн-разработка, веб-дизайн, техника юзабилити, AngularJS, Dot Net Core, .NET Core, Entity Framework , JavaScript, Wordpress, разработка веб-приложений, мобильное веб-приложение, сайт для приложения' },
                        { property: 'og:site_name', content: 'Renet Consulting' },
                        { property: 'og:url', content: '//renetusa.com/ru/promise' },
                        { property: 'og:type', content: 'article' },
                        { property: 'og:title', content: 'ProMISe - пример пользовательского веб-приложения, разработанный Renet Consulting' },
                        { property: 'og:description', content: 'ProMISe - проект разработки веб-проектов Renet Consulting' },
                        { property: 'og:image', content: '/images/bg-promise.jpg' }
                    ]
                }
            },
            { path: 'yzpn', component: YZPNComponent, data: { title: 'YZPN', } },
            { path: 'pulse-electronics', component: PulseElectronicsComponent, data: { title: 'Pulse Electronics', } },

            { path: '**', redirectTo: 'carroll-food-intolerance-app' }
        ]
    }
];
