import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { LinkDefinition } from '../interfaces/link.definition';

@Injectable({
    providedIn: 'root'
})
export class LinkService {

    constructor(
        @Inject(DOCUMENT) private DOCUMENT,
        @Inject(Renderer2) private renderer2: Renderer2
    ) { }

    set = (item: LinkDefinition): void => {
        if (item != null) {
            item.id = (item.id) ? item.id : new Date().valueOf().toString();
            const link = this.renderer2.createElement('link');
            const head: HTMLHeadElement = this.DOCUMENT.head;
            if (head === null) {
                throw new Error('<head> not found within DOCUMENT.');
            }
            Object.keys(item).forEach((key: string) => {
                this.renderer2.setAttribute(link, key, item[key]);
            });
            const oldLink = this.get(item);
            if (oldLink != null) {
                this.renderer2.removeChild(head, oldLink);
            }
            this.renderer2.appendChild(head, link);
        }
    }

    get = (item: LinkDefinition): Element => {
        let result: Element = null;
        if (item != null) {
            const head: HTMLHeadElement = this.DOCUMENT.head;
            for (let index = 0; index < head.children.length; index++) {
                const link: Element = head.children.item(index);
                if (link.localName === 'link') {
                    if (link.id === item.id) {
                        result = link;
                        break;
                    }
                }
            }
        }
        return result;
    }
}
