<section id="pageTitle" [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <div class="container">
        <div class="row block-box">
            <div class="col-lg-12 text-center">
                <h1>{{'TECHNOLOGIES_TITLE' | translate}}</h1>
                <div class="wow zoomIn" data-wow-duration="1s" data-wow-offset="100">
                    <div class="wow tada" data-wow-delay="1s" data-wow-duration="1s">
                        <div class="wow rubberBand" data-wow-delay="2000ms" data-wow-duration="1s">
                            <button type="button"
                                    class="btn btn-primary btn-lg wow pulse animated"
                                    data-wow-delay="300ms"
                                    data-wow-iteration="infinite"
                                    data-wow-duration="2s"
                                    [routerLink]="[prefix, 'contact-us']">
                                {{'CONTACT_BUTTON' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
</section>

<section id="content" class="technologies" [class.russian]="prefix === '/ru' || prefix === '/ua'" content="Some of the technologies that Renet Consulting is using">
    <article>
        <div class="container">
            <h2 class="text-center black-text">{{'TECHNOLOGIES_FRAMEWORKS_AND_LIBRARIES_H2' | translate}}</h2>
            <br />
            <div class="row block-box">
                <div class="col-lg-2 text-center pull-left">
                    <figure>
                        <img id="angular"
                             src="/assets/images/technologies-logo/angular-js-logo.png?v={{version}}"
                             alt="{{'TECHNOLOGIES_IMG_ANGULAR_FRAMEWORK_ALT' | translate}}"
                             title="{{'TECHNOLOGIES_IMG_ANGULAR_FRAMEWORK_TITLE' | translate}}"
                             class="wow bounceIn"
                             data-wow-delay=".1s" />
                        <figcaption><p><strong>{{'TECHNOLOGIES_IMG_ANGULAR_FRAMEWORK' | translate}}</strong></p></figcaption>
                    </figure>
                </div>
                <div class="col-lg-10 pull-left">
                    <h3 class="black-text thin-text">{{'TECHNOLOGIES_IMG_ANGULAR_FRAMEWORK_H3' | translate}}</h3>
                </div>
                <div class="clearfix"></div>
            </div>

            <div class="row block-box">
                <div class="col-lg-2 text-center pull-left">
                    <figure>
                        <img id="jquery"
                             src="/assets/images/technologies-logo/jquery-logo.png?v={{version}}"
                             alt="{{'TECHNOLOGIES_IMG_JQUERY_ALT' | translate}}"
                             title="{{'TECHNOLOGIES_IMG_JQUERY_TITLE' | translate}}"
                             class="wow bounceIn"
                             data-wow-delay=".2s" />
                        <figcaption><p><strong>{{'TECHNOLOGIES_IMG_JQUERY' | translate}}</strong></p></figcaption>
                    </figure>
                </div>
                <div class="col-lg-10 pull-left">
                    <h3 class="black-text thin-text pull-left">{{'TECHNOLOGIES_IMG_JQUERY_H3' | translate}}</h3>
                </div>
                <div class="clearfix"></div>
            </div>

            <div class="row block-box">
                <div class="col-lg-2 text-center pull-left">
                    <figure>
                        <img id="wordpress"
                             src="/assets/images/technologies-logo/wordpress.png?v={{version}}"
                             alt="{{'TECHNOLOGIES_IMG_WORDPRESS_ALT' | translate}}"
                             title="{{'TECHNOLOGIES_IMG_WORDPRESS_TITLE' | translate}}"
                             class="wow bounceIn"
                             data-wow-delay=".3s" />
                        <figcaption><p><strong>{{'TECHNOLOGIES_IMG_WORDPRESS' | translate}}</strong></p></figcaption>
                    </figure>
                </div>
                <div class="col-lg-10 pull-left">
                    <h3 class="black-text thin-text">{{'TECHNOLOGIES_IMG_WORDPRESS_H3' | translate}}</h3>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="row block-box">
                <div class="col-lg-2 text-center pull-left">
                    <figure>
                        <img id="aspdotnet"
                             src="/assets/images/technologies-logo/asp-dot-net.png?v={{version}}"
                             alt="{{'TECHNOLOGIES_IMG_ASPDOTNET_ALT' | translate}}"
                             title="{{'TECHNOLOGIES_IMG_ASPDOTNET_TITLE' | translate}}"
                             class="wow bounceIn"
                             data-wow-delay=".4s" />
                        <figcaption><p><strong>{{'TECHNOLOGIES_IMG_ASPDOTNET' | translate}}</strong></p></figcaption>
                    </figure>
                </div>
                <div class="col-lg-10 pull-left">
                    <h3 class="black-text thin-text">{{'TECHNOLOGIES_IMG_ASPDOTNET_H3' | translate}}</h3>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </article>
    <div class="clearfix"></div>
    <div class="hr-line"></div>
    <div class="clearfix"></div>
    <article>
        <div class="container">
            <h2 class="text-center black-text">{{'TECHNOLOGIES_LANGUAGES_H2' | translate}}</h2>
            <br />
            <div class="row block-box">
                <div class="col-lg-2 text-center pull-left">
                    <figure>
                        <img id="html-css"
                             src="/assets/images/technologies-logo/html-css-logo.png?v={{version}}"
                             alt="{{'TECHNOLOGIES_IMG_HTML_AND_CSS_ALT' | translate}}"
                             title="{{'TECHNOLOGIES_IMG_HTML_AND_CSS_TITLE' | translate}}"
                             class="wow bounceIn"
                             data-wow-delay=".5s" />
                        <figcaption><p><strong>{{'TECHNOLOGIES_IMG_HTML_AND_CSS' | translate}}</strong></p></figcaption>
                    </figure>
                </div>
                <div class="col-lg-10 pull-left">
                    <h3 class="black-text thin-text">{{'TECHNOLOGIES_IMG_HTML_AND_CSS_H3' | translate}}</h3>
                </div>
                <div class="clearfix"></div>
            </div>

            <div class="row block-box">
                <div class="col-lg-2 text-center pull-left">
                    <figure>
                        <img id="javascript"
                             src="/assets/images/technologies-logo/javascript.png?v={{version}}"
                             alt="{{'TECHNOLOGIES_IMG_JAVASCRIPT_ALT' | translate}}"
                             title="{{'TECHNOLOGIES_IMG_JAVASCRIPT_TITLE' | translate}}"
                             class="wow bounceIn" data-wow-delay=".6s" />
                        <figcaption><p><strong>{{'TECHNOLOGIES_IMG_JAVASCRIPT' | translate}}</strong></p></figcaption>
                    </figure>
                </div>
                <div class="col-lg-10 pull-left">
                    <h3 class="black-text thin-text">{{'TECHNOLOGIES_IMG_JAVASCRIPT_H3' | translate}}</h3>
                </div>
                <div class="clearfix"></div>
            </div>

            <div class="row block-box">
                <div class="col-lg-2 text-center pull-left">
                    <figure>
                        <img id="csharp"
                             src="/assets/images/technologies-logo/csharp.png?v={{version}}"
                             alt="{{'TECHNOLOGIES_IMG_CSHARP_ALT' | translate}}"
                             title="{{'TECHNOLOGIES_IMG_CSHARP_TITLE' | translate}}"
                             class="wow bounceIn"
                             data-wow-delay=".7s" />
                        <figcaption><p><strong>{{'TECHNOLOGIES_IMG_CSHARP' | translate}}</strong></p></figcaption>
                    </figure>
                </div>
                <div class="col-lg-10 pull-left">
                    <h3 class="black-text thin-text">{{'TECHNOLOGIES_IMG_CSHARP_H3' | translate}}</h3>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="row block-box">
                <div class="col-lg-2 text-center pull-left">
                    <figure>
                        <img id="typescript"
                             src="/assets/images/technologies-logo/ts-logo.png?v={{version}}"
                             alt="{{'TECHNOLOGIES_IMG_TYPESCRIPT_ALT' | translate}}"
                             title="{{'TECHNOLOGIES_IMG_TYPESCRIPT_TITLE' | translate}}"
                             class="wow bounceIn"
                             data-wow-delay=".8s" />
                        <figcaption><p><strong>{{'TECHNOLOGIES_IMG_TYPESCRIPT' | translate}}</strong></p></figcaption>
                    </figure>
                </div>
                <div class="col-lg-10 pull-left">
                    <h3 class="black-text thin-text">{{'TECHNOLOGIES_IMG_TYPESCRIPT_H3' | translate}}</h3>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </article>
    <div class="clearfix"></div>
    <div class="hr-line"></div>
    <div class="clearfix"></div>
    <article>
        <div class="container">
            <h2 class="text-center black-text">Services:</h2>
            <br />
            <div class="row block-box">
                <div class="col-lg-2 text-center pull-left">
                    <figure>
                        <img id="microsoftAzure"
                             src="/assets/images/technologies-logo/microsoft-azure.png?v={{version}}"
                             alt="{{'TECHNOLOGIES_IMG_MICROSOFTAZURE_ALT' | translate}}"
                             title="{{'TECHNOLOGIES_IMG_MICROSOFTAZURE_TITLE' | translate}}"
                             class="wow bounceIn"
                             data-wow-delay=".9s" />
                        <figcaption><p><strong>{{'TECHNOLOGIES_IMG_MICROSOFTAZURE' | translate}}</strong></p></figcaption>
                    </figure>
                </div>
                <div class="col-lg-10 pull-left">
                    <h3 class="black-text thin-text">{{'TECHNOLOGIES_IMG_MICROSOFTAZURE_H3' | translate}}</h3>
                </div>
                <div class="clearfix"></div>
            </div>

            <div class="row block-box">
                <div class="col-lg-2 text-center pull-left">
                    <figure>
                        <img id="amazonwebservices"
                             src="/assets/images/technologies-logo/amazon-web-services.png?v={{version}}"
                             alt="{{'TECHNOLOGIES_IMG_AMAZONWEBSERVICES_ALT' | translate}}"
                             title="{{'TECHNOLOGIES_IMG_AMAZONWEBSERVICES_TITLE' | translate}}"
                             class="wow bounceIn"
                             data-wow-delay=".1s" />
                        <figcaption><p><strong>{{'TECHNOLOGIES_IMG_AMAZONWEBSERVICES' | translate}}</strong></p></figcaption>
                    </figure>
                </div>
                <div class="col-lg-10 pull-left">
                    <h3 class="black-text thin-text">{{'TECHNOLOGIES_IMG_AMAZONWEBSERVICES_H3' | translate}}</h3>
                </div>
                <div class="clearfix"></div>
            </div>

            <div class="row block-box">
                <div class="col-lg-2 text-center pull-left">
                    <figure>
                        <img id="docker"
                             src="/assets/images/technologies-logo/docker.png?v={{version}}"
                             alt="{{'TECHNOLOGIES_IMG_DOCKER_ALT' | translate}}"
                             title="{{'TECHNOLOGIES_IMG_DOCKER_TITLE' | translate}}"
                             class="wow bounceIn"
                             data-wow-delay=".2s" />
                        <figcaption><p><strong>{{'TECHNOLOGIES_IMG_DOCKER' | translate}}</strong></p></figcaption>
                    </figure>
                </div>
                <div class="col-lg-10 pull-left">
                    <h3 class="black-text thin-text">{{'TECHNOLOGIES_IMG_DOCKER_H3' | translate}}</h3>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </article>
    <div class="clearfix"></div>
    <div class="hr-line"></div>
    <div class="clearfix"></div>
    <article>
        <div class="container">
            <h2 class="text-center black-text">{{'TECHNOLOGIES_OTHER_TITLE' | translate}}</h2>
            <br />
            <div class="row block-box">
                <div class="col-lg-2 text-center pull-left">
                    <figure>
                        <img id="windowsServer2016"
                             src="/assets/images/technologies-logo/windows-server-2016.png?v={{version}}"
                             alt="{{'TECHNOLOGIES_IMG_WINDOWSSERVER_ALT' | translate}}"
                             title="{{'TECHNOLOGIES_IMG_WINDOWSSERVER_TITLE' | translate}}"
                             class="wow bounceIn" data-wow-delay=".3s" />
                        <figcaption><p><strong>{{'TECHNOLOGIES_IMG_WINDOWSSERVER' | translate}}</strong></p></figcaption>
                    </figure>
                </div>
                <div class="col-lg-10 pull-left">
                    <h3 class="black-text thin-text">{{'TECHNOLOGIES_IMG_WINDOWSSERVER_H3' | translate}}</h3>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="row block-box">
                <div class="col-lg-2 text-center pull-left">
                    <figure>
                        <img id="sonicWall"
                             src="/assets/images/technologies-logo/sonic-wall.png?v={{version}}"
                             alt="{{'TECHNOLOGIES_IMG_SONICWALL_ALT' | translate}}"
                             title="{{'TECHNOLOGIES_IMG_SONICWALL_TITLE' | translate}}"
                             class="wow bounceIn"
                             data-wow-delay=".4s" />
                        <figcaption><p><strong>SonicWall</strong></p></figcaption>
                    </figure>
                </div>
                <div class="col-lg-10 pull-left">
                    <h3 class="black-text thin-text">{{'TECHNOLOGIES_IMG_SONICWALL_H3' | translate}}</h3>
                </div>
                <div class="clearfix"></div>
            </div>
            <br />
            <br />
        </div>
    </article>
    <div class="clearfix"></div>
    <div class="bottom-banner text-center">
        <div class="container">
            <h5>{{'TECHNOLOGIES_NO_PROJECT_IS_TOO_SMALL_H5' | translate}}</h5>
        </div>
    </div>
</section>
