import { Route } from '@angular/router';
import { EN_ROUTES } from './app-en.routes';
import { RU_ROUTES } from './app-ru.routes';
import { UA_ROUTES } from './app-ua.routes';

export const ROUTES: Route[] = [
    { path: '', redirectTo: 'en/home', pathMatch: 'full' },
    { path: 'ru', redirectTo: 'ru/home', pathMatch: 'full' },
    { path: 'en', redirectTo: 'en/home', pathMatch: 'full' },
    { path: 'ua', redirectTo: 'ua/home', pathMatch: 'full' },
    { path: 'ru', children: RU_ROUTES },
    { path: 'en', children: EN_ROUTES },
    { path: 'ua', children: UA_ROUTES },
    { path: '**', redirectTo: 'en/not-found' }
];
