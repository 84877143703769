import { Component, HostListener, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { TranslateBase } from '~/bases/translate.base';
import { TranslatorService } from '~/services/translator.service';
import { IS_BROWSER } from '~/tokens/is-browser.token';
import { isMobile } from '~/utils/is-mobile';
import { VERSION } from '~/tokens/version.token';

@Component({
    selector: 'header-menu',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends TranslateBase implements OnInit, OnDestroy {

    barFixed: boolean;
    collapsed: boolean;
    isMobile: boolean;

    constructor(
        @Inject(Injector) injector: Injector,
        @Inject(IS_BROWSER) private isBrowser,
        @Inject(TranslatorService) public translatorService: TranslatorService,
        @Inject(VERSION) public version: string,
    ) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();
        this.resize();
    }

    @HostListener('window:resize') resize = () => this.isMobile = isMobile();

    @HostListener('document:scroll') scroll = (): void => {
        if (this.isBrowser) {
            this.barFixed = window.pageYOffset > 0;
        }
    }

    setLanguage = (lang: string): void => {
        this.translatorService.navigate(lang);
    }

    toggleCollapsed = () => {
        this.collapsed = !this.collapsed;
    }
}
