import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IAppSettings } from '../interfaces/app-settings';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    readonly url = '/api/settings';

    constructor(
        @Inject(HttpClient) private http: HttpClient,
    ) { }

    get = () => this.http.get<IAppSettings>(`${this.url}/environment`);
}
