/* tslint:disable:max-line-length */
import { Route } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { CareersComponent } from './components/careers/careers.component';
import { CloudComputingComponent } from './components/cloud.computing/cloud.computing.component';
import { CustomSoftwareComponent } from './components/custom.software/custom.software.component';
import { HistoryComponent } from './components/history/history.component';
import { HomeComponent } from './components/home/home.component';
import { IamsapienComponent } from './components/iamsapien/iamsapien.component';
import { ITConsultingComponent } from './components/it.consulting/it.consulting.component';
import { NotFoundComponent } from './components/not.found/not.found.component';
import { OurTeamComponent } from './components/our.team/our.team.component';
import { PrinciplesComponent } from './components/principles/principles.component';
import { CFIComponent } from './components/profiles/alex.sirant/portfolio/cfi/cfi.component';
import { AEMComponent } from './components/profiles/helen.doroshenko/portfolio/aem/aem.component';
import { EcologyComponent } from './components/profiles/helen.doroshenko/portfolio/ecology/ecology.component';
import { OneginBluesComponent } from './components/profiles/helen.doroshenko/portfolio/onegin.blues/onegin.blues.component';
import { PromiseProjectComponent } from './components/profiles/helen.doroshenko/portfolio/promise/promise.component';
import { PulseElectronicsComponent } from './components/profiles/helen.doroshenko/portfolio/pulse.electronics/pulse.electronics.component';
import { YZPNComponent } from './components/profiles/helen.doroshenko/portfolio/yzpn/yzpn.component';
import { HelenDoroshenkoProfileComponent } from './components/profiles/helen.doroshenko/profile/helen.doroshenko.component';
import { PromiseComponent } from './components/promise/promise.component';
import { RockAwayBeachResortComponent } from './components/rockawaybeachresort/rockawaybeachresort.component';
import { TechnologiesComponent } from './components/technologies/technologies.component';
import { WebApplicationsComponent } from './components/web.applications/web.applications.component';

export const UA_ROUTES: Route[] = [
    {
        path: 'home', component: HomeComponent,
        data: {
            title: 'Renet Consulting, спеціалізована компанія з програмного забезпечення',
            meta: [
                { name: 'description', content: 'Надання послуг ІТ-консалтингу на національному рівні, включаючи веб-дизайн, веб-і мобільні додатки, веб-додатки, управління проектами, призначене для користувача програмне забезпечення і призначені для користувача ІТ-рішення. Знаходиться в Портленді, штат Орегон.' },
                { name: 'keywords', content: 'Portland Renet Consulting, розробка користувальницького програмного забезпечення, розробка веб-сайтів, Портленд, веб-розробка, розробка баз даних, розробка веб-додатків, розробка мобільних додатків, онлайн-розробка, веб-дизайн, техніка юзабіліті, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, розробка веб-додатків, мобільне веб-додаток, сайт для додатка' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/home' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Renet Consulting, спеціалізована компанія з програмного забезпечення' },
                { property: 'og:description', content: 'Надання послуг ІТ-консалтингу на національному рівні, включаючи веб-дизайн, веб-і мобільні додатки, веб-додатки, управління проектами, призначене для користувача програмне забезпечення і призначені для користувача ІТ-рішення. Знаходиться в Портленді, штат Орегон.' },
                { property: 'og:image', content: '/images/bg-header.jpg' }
            ]
        }
    },
    {
        path: 'about', component: AboutComponent,
        data: {
            title: 'О Нас',
            meta: [
                { name: 'description', content: 'Renet Consulting працює з клієнтами більш 20 років, створюючи для користувача веб-додатки, працюючи над веб-програмуванням, розробляючи веб-додатки на національному та місцевому рівні в Портленді, OR.' },
                { name: 'keywords', content: 'Portland Renet Consulting, розробка користувальницького програмного забезпечення, розробка веб-сайтів, Портленд, веб-розробка, розробка баз даних, розробка веб-додатків, розробка мобільних додатків, онлайн-розробка, веб-дизайн, техніка юзабіліті, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, розробка веб-додатків, мобільне веб-додаток, сайт для додатка' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/about' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Про нас' },
                { property: 'og:description', content: 'Renet Consulting працює з клієнтами більш 20 років, створюючи для користувача веб-додатки, працюючи над веб-програмуванням, розробляючи веб-додатки на національному та місцевому рівні в Портленді, OR.' },
                { property: 'og:image', content: '/images/we-build-custom-software.png' }
            ]
        }
    },
    {
        path: 'history', component: HistoryComponent,
        data: {
            title: 'История Renet Consulting',
            meta: [
                { name: 'description', content: 'Компанія Renet Consulting вже більше 20 років надає ЯКІСНІ послуги та ШВИДКУ розробку веб-додатків, призначене для користувача програмне забезпечення та веб-дизайн більше 20 років на національному та місцевому рівні в Портленді, штат Орегон.' },
                { name: 'keywords', content: 'Portland Renet Consulting, розробка користувальницького програмного забезпечення, розробка веб-сайтів, Портленд, веб-розробка, розробка баз даних, розробка веб-додатків, розробка мобільних додатків, онлайн-розробка, веб-дизайн, техніка юзабіліті, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, розробка веб-додатків, мобільне веб-додаток, сайт для додатка' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/history' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Історія Renet Consulting' },
                { property: 'og:description', content: 'Компанія Renet Consulting вже більше 20 років надає ЯКІСНІ послуги та ШВИДКУ розробку веб-додатків, призначене для користувача програмне забезпечення та веб-дизайн більше 20 років на національному та місцевому рівні в Портленді, штат Орегон.' },
                { property: 'og:image', content: '/images/bg-header.jpg' }
            ]
        }
    },
    {
        path: 'contact-us', loadChildren: () => import('./components/contact/contact.module').then(m => m.ContactModule),
        data: {
            title: 'Как связаться с Renet Consulting',
            meta: [
                { name: 'description', content: 'Renet Consulting володіє веб-дизайном, розробкою веб-додатків, мобільних веб-додатком, веб-програмуванням і призначеним для користувача програмним забезпеченням на національному та місцевому рівні, в Портленді, Орегон.' },
                { name: 'keywords', content: 'Portland Renet Consulting, розробка користувальницьких програмних продуктів, розробка веб-сайтів, Portland, веб-розробка, розробка баз даних, розробка веб-додатків, розробка мобільних додатків, онлайн-розробка, веб-дизайн, юзабіліті, Angular, AngularJS, Dot Net Core , .NET Core, Entity Framework, JavaScript, Wordpress, розробка веб-додатків, мобільне веб-додаток, сайт для додатка.' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/contact-us' },
                { property: 'og:type', content: 'article' },
                {
                    property: 'og:title', content: 'Зв"\'\"язок з Renet Consulting' },
                { property: 'og:description', content: 'Renet Consulting володіє веб-дизайном, розробкою веб-додатків, мобільних веб-додатком, веб-програмуванням і призначеним для користувача програмним забезпеченням на національному та місцевому рівні, в Портленді, Орегон.' },
                { property: 'og:image', content: '/images/bg-contact-us.jpg' }
            ]
        }
    },
    {
        path: 'principles', component: PrinciplesComponent,
        data: {
            title: 'Основні принципи Renet Consulting',
            meta: [
                { name: 'description', content: 'Renet Consulting имеет несколько основных принципов, когда дело доходит до разработки специализированного программного обеспечения' },
                { name: 'keywords', content: 'Portland Renet Consulting, розробка користувальницького програмного забезпечення, розробка веб-сайтів, Портленд, веб-розробка, розробка баз даних, розробка веб-додатків, розробка мобільних додатків, онлайн-розробка, веб-дизайн, техніка юзабіліті, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, розробка веб-додатків, мобільне веб-додаток, сайт для додатка' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/principles' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Основні принципи Renet Consulting' },
                { property: 'og:description', content: 'Renet Consulting має кілька основних принципів, коли справа доходить до розробки спеціалізованого програмного забезпечення' },
                { property: 'og:image', content: '/images/individuals-over-tools.png' }
            ]
        }
    },
    {
        path: 'web-applications', component: WebApplicationsComponent,
        data: {
            title: 'Призначені для користувача веб-додатки',
            meta: [
                { name: 'description', content: 'Renet Consulting володіє розробкою веб-додатків, веб-дизайном, мобільним веб-додатком, веб-програмуванням і призначеним для користувача програмним забезпеченням' },
                { name: 'keywords', content: 'Portland Renet Consulting, розробка користувальницького програмного забезпечення, розробка веб-сайтів, Портленд, веб-розробка, розробка баз даних, розробка веб-додатків, розробка мобільних додатків, онлайн-розробка, веб-дизайн, техніка юзабіліті, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, розробка веб-додатків, мобільне веб-додаток, сайт для додатка' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/web-applications' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Призначені для користувача веб-додатки' },
                { property: 'og:description', content: 'Renet Consulting володіє розробкою веб-додатків, веб-дизайном, мобільним веб-додатком, веб-програмуванням і призначеним для користувача програмним забезпеченням' },
                { property: 'og:image', content: '/images/web-application-img.png' }
            ]
        }
    },
    {
        path: 'it-consulting', component: ITConsultingComponent,
        data: {
            title: 'Призначений для користувача ІТ-сервіс',
            meta: [
                { name: 'description', content: 'Крім розробки спеціалізованого програмного забезпечення, Renet Consulting також пропонує ІТ-послуги, включаючи віддалене управління' },
                { name: 'keywords', content: 'Portland Renet Consulting, розробка користувальницького програмного забезпечення, розробка веб-сайтів, Портленд, веб-розробка, розробка баз даних, розробка веб-додатків, розробка мобільних додатків, онлайн-розробка, веб-дизайн, техніка юзабіліті, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, розробка веб-додатків, мобільне веб-додаток, сайт для додатка' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/it-consulting' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Призначений для користувача ІТ-сервіс' },
                { property: 'og:description', content: 'Крім розробки спеціалізованого програмного забезпечення, Renet Consulting також пропонує ІТ-послуги, включаючи віддалене управління' },
                { property: 'og:image', content: '/images/server-management.png' }
            ]
        }
    },
    {
        path: 'custom-software', component: CustomSoftwareComponent,
        data: {
            title: 'Розробка користувальницького програмного забезпечення',
            meta: [
                { name: 'description', content: 'Renet Consulting пропонує спеціалізовану розробку програмного забезпечення, розробку веб-додатків, веб-програмування, розробку веб-додатків' },
                { name: 'keywords', content: 'Portland Renet Consulting, розробка користувальницького програмного забезпечення, розробка веб-сайтів, Портленд, веб-розробка, розробка баз даних, розробка веб-додатків, розробка мобільних додатків, онлайн-розробка, веб-дизайн, техніка юзабіліті, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, розробка веб-додатків, мобільне веб-додаток, сайт для додатка' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/custom-software' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Розробка користувальницького програмного забезпечення' },
                { property: 'og:description', content: 'Renet Consulting пропонує спеціалізовану розробку програмного забезпечення, розробку веб-додатків, веб-програмування, розробку веб-додатків' },
                { property: 'og:image', content: '/images/you-need-custom-software.png' }
            ]
        }
    },
    {
        path: 'our-team', component: OurTeamComponent,
        data: {
            title: 'Інтернаціональна Команда Renet Consulting',
            meta: [
                { name: 'description', content: 'Команда Renet Consulting складається з досвідчених веб-розробників, дизайнерів веб-додатків, розробників призначених для користувача програм' },
                { name: 'keywords', content: 'Portland Renet Consulting, розробка користувальницького програмного забезпечення, розробка веб-сайтів, Портленд, веб-розробка, розробка баз даних, розробка веб-додатків, розробка мобільних додатків, онлайн-розробка, веб-дизайн, техніка юзабіліті, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, розробка веб-додатків, мобільне веб-додаток, сайт для додатка' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/our-team' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Інтернаціональна Команда Renet Consulting' },
                { property: 'og:description', content: 'Команда Renet Consulting складається з досвідчених веб-розробників, дизайнерів веб-додатків, розробників призначених для користувача програм' },
                { property: 'og:image', content: '/images/bg-header.jpg' }
            ]
        }
    },
    {
        path: 'cloud-computing', component: CloudComputingComponent,
        data: {
            title: 'Хмарні обчислювальні служби',
            meta: [
                { name: 'description', content: 'Крім розробки спеціального програмного забезпечення, Renet Consulting також пропонує послуги Cloud Computing на Microsoft Azure або Amazon Web Services' },
                { name: 'keywords', content: 'Portland Renet Consulting, розробка користувальницького програмного забезпечення, розробка веб-сайтів, Портленд, веб-розробка, розробка баз даних, розробка веб-додатків, розробка мобільних додатків, онлайн-розробка, веб-дизайн, техніка юзабіліті, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, розробка веб-додатків, мобільне веб-додаток, сайт для додатка' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/cloud-computing' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Хмарні обчислювальні служби' },
                { property: 'og:description', content: 'Крім розробки спеціального програмного забезпечення, Renet Consulting також пропонує послуги Cloud Computing на Microsoft Azure або Amazon Web Services' },
                { property: 'og:image', content: '/images/bg-header.jpg' }
            ]
        }
    },
    {
        path: 'technologies', component: TechnologiesComponent,
        data: {
            title: 'Технології веб-розробки, з якими ми працюємо',
            meta: [
                { name: 'description', content: 'Призначена для користувача веб-розробка, веб-додаток для мобільних пристроїв, технології веб-дизайну, такі як Angular, Javascript, Typcript, WordPress, .NET Core, jQuery, SQL, Renet Consulting' },
                { name: 'keywords', content: 'Portland Renet Consulting, розробка користувальницького програмного забезпечення, розробка веб-сайтів, Портленд, веб-розробка, розробка баз даних, розробка веб-додатків, розробка мобільних додатків, онлайн-розробка, веб-дизайн, техніка юзабіліті, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, розробка веб-додатків, мобільне веб-додаток, сайт для додатка' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/technologies' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Технології веб-розробки, з якими ми працюємо' },
                { property: 'og:description', content: 'Призначена для користувача веб-розробка, веб-додаток для мобільних пристроїв, технології веб-дизайну, такі як Angular, Javascript, Typcript, WordPress, .NET Core, jQuery, SQL, Renet Consulting' },
                { property: 'og:image', content: '/images/technologies-logo/angular-js-logo.png' }
            ]
        }
    },
    {
        path: 'careers', component: CareersComponent,
        data: {
            title: 'Веб-розробка Кар"\'\"єра в Renet Consulting',
            meta: [
                { name: 'description', content: 'Кар"\'\"єра - розробника в Renet Consulting на національному або місцевому рівні в Портленді, OR' },
                { name: 'keywords', content: 'Portland Renet Consulting, розробка користувальницького програмного забезпечення, розробка веб-сайтів, Портленд, веб-розробка, розробка баз даних, розробка веб-додатків, розробка мобільних додатків, онлайн-розробка, веб-дизайн, техніка юзабіліті, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, розробка веб-додатків, мобільне веб-додаток, сайт для додатка' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/careers' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Веб-розробка Кар"\'\"єра в Renet Consulting' },
                { property: 'og:description', content: 'Кар"\'\"єра - розробника в Renet Consulting на національному або місцевому рівні в Портленді, OR' },
                { property: 'og:image', content: '/images/join-our-web-development-team.png' }
            ]
        }
    },
    {
        path: 'not-found', component: NotFoundComponent,
        data: {
            title: 'Сторінку не знайдено Renet Consulting',
            meta: [
                { name: 'description', content: 'Сторінку не знайдено Renet Consulting' },
                { name: 'keywords', content: 'Веб-розробники Кар"\'\"єра в Renet Consulting на національному або місцевому рівні в Портленді, OR' },
                { name: 'keywords', content: 'Portland Renet Consulting, розробка користувальницького програмного забезпечення, розробка веб-сайтів, Портленд, веб-розробка, розробка баз даних, розробка веб-додатків, розробка мобільних додатків, онлайн-розробка, веб-дизайн, техніка юзабіліті, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, розробка веб-додатків, мобільне веб-додаток, сайт для додатка' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/ru/not-found' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Сторінку не знайдено Renet Consulting' },
                { property: 'og:description', content: 'Сторінку не знайдено Renet Consulting' },
                { property: 'og:image', content: '/images/404-page-not-found.png' }
            ]
        }
    },
    {
        path: 'request-a-quote', loadChildren: () => import('./components/request-quote/request-quote.module').then(m => m.RequestQuoteModule),
        data: {
            title: 'Форма Запиту в Renet Consulting',
            meta: [
                { name: 'description', content: 'Форма Запроса. Мы можем разрабатывать и создавать веб-сайт с использованием WordPress или Angular/.NET core. Мы можем перенести ваш сайт на мобильный.' },
                { name: 'keywords', content: 'Portland Renet Consulting, розробка користувальницького програмного забезпечення, розробка веб-сайтів, Портленд, веб-розробка, розробка баз даних, розробка веб-додатків, розробка мобільних додатків, онлайн-розробка, веб-дизайн, техніка юзабіліті, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, розробка веб-додатків, мобільне веб-додаток, сайт для додатка' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/en/request-a-quote' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Форма Запиту' },
                { property: 'og:description', content: 'Форма запиту. Ми можемо розробляти і створювати веб-сайт з використанням WordPress або Angular/.NET core. Ми можемо перенести ваш сайт на мобільний.' },
                { property: 'og:image', content: '/images/request-a-quote-img.jpg' }
            ]
        }
    },
    {
        path: 'helen-doroshenko',
        children: [
            { path: '', redirectTo: 'profile', pathMatch: 'full' },

            { path: 'profile', component: HelenDoroshenkoProfileComponent, data: { title: 'Профіль Олени Дорошенко', } },
            { path: 'promise-project', component: PromiseProjectComponent, data: { title: 'ProMISe - приклад для користувача веб-додатки.', } },

            {
                path: 'prev-projects',
                children: [
                    { path: '', redirectTo: 'onegin-blue', pathMatch: 'full' },

                    { path: 'onegin-blues', component: OneginBluesComponent, data: { title: 'Онєгін-Блюз', } },
                    { path: 'ad-empire-media', component: AEMComponent, data: { title: 'Ad Empire Media', } },
                    { path: 'ecology', component: EcologyComponent, data: { title: 'Екологіка', } },

                    { path: '**', redirectTo: 'onegin-blue' }
                ]
            },

            { path: '**', redirectTo: 'profile' }
        ]
    },
    {
        path: 'projects',
        children: [
            { path: '', redirectTo: 'carroll-food-intolerance-app', pathMatch: 'full' },

            { path: 'carroll-food-intolerance-app', component: CFIComponent, data: { title: 'Carroll Food Intolerance додаток', } },
            {
                path: 'iamsapien', component: IamsapienComponent,
                data: {
                    title: 'IAmSapinen - приклад для користувача веб-додатки, розроблений Renet Consulting',
                    meta: [
                        {
                            name: 'description', content: 'Renet Consulting"\s приклад дизайну веб-додатків і розробки веб-додатків, призначене для користувача веб-програмування'
                        },
                        { name: 'keywords', content: 'Portland Renet Consulting, розробка користувальницького програмного забезпечення, розробка веб-сайтів, Портленд, веб-розробка, розробка баз даних, розробка веб-додатків, розробка мобільних додатків, онлайн-розробка, веб-дизайн, техніка юзабіліті, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, розробка веб-додатків, мобільне веб-додаток, сайт для додатка' },
                        { property: 'og:site_name', content: 'Renet Consulting' },
                        { property: 'og:url', content: '//renetusa.com/ru/iamsapien' },
                        { property: 'og:type', content: 'article' },
                        { property: 'og:title', content: 'IAmSapinen - приклад для користувача веб-додатки, розроблений Renet Consulting' },
                        { property: 'og:description', content: 'Renet Consulting"\s приклад дизайну веб-додатків і розробки веб-додатків, призначене для користувача веб-програмування' },
                        { property: 'og:image', content: '/images/bg-iamsapien.jpg' }
                    ]
                }
            },
            {
                path: 'rockawaybeachresort', component: RockAwayBeachResortComponent,
                data: {
                    title: 'Rockaway Beach Resort, один из клиентов Renet Consulting',
                    meta: [
                        { name: 'description', content: 'Обновление веб-приложения Renet Consulting, веб-дизайн и разработка веб-приложений, пользовательское веб-программирование' },
                        { name: 'keywords', content: 'Portland Renet Consulting, розробка користувальницького програмного забезпечення, розробка веб-сайтів, Портленд, веб-розробка, розробка баз даних, розробка веб-додатків, розробка мобільних додатків, онлайн-розробка, веб-дизайн, техніка юзабіліті, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, розробка веб-додатків, мобільне веб-додаток, сайт для додатка' },
                        { property: 'og:site_name', content: 'Renet Consulting' },
                        { property: 'og:url', content: '//renetusa.com/ru/rockawaybeachresort' },
                        { property: 'og:type', content: 'article' },
                        { property: 'og:title', content: 'Rockaway Beach Resort, один з клієнтів Renet Consulting' },
                        { property: 'og:description', content: 'Оновлення веб-додатки Renet Consulting, веб-дизайн і розробка веб-додатків, призначене для користувача веб-програмування' },
                        { property: 'og:image', content: '/images/bg-rockawaybeachresort.jpg' }
                    ]
                }
            },
            {
                path: 'promise', component: PromiseComponent,
                data: {
                    title: 'ProMISe - приклад для користувача веб-додатки, розроблений Renet Consulting',
                    meta: [
                        { name: 'description', content: 'ProMISe - проект розробки веб-проектів Renet Consulting' },
                        { name: 'keywords', content: 'Portland Renet Consulting, розробка користувальницького програмного забезпечення, розробка веб-сайтів, Портленд, веб-розробка, розробка баз даних, розробка веб-додатків, розробка мобільних додатків, онлайн-розробка, веб-дизайн, техніка юзабіліті, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, розробка веб-додатків, мобільне веб-додаток, сайт для додатка' },
                        { property: 'og:site_name', content: 'Renet Consulting' },
                        { property: 'og:url', content: '//renetusa.com/ru/promise' },
                        { property: 'og:type', content: 'article' },
                        { property: 'og:title', content: 'ProMISe - приклад для користувача веб-додатки, розроблений Renet Consulting' },
                        { property: 'og:description', content: 'ProMISe - проект розробки веб-проектів Renet Consulting' },
                        { property: 'og:image', content: '/images/bg-promise.jpg' }
                    ]
                }
            },
            { path: 'yzpn', component: YZPNComponent, data: { title: 'YZPN', } },
            { path: 'pulse-electronics', component: PulseElectronicsComponent, data: { title: 'Pulse Electronics', } },

            { path: '**', redirectTo: 'carroll-food-intolerance-app' }
        ]
    }
];
