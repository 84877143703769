<section id="pageTitle" class="safariHeader" [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h1>{{'CAREERS_TITLE' | translate}}</h1>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
</section>
<!--content-->
<section id="content" class="careersContent" content="At Renet Consulting we are always looking for talented web developers" [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <article>
        <div class="container-fluid">
            <div class="container">
                <div class="row wow bounceInLeft block-box" data-wow-delay=".2s">
                    <div class="col-lg-2 text-center pull-left">
                        <img src="/assets/images/join-our-web-development-team.png?v={{version}}"
                             alt="{{'CAREERS_IMG_WEB_DEVELOPMENT_TEAM_ALT' | translate}}"
                             title="{{'CAREERS_IMG_WEB_DEVELOPMENT_TEAM_TITLE' | translate}}" />
                    </div>
                    <div class="col-lg-10 pull-left">
                        <h2>{{'CAREERS_JOIN_H2' | translate}}</h2>
                        <br />
                        <h3 class="thin-text">{{'CAREERS_LEVEL_UP_YOUR_CAREER_H3' | translate}}</h3>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </article>
    <article>
        <div class="container">
            <h2 class="text-center">{{'CAREERS_CURRENT OPENING_H2' | translate}}</h2>
            <br />
            <br />
            <div class="currentOpenings">
                <h3 class="black-text text-center">{{'CAREERS_SOFTWARE_DEVELOPER_H3' | translate}}</h3>
                <br />
                <br />
                <p>{{'CAREERS_SOFTWARE_DEVELOPER_P' | translate}}</p>
                <p>&nbsp;</p>
                <div class="clearfix"></div>
                <div class="hr-line-thin"></div>
                <div class="clearfix"></div>
                <div class="row">
                    <div class="col-md-6 pull-left">
                        <br /><br />
                        <h4 class="black-text thin-text">{{'CAREERS_SOFTWARE_DEVELOPER_RESPONSIBILITIES' | translate}}</h4><br /><br />
                        <ul>
                            <li>{{'CAREERS_SOFTWARE_DEVELOPER_RESPONSIBILITIES_LIST_1' | translate}}</li>
                            <li>{{'CAREERS_SOFTWARE_DEVELOPER_RESPONSIBILITIES_LIST_2' | translate}}</li>
                            <li>{{'CAREERS_SOFTWARE_DEVELOPER_RESPONSIBILITIES_LIST_3' | translate}}</li>
                            <li>{{'CAREERS_SOFTWARE_DEVELOPER_RESPONSIBILITIES_LIST_4' | translate}}</li>
                            <li>{{'CAREERS_SOFTWARE_DEVELOPER_RESPONSIBILITIES_LIST_5' | translate}}</li>
                            <li>{{'CAREERS_SOFTWARE_DEVELOPER_RESPONSIBILITIES_LIST_6' | translate}}</li>
                            <li>{{'CAREERS_SOFTWARE_DEVELOPER_RESPONSIBILITIES_LIST_7' | translate}}</li>
                            <li>{{'CAREERS_SOFTWARE_DEVELOPER_RESPONSIBILITIES_LIST_8' | translate}}</li>
                        </ul>
                    </div>
                    <div class="col-md-6 pull-left">
                        <br /><br /><h4 class="black-text thin-text">{{'CAREERS_SOFTWARE_DEVELOPER_REQUIREMENTS' | translate}}</h4><br /><br />
                        <ul>
                            <li>{{'CAREERS_SOFTWARE_DEVELOPER_REQUIREMENTS_LIST_1' | translate}}</li>
                            <li>{{'CAREERS_SOFTWARE_DEVELOPER_REQUIREMENTS_LIST_2' | translate}}</li>
                            <li>{{'CAREERS_SOFTWARE_DEVELOPER_REQUIREMENTS_LIST_3' | translate}}</li>
                            <li>{{'CAREERS_SOFTWARE_DEVELOPER_REQUIREMENTS_LIST_4' | translate}}</li>
                            <li>{{'CAREERS_SOFTWARE_DEVELOPER_REQUIREMENTS_LIST_5' | translate}}</li>
                            <li>{{'CAREERS_SOFTWARE_DEVELOPER_REQUIREMENTS_LIST_6' | translate}}</li>
                            <li>{{'CAREERS_SOFTWARE_DEVELOPER_REQUIREMENTS_LIST_7' | translate}}</li>
                            <li>{{'CAREERS_SOFTWARE_DEVELOPER_REQUIREMENTS_LIST_8' | translate}}</li>
                            <li>{{'CAREERS_SOFTWARE_DEVELOPER_REQUIREMENTS_LIST_9' | translate}}</li>
                        </ul>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </article>
    <article>
        <div class="clearfix"></div>
        <div class="hr-line"></div>
        <div class="clearfix"></div>
        <div class="container">
            <div class="currentOpenings">
                <h3 class="black-text text-center">{{'CAREERS_FRONTEND_DEVELOPER_H3' | translate}}</h3>
                <br />
                <br />
                <p>{{'CAREERS_FRONTEND_DEVELOPER_P' | translate}}</p>
                <p>&nbsp;</p>
                <div class="hr-line-thin"></div>
                <div class="row">
                    <div class="col-md-6 pull-left">
                        <br /><br /><h4 class="black-text thin-text">{{'CAREERS_FRONTEND_DEVELOPER_RESPONSIBILITIES' | translate}}</h4><br /><br />
                        <ul>
                            <li>{{'CAREERS_FRONTEND_DEVELOPER_RESPONSIBILITIES_LIST_1' | translate}}</li>
                            <li>{{'CAREERS_FRONTEND_DEVELOPER_RESPONSIBILITIES_LIST_2' | translate}}</li>
                            <li>{{'CAREERS_FRONTEND_DEVELOPER_RESPONSIBILITIES_LIST_3' | translate}}</li>
                            <li>{{'CAREERS_FRONTEND_DEVELOPER_RESPONSIBILITIES_LIST_4' | translate}}</li>
                            <li>{{'CAREERS_FRONTEND_DEVELOPER_RESPONSIBILITIES_LIST_5' | translate}}</li>
                        </ul>
                    </div>
                    <div class="col-md-6 pull-left">
                        <br /><br /><h4 class="black-text thin-text">{{'CAREERS_FRONTEND_DEVELOPER_REQUIREMENTS' | translate}}</h4><br /><br />
                        <ul>
                            <li>{{'CAREERS_FRONTEND_DEVELOPER_REQUIREMENTS_LIST_1' | translate}}</li>
                            <li>{{'CAREERS_FRONTEND_DEVELOPER_REQUIREMENTS_LIST_2' | translate}}</li>
                            <li>{{'CAREERS_FRONTEND_DEVELOPER_REQUIREMENTS_LIST_3' | translate}}</li>
                            <li>{{'CAREERS_FRONTEND_DEVELOPER_REQUIREMENTS_LIST_4' | translate}}</li>
                            <li>{{'CAREERS_FRONTEND_DEVELOPER_REQUIREMENTS_LIST_5' | translate}}</li>
                            <li>{{'CAREERS_FRONTEND_DEVELOPER_REQUIREMENTS_LIST_6' | translate}}</li>
                        </ul>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </article>
    <article>
        <div class="clearfix"></div>
        <div class="hr-line"></div>
        <div class="clearfix"></div>
        <div class="container">
            <div class="currentOpenings">
                <h3 class="black-text text-center">{{'PROJECT_MANAGER_TITLE' | translate}}</h3>
                <br />
                <br />
                <p>{{'PROJECT_MANAGER_RESPONSIBILITY' | translate}}</p>
                <p>&nbsp;</p>
                <div class="hr-line-thin"></div>
                <div class="row project-manager-position">
                    <div class="col-md-6 pull-left border-bottom-0 pb-0">
                        <br /><br /><h4 class="black-text thin-text">{{'PROJECT_MANAGER_RESPONSIBILITIES' | translate}}</h4><br /><br />
                        <ul>
                            <li>{{'PROJECT_MANAGER_RESPONSIBILITIES_LIST_1' | translate}}</li>
                            <li>{{'PROJECT_MANAGER_RESPONSIBILITIES_LIST_2' | translate}}</li>
                            <li>
                                <strong>{{'PROJECT_MANAGER_RESPONSIBILITIES_LIST_3' | translate}}</strong>
                                <ul>
                                    <li class="mt-5">{{'PROJECT_MANAGER_RESPONSIBILITIES_LIST_4' | translate}}</li>
                                    <li>{{'PROJECT_MANAGER_RESPONSIBILITIES_LIST_5' | translate}}</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-6 pull-left border-bottom-0 pb-0">
                        <br /><br /><h4 class="black-text thin-text">{{'PROJECT_MANAGER_SKILLS' | translate}}</h4><br /><br />
                        <ul>
                            <li>{{'PROJECT_MANAGER_SKILLS_LIST_1' | translate}}</li>
                            <li>{{'PROJECT_MANAGER_SKILLS_LIST_2' | translate}}</li>
                            <li>{{'PROJECT_MANAGER_SKILLS_LIST_3' | translate}}</li>
                            <li>{{'PROJECT_MANAGER_SKILLS_LIST_4' | translate}}</li>
                            <li>{{'PROJECT_MANAGER_SKILLS_LIST_5' | translate}}</li>
                            <li>{{'PROJECT_MANAGER_SKILLS_LIST_6' | translate}}</li>
                            <li>{{'PROJECT_MANAGER_SKILLS_LIST_7' | translate}}</li>
                        </ul>
                    </div>
                    <div class="col-md-6 pull-left border-bottom-0 pb-0">
                        <br /><br /><h4 class="black-text thin-text">{{'PROJECT_MANAGER_QUALIFICATIONS' | translate}}</h4><br /><br />
                        <ul>
                            <li>{{'PROJECT_MANAGER_QUALIFICATIONS_LIST_1' | translate}}</li>
                            <li>{{'PROJECT_MANAGER_QUALIFICATIONS_LIST_2' | translate}}</li>
                            <li>{{'PROJECT_MANAGER_QUALIFICATIONS_LIST_3' | translate}}</li>
                        </ul>
                    </div>
                    <div class="col-md-6 pull-left border-bottom-0 pb-0">
                        <br /><br /><h4 class="black-text thin-text">{{'PROJECT_MANAGER_REQUIREMENTS' | translate}}</h4><br /><br />
                        <ul>
                            <li>{{'PROJECT_MANAGER_REQUIREMENTS_LIST_1' | translate}}</li>
                            <li>{{'PROJECT_MANAGER_REQUIREMENTS_LIST_2' | translate}}</li>
                            <li>{{'PROJECT_MANAGER_REQUIREMENTS_LIST_3' | translate}}</li>
                        </ul>
                    </div>
                    <div class="col-md-6 pull-left border-bottom-0">
                        <br /><br /><h4 class="black-text thin-text">{{'PROJECT_MANAGER_PREFERENCES' | translate}}</h4><br /><br />
                        <ul>
                            <li>{{'PROJECT_MANAGER_PREFERENCES_LIST_1' | translate}}</li>
                            <li>
                                {{'PROJECT_MANAGER_PREFERENCES_LIST_2' | translate}} <a href="mailto:job-prog-uk@renetusa.com">job-prog-uk@renetusa.com</a>
                            </li>
                        </ul>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </article>
    <div class="clearfix"></div>
    <div class="text-center">
        <div class="container">
            <br />
            <br />
            <div class="wow zoomIn" data-wow-duration="1s" data-wow-offset="100">
                <div class="wow tada" data-wow-delay="1s" data-wow-duration="1s">
                    <div class="wow rubberBand" data-wow-delay="2000ms" data-wow-duration="1s">
                        <button type="button"
                                class="btn btn-primary btn-lg wow pulse animated"
                                data-wow-delay="300ms"
                                data-wow-iteration="infinite"
                                data-wow-duration="2s"
                                [routerLink]="[prefix, 'contact-us']">
                            {{'CONTACT_BUTTON' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--END coontent-->
