import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import * as WOWFactory from 'wow.js';
import { environment } from '../environments/environment';
import { AppSharedModule } from './app.shared.module';
import { AppComponent } from './components/app/app.component';
import { BASE_URL } from './tokens/base-url.token';
import { IS_BROWSER } from './tokens/is-browser.token';
import { WOW_FACTORY } from './tokens/wow-factory.token';

const BASE_URL_FACTORY = () => {
    if (typeof window !== 'undefined') {
        const port = window.location.port ? `:${window.location.port}` : '';
        return `${window.location.protocol}//${window.location.hostname}${port}`;
    }
    return '';
};

@NgModule({
    imports: [
        BrowserAnimationsModule,
        AppSharedModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        { provide: BASE_URL, useFactory: BASE_URL_FACTORY },
        { provide: IS_BROWSER, useValue: true },
        { provide: WOW_FACTORY, useValue: WOWFactory }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
