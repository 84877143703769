import { Inject, Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { IAppSettings } from '../interfaces/app-settings';
import { AppSettingsService } from './app-settings.service';
import { SettingsService } from './settings.service';

@Injectable({
    providedIn: 'root'
})
export class InitService {

    constructor(
        @Inject(AppSettingsService) private appSettingsService: AppSettingsService,
        @Inject(SettingsService) private settingsService: SettingsService
    ) { }

    init = () => this.settingsService.get().pipe(
        tap(this.setSettings),
        mapTo(true),
        catchError(() => of(true))
    ).toPromise()

    setSettings = (x: IAppSettings): void => {
        this.appSettingsService.captchaUrl.next(x.captchaUrl);
    }
}
