<section id="pageTitle" class="safariHeader" [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h1>
                    <span class="blue-text">{{'ITCONSULTING_TITLE' | translate}}</span><br /><br />
                    {{'ITCONSULTING_TITLE_2' | translate}}
                </h1>
                <div class="wow zoomIn" data-wow-duration="1s" data-wow-offset="100">
                    <div class="wow tada" data-wow-delay="1s" data-wow-duration="1s">
                        <div class="wow rubberBand" data-wow-delay="2000ms" data-wow-duration="1s">
                            <button type="button"
                                    class="btn btn-primary btn-lg wow pulse animated"
                                    data-wow-delay="300ms"
                                    data-wow-iteration="infinite"
                                    data-wow-duration="2s"
                                    [routerLink]="[prefix, 'contact-us']">
                                {{'CONTACT_BUTTON' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
</section>

<!--content-->
<section id="content" class="itConstulting" content="Renet Consulting provides custom IT services, including remote management" [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <!--first container-->
    <article>
        <div class="container">
            <div class="row block-box">
                <h2 class="text-center">{{'ITCONSULTING_UNDERSTAND_YOUR_BUSINESS_h2' | translate}}</h2>
                <div class="col-lg-12">
                    <br />
                    <br />
                    <div class="row block-box">
                        <div class="col-md-6 pull-left">
                            <ul>
                                <li [innerHTML]="'ITCONSULTING_OUR_MISSION_LIST_1' | translate"></li>
                                <li>{{'ITCONSULTING_OUR_MISSION_LIST_2' | translate}}</li>
                            </ul>
                        </div>
                        <div class="col-md-6 pull-left">
                            <ul>
                                <li>{{'ITCONSULTING_OUR_MISSION_LIST_3' | translate}}</li>
                                <li>{{'ITCONSULTING_OUR_MISSION_LIST_4' | translate}}</li>
                            </ul>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <h3 class="text-center black-text">
                        <span class="thin-text">{{'ITCONSULTING_MANY_COST_EFFECTIVE_PLANS_H3' | translate}}</span>
                        {{'ITCONSULTING_YOU_MAY_CHOOSE_TO_FIT_H3' | translate}}
                    </h3>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </article>
    <div class="clearfix"></div>
    <div class="hr-line"></div>
    <div class="clearfix"></div>
    <!--second container-->
    <div class="container it-constulting-content">
        <p [innerHTML]="'ITCONSULTING_WHETHER_MONITOR_ON_PREMISE' | translate"></p>
        <br />
        <div class="row block-box">
            <div class="col-md-3 col-sm-6 pull-left">
                <ul>
                    <li>{{'ITCONSULTING_WHETHER_MONITOR_ON_PREMISE_LIST_1' | translate}}</li>
                    <li>{{'ITCONSULTING_WHETHER_MONITOR_ON_PREMISE_LIST_2' | translate}}</li>
                </ul>
            </div>
            <div class="col-md-3 col-sm-6 pull-left">
                <ul>
                    <li>{{'ITCONSULTING_WHETHER_MONITOR_ON_PREMISE_LIST_3' | translate}}</li>
                    <li>{{'ITCONSULTING_WHETHER_MONITOR_ON_PREMISE_LIST_4' | translate}}</li>
                </ul>
            </div>
            <div class="col-md-3 col-sm-6 pull-left">
                <ul>
                    <li>{{'ITCONSULTING_WHETHER_MONITOR_ON_PREMISE_LIST_5' | translate}}</li>
                    <li>{{'ITCONSULTING_WHETHER_MONITOR_ON_PREMISE_LIST_6' | translate}}</li>
                </ul>
            </div>
            <div class="col-md-3 col-sm-6 pull-left">
                <ul>
                    <li>{{'ITCONSULTING_WHETHER_MONITOR_ON_PREMISE_LIST_7' | translate}}</li>
                </ul>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="hr-line"></div>
    <div class="clearfix"></div>
    <!--third container-->
    <div class="container">
        <p [innerHTML]="'ITCONSULTING_INFORMATION_WE_GATHER' | translate"></p>
        <br />
        <br />
        <div class="row block-box">
            <h2 class="text-center">{{'ITCONSULTING_IT_GOALS_H2' | translate}}</h2>
            <div class="col-lg-4 col-md-4 text-center pull-left">
                <img src="/assets/images/server-management.png?v={{version}}"
                     alt="{{'ITCONSULTING_IMG_SMALL_BUSINESS_SERVER_MANAGEMENT_ALT' | translate}}"
                     title="{{'ITCONSULTING_IMG_SMALL_BUSINESS_SERVER_MANAGEMENT_TITLE' | translate}}"
                     class="wow bounceIn"
                     data-wow-delay=".2s" />
                <h3>{{'ITCONSULTING_IMG_SMALL_BUSINESS_SERVER_MANAGEMENT' | translate}}</h3>
            </div>

            <div class="col-lg-4 col-md-4 text-center pull-left">
                <img src="/assets/images/remote-server-management-monitoring.png?v={{version}}"
                     alt="{{'ITCONSULTING_IMG_REMOTE_SERVER_MANAGEMENT_ALT' | translate}}"
                     title="{{'ITCONSULTING_IMG_REMOTE_SERVER_MANAGEMENT_TITLE' | translate}}"
                     class="wow bounceIn"
                     data-wow-delay=".3s" />
                <h3>{{'ITCONSULTING_IMG_REMOTE_SERVER_MANAGEMENT' | translate}}</h3>
            </div>

            <div class="col-lg-4 col-md-4 text-center pull-left">
                <img src="/assets/images/network-management.png?v={{version}}"
                     alt="{{'ITCONSULTING_IMG_NETWORK_MANAGEMENT_ALT' | translate}}"
                     title="{{'ITCONSULTING_IMG_NETWORK_MANAGEMENT_TITLE' | translate}}"
                     class="wow bounceIn"
                     data-wow-delay=".4s" />
                <h3>Network Management</h3>
            </div>
            <div class="clearfix"></div>
        </div>
        <br />
        <br />
        <p [innerHTML]="'ITCONSULTING_WE_ARE_PARTNERS' | translate"></p>
        <p>&nbsp;</p>
    </div>
    <div class="clearfix"></div>
    <div class="bottom-banner text-center">
        <div class="container">
            <h5>{{'ITCONSULTING_HELP_YOU_SAVE_ON_HARDWARE_H5' | translate}}</h5>
        </div>
    </div>
</section>
<!--END coontent-->
