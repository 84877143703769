import { Injectable, Injector, OnDestroy, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TranslatorService } from '~/services/translator.service';

@Injectable()
export abstract class TranslateBase implements OnInit, OnDestroy {

    readonly subscription = new Subscription();
    translateService: TranslateService;
    translatorService: TranslatorService;
    prefix: string;

    constructor(
        injector: Injector
    ) {
        this.translateService = injector.get(TranslateService);
        this.translatorService = injector.get(TranslatorService);
    }

    ngOnInit() {
        this.setPrefix(this.translatorService.activated);
        this.subscription.add(this.translateService.onLangChange.subscribe((i: LangChangeEvent) => this.setPrefix(i.lang)));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    setPrefix = (lang: string) => {
        this.prefix = lang ? `/${lang}` : '';
    }
}
