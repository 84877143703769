import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { TranslateBase } from '~/bases/translate.base';
import { ScrollingService } from '~/services/scrolling.service';
import { IS_BROWSER } from '~/tokens/is-browser.token';
import { VERSION } from '~/tokens/version.token';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends TranslateBase implements OnInit, OnDestroy {

    readonly pageScrollOffset: number = 145;
    interval = 0;

    constructor(
        @Inject(Injector) injector: Injector,
        @Inject(IS_BROWSER) private isBrowser: boolean,
        @Inject(ScrollingService) private scrollingService: ScrollingService,
        @Inject(VERSION) public version: string,
    ) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();
        this.setInterval();
    }

    setInterval = (): void => {
        if (this.isBrowser) {
            this.interval = 7000;
        }
    }

    scrollToRaute = (link: string, id: string): void => {
        this.scrollingService.scrollToPageAsync(`${this.prefix}/${link}`, {
            scrollTarget: '#' + id,
            scrollOffset: this.pageScrollOffset
        });
    }
}
