import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { TranslateBase } from '~/bases/translate.base';

@Component({
    selector: 'cloud-computing',
    templateUrl: './cloud.computing.component.html'
})
export class CloudComputingComponent extends TranslateBase implements OnInit, OnDestroy {

    constructor(
        injector: Injector,
    ) {
        super(injector);
    }
}
