<section id="pageTitle" class="safariHeader" [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h1>{{'HISTORY_TITLE' | translate}}</h1>
                <div class="wow zoomIn" data-wow-duration="1s" data-wow-offset="100">
                    <div class="wow tada" data-wow-delay="1s" data-wow-duration="1s">
                        <div class="wow rubberBand" data-wow-delay="2000ms" data-wow-duration="1s">
                            <button type="button"
                                    class="btn btn-primary btn-lg wow pulse animated"
                                    data-wow-delay="300ms"
                                    data-wow-iteration="infinite"
                                    data-wow-duration="2s"
                                    [routerLink]="[prefix, 'contact-us']">
                                {{'CONTACT_BUTTON' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
</section>

<!--content-->
<section id="content" content="Renet Consulting History" [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <article>
        <div class="container">
            <div class="row vertical-line">
                <div class="col-sm-2">
                    <h3 class="black-text text-right">1996</h3>
                </div>
                <div class="col-sm-10">
                    <p [innerHTML]="'HISTORY_1' | translate"></p>
                </div>
            </div>
            <div class="row vertical-line">
                <div class="col-sm-2">
                    <h3 class="black-text text-right">1998</h3>
                </div>
                <div class="col-sm-10">
                    <p [innerHTML]="'HISTORY_2' | translate"></p>
                </div>
            </div>
            <div class="row vertical-line">
                <div class="col-sm-2">
                    <h3 class="black-text text-right">1998-2000</h3>
                </div>
                <div class="col-sm-10">
                    <p [innerHTML]="'HISTORY_3' | translate"></p>
                </div>
            </div>
            <div class="row vertical-line">
                <div class="col-sm-2">
                    <h3 class="black-text text-right">2000-2003</h3>
                </div>
                <div class="col-sm-10">
                    <p [innerHTML]="'HISTORY_4' | translate"></p>
                </div>
            </div>
            <div class="row vertical-line">
                <div class="col-sm-2">
                    <h3 class="black-text text-right">2001-2002</h3>
                </div>
                <div class="col-sm-10">
                    <p [innerHTML]="'HISTORY_5' | translate"></p>
                </div>
            </div>
            <div class="row vertical-line">
                <div class="col-sm-2">
                    <h3 class="black-text text-right">2001-2004</h3>
                </div>
                <div class="col-sm-10">
                    <p [innerHTML]="'HISTORY_6' | translate"></p>
                </div>
            </div>
            <div class="row vertical-line">
                <div class="col-sm-2">
                    <h3 class="black-text text-right">2004-2008</h3>
                </div>
                <div class="col-sm-10">
                    <p [innerHTML]="'HISTORY_7' | translate"></p>
                </div>
            </div>
            <div class="row vertical-line">
                <div class="col-sm-2">
                    <h3 class="black-text text-right">2008-2010</h3>
                </div>
                <div class="col-sm-10">
                    <p [innerHTML]="'HISTORY_8' | translate"></p>
                </div>
            </div>
            <div class="row vertical-line">
                <div class="col-sm-2">
                    <h3 class="black-text text-right">2010-2015</h3>
                </div>
                <div class="col-sm-10">
                    <p [innerHTML]="'HISTORY_9' | translate"></p>
                </div>
            </div>
            <div class="row vertical-line">
                <div class="col-sm-2">
                    <h3 class="black-text text-right">2015 – 2023</h3>
                </div>
                <div class="col-sm-10">
                    <p>
                        {{'HISTORY_11_FIRST_PART' | translate}}
                        <a [routerLink]="[prefix, 'projects', 'iamsapien']">iAmSapien</a> –
                        {{'HISTORY_11_SECOND_PART' | translate}}
                        <strong>World Wise People, LLC</strong>.
                    </p>
                </div>
            </div>
            <div class="row vertical-line">
                <div class="col-sm-2">
                    <h3 class="black-text text-right">2017 - 2018</h3>
                </div>
                <div class="col-sm-10">
                    <p>
                        {{'HISTORY_12_FIRST_PART' | translate}}
                        <a [routerLink]="[prefix, 'projects', 'promise']">ProMISe™</a>
                        {{'HISTORY_12_SECOND_PART' | translate}}
                    </p>
                </div>
            </div>
            <div class="row vertical-line">
                <div class="col-sm-2">
                    <h3 class="black-text text-right">2017 - 2018</h3>
                </div>
                <div class="col-sm-10">
                    <p>
                        {{'HISTORY_13_1' | translate}} <a href="https://play.google.com/store/apps/details?id=org.nativescript.tooltech.photolog" target="_blank">PhotoLog</a> {{'HISTORY_13_2' | translate}} <a href="http://tooltechcellular.com/" target="_blank">Tool Tech, LLC</a>.
                    </p>
                </div>
            </div>
            <div class="row vertical-line">
                <div class="col-sm-2">
                    <h3 class="black-text text-right">2018</h3>
                </div>
                <div class="col-sm-10">
                    <p>
                        {{'HISTORY_14' | translate}} <a href="http://yzpntile.com" target="_blank">YZPN Master Construction</a>.
                    </p>
                </div>
            </div>
            <div class="row vertical-line">
                <div class="col-sm-2">
                    <h3 class="black-text text-right">2018</h3>
                </div>
                <div class="col-sm-10">
                    <p>
                        {{'HISTORY_13_1' | translate}} <a href="" [routerLink]="[prefix, 'projects', 'carroll-food-intolerance-app']">Carroll Food Intolerance</a> {{'CFI_DESCRIPTION_0' | translate}}
                    </p>
                </div>
            </div>
            <div class="row vertical-line">
                <div class="col-sm-2">
                    <h3 class="black-text text-right">2020</h3>
                </div>
                <div class="col-sm-10">
                    <p>{{'HISTORY_15' | translate}}</p>
                </div>
            </div>
        </div>
    </article>
</section>
<!--END coontent-->
