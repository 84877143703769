<section id="pageTitle"></section>
<section id="solution" class="portfolioContent" [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <div class="container">
        <div class="row d-flex align-items-stretch">
            <div class="col-lg-6 pull-left"></div>
            <div class="col-lg-6 pull-left">
                <h2 class="text-center black-text">{{'ONEGIN_BLUES_TITLE' | translate}}</h2>
                <h3 class="thin-text">{{'THE_GOAL' | translate}}</h3>
                <p>{{'ONEGIN_BLUES_GOAL_DESCRIPTION' | translate}}</p>
                <p>&nbsp;</p>
                <h3 class="thin-text">{{'THE_RESULT' | translate}}</h3>
                <p>{{'ONEGIN_BLUES_DESCRIPTION' | translate}}</p>
                <p>&nbsp;</p>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</section>
<article>
    <!--content-->
    <section id="content" class="portfolio-container" [class.russian]="prefix === '/ru' || prefix === '/ua'" content="Renet Consulting example of a custom software">
        <img src="/assets/images/profiles/helen/portfolio/onegin-blues.jpg?v={{version}}" alt="{{'ONEGIN_BLUES_ALT' | translate}}" />
        <div class="other-works text-center">
            <p>
                {{'SEE_PROFILE' | translate}} <a href="#" [routerLink]="[prefix, 'helen-doroshenko', 'profile']">{{'SEE_HELENAS_PROFILE' | translate}}</a> {{'SEE_PROFILE_2' | translate}}
                <button type="button"
                        class="btn btn-primary btn-lg wow pulse animated"
                        data-wow-delay="300ms"
                        data-wow-iteration="infinite"
                        data-wow-duration="2s"
                        [routerLink]="[prefix, 'contact-us']">
                    {{'CONTACT_BUTTON' | translate}}
                </button>
            </p>
        </div>
        <div class="clearfix"></div>
    </section>
    <!--END coontent-->
</article>
