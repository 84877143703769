<section id="pageTitle"></section>
<article>
    <section id="solution" class="portfolioContent" [class.russian]="prefix === '/ru' || prefix === '/ua'">
        <div class="container">
            <div class="row d-flex align-items-stretch">
                <div class="col-md-12 pull-left text-center">
                    <img class="m-0" src="/assets/images/profiles/alex/cfi-header-img.png" alt="{{'CFI_ALT' | translate}}" />
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </section>

    <section id="solution" class="container-fluid about-cfi">
        <div class="container">
            <div class="row d-flex align-items-center">
                <div class="col-md-3 pull-left cfi-logo">
                    <img src="/assets/images/profiles/alex/cfi-logo.png" alt="{{'CFI_ALT' | translate}}" />
                </div>
                <div class="col-md-9 pull-left">
                    <h2 class="black-text">{{'CFI_ALT' | translate}}</h2>
                    <p>{{'CFI_DESCRIPTION_0' | translate}}</p>
                    <p class="m-0">{{'CFI_DESCRIPTION_1' | translate}}</p>
                    <p class="text-left m-0">
                        <span>
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=org.thecarrollinstitute.foodtesting" class="btn btn-secondary btn-lg">{{'GO_TO_WEBSITE_BUTTON' | translate}}</a>
                        </span>
                    </p>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </section>
    <!--content-->
    <section id="content" class="portfolio-container" [class.russian]="prefix === '/ru' || prefix === '/ua'" content="Renet Consulting example of a custom software">
        <div class="portfolio-container-1">
            <div class="container">
                <img class="wow bounceInRight" data-wow-delay=".4s" src="/assets/images/profiles/alex/cfi-app.png" alt="{{'CFI_ALT' | translate}}" />
                <div class="row d-flex align-items-start">
                    <div class="col-md-3 pull-left circle d-lg-block d-md-block d-sm-none"></div>
                    <div class="col-md-9 pull-left">
                        <p class="pb-3">{{'CFI_DESCRIPTION_2' | translate}}</p>
                        <ul class="mb-0 pb-0">
                            <li>{{'CFI_DESCRIPTION_3' | translate}}</li>
                            <li>{{'CFI_DESCRIPTION_4' | translate}}</li>
                            <li class="mb-0">{{'CFI_DESCRIPTION_5' | translate}}</li>
                        </ul>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-lg-3 col-md-6 pull-left text-center">
                        <img src="/assets/images/profiles/alex/cfi-example-1.png" alt="{{'CFI_ALT' | translate}}" />
                    </div>
                    <div class="col-lg-3 col-md-6 pull-left text-center">
                        <img src="/assets/images/profiles/alex/cfi-example-2.png" alt="{{'CFI_ALT' | translate}}" />
                    </div>
                    <div class="col-lg-3 col-md-6 pull-left text-center">
                        <img src="/assets/images/profiles/alex/cfi-example-3.png" alt="{{'CFI_ALT' | translate}}" />
                    </div>
                    <div class="col-lg-3 col-md-6 pull-left text-center">
                        <img src="/assets/images/profiles/alex/cfi-example-4.png" alt="{{'CFI_ALT' | translate}}" />
                    </div>
                </div>
            </div>
        </div>
        <div class="portfolio-container-2">
            <div class="container">
                <div class="row d-flex align-items-center my-5 pb-5">
                    <div class="col-md-9 pull-left">
                        <p class="text-right m-0">{{'CFI_DESCRIPTION_6' | translate}}</p>
                    </div>
                    <div class="col-md-3 pull-left circle circle-right d-lg-block d-md-block d-sm-none"></div>
                    <div class="clearfix"></div>
                </div>
                <div class="row d-flex align-items-start">
                    <div class="col-lg-3 col-md-6 pull-left text-center">
                        <img class="m-0" src="/assets/images/profiles/alex/cfi-example-5.png" alt="{{'CFI_ALT' | translate}}" />
                    </div>
                    <div class="col-lg-9 col-md-6 pull-left">
                        <p>{{'CFI_DESCRIPTION_7' | translate}}</p>
                        <div class="col-lg-4 col-md-12 pl-0">
                            <img class="mt-3" src="/assets/images/profiles/alex/cfi-example-6.png" alt="{{'CFI_ALT' | translate}}" />
                        </div>
                    </div>
                </div>
                <p class="text-center">
                    <button type="button"
                            class="btn btn-primary btn-lg wow pulse animated"
                            data-wow-delay="300ms"
                            data-wow-iteration="infinite"
                            data-wow-duration="2s"
                            [routerLink]="[prefix, 'contact-us']">
                        {{'CONTACT_BUTTON' | translate}}
                    </button>
                </p>
            </div>
        </div>
    </section>
    <!--END coontent-->
</article>
