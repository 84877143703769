import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TranslateBase } from '~/bases/translate.base';
import { TranslatorService } from '~/services/translator.service';

@Component({
    selector: 'translate',
    template: ''
})
export class TranslateComponent extends TranslateBase implements OnInit, OnDestroy {

    constructor(
        @Inject(Injector) injector: Injector,
        @Inject(Router) private router: Router,
        @Inject(TranslatorService) public translatorService: TranslatorService,
    ) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();
        this.setDefaults();
        this.subscription.add(this.router.events.pipe(filter(i => i instanceof NavigationEnd)).subscribe(this.translatorService.use));
    }

    setDefaults = (): void => {
        const langs = Object.keys(this.translatorService.langs);
        this.translateService.setDefaultLang(langs[0]);
        this.translateService.addLangs(langs);
    }
}
