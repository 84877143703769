<section id="pageTitle" class="safariHeader" [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h1>
                    <span content="Custom Software Development" class="blue-text">{{'CUSTOMSOFTWARE_TITLE' | translate}}</span>
                    <br />
                    <br />
                    {{'CUSTOMSOFTWARE_TITLE_2' | translate}}
                </h1>
                <div class="wow zoomIn" data-wow-duration="1s" data-wow-offset="100">
                    <div class="wow tada" data-wow-delay="1s" data-wow-duration="1s">
                        <div class="wow rubberBand" data-wow-delay="2000ms" data-wow-duration="1s">
                            <button type="button"
                                    class="btn btn-primary btn-lg wow pulse animated"
                                    data-wow-delay="300ms"
                                    data-wow-iteration="infinite"
                                    data-wow-duration="2s"
                                    [routerLink]="[prefix, 'contact-us']">
                                {{'CONTACT_BUTTON' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
</section>

<!--content-->
<section id="content" class="customSoftware" content="We build custom software for your business nationally and locally, in Portland, OR" [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <article>
        <div class="container">
            <div class="row">
                <div class="col-md-6 pull-left">
                    <p>{{'CUSTOMSOFTWAR_VIRTUALLY_ALL_BUSINESSES' | translate}}</p>
                    <p>&nbsp;</p>
                    <p [innerHTML]="'CUSTOMSOFTWAR_OFF_THE_SHELF_SOFTWARE' | translate"></p>
                </div>
                <div class="col-md-6 pull-left">
                    <p>{{'CUSTOMSOFTWAR_OUR_PROFOUND_EXPERIENCE' | translate}}</p>
                    <p>&nbsp;</p>
                    <p>{{'CUSTOMSOFTWAR_FROM_FRONT_TO_BACK_END' | translate}}</p>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </article>
    <div class="clearfix"></div>
    <div class="hr-line"></div>
    <div class="clearfix"></div>
    <article>
        <div class="container">
            <div class="row">
                <div class="col-lg-12 custom-software-content">
                    <h2 class="text-center">{{'CUSTOMSOFTWAR_WHEN_DO_YOU_DECIDE_H2' | translate}}</h2>
                    <div class="row block-box">
                        <div class="col-lg-2 col-md-6 pull-left">
                            <img src="/assets/images/you-need-custom-software.png?v={{version}}"
                                 alt="{{'CUSTOMSOFTWAR_IMG_CUSTOM_SOFTWARE_ALT' | translate}}"
                                 title="{{'CUSTOMSOFTWAR_IMG_CUSTOM_SOFTWARE_TITLE' | translate}}"
                                 class="wow bounceIn"
                                 data-wow-delay=".2s" />
                        </div>
                        <div class="col-lg-10 col-md-6 pull-left">
                            <h3 class="black-text">{{'CUSTOMSOFTWAR_IMG_CUSTOM_SOFTWARE' | translate}}</h3>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="row block-box">
                        <div class="col-lg-2 col-md-6 pull-left">
                            <img src="/assets/images/automating-businesses-processes.png?v={{version}}"
                                 alt="{{'CUSTOMSOFTWAR_IMG_CUSTOM_SOFTWARE_COULD_AUTOMATE_ALT' | translate}}"
                                 title="{{'CUSTOMSOFTWAR_IMG_CUSTOM_SOFTWARE_COULD_AUTOMATE_TITLE' | translate}}"
                                 class="wow bounceIn" data-wow-delay=".3s" />
                        </div>
                        <div class="col-lg-10 col-md-6 pull-left">
                            <h3 class="black-text">{{'CUSTOMSOFTWAR_IMG_CUSTOM_SOFTWARE_COULD_AUTOMATE' | translate}}</h3>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="row block-box">
                        <div class="col-lg-2 col-md-6 pull-left">
                            <img src="/assets/images/streamline-processes.png?v={{version}}"
                                 alt="{{'CUSTOMSOFTWAR_IMG_CUSTOM_SOFTWARE_COULD_STREAMLINE_ALT' | translate}}"
                                 title="{{'CUSTOMSOFTWAR_IMG_CUSTOM_SOFTWARE_COULD_STREAMLINE_TITLE' | translate}}"
                                 class="wow bounceIn"
                                 data-wow-delay=".4s" />
                        </div>
                        <div class="col-lg-10 col-md-6 pull-left">
                            <h3 class="black-text">{{'CUSTOMSOFTWAR_IMG_CUSTOM_SOFTWARE_COULD_STREAMLINE' | translate}}</h3>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="row block-box">
                        <div class="col-lg-2 col-md-6 pull-left">
                            <img src="/assets/images/address-unique-requirements.png?v={{version}}"
                                 alt="{{'CUSTOMSOFTWAR_IMG_CUSTOM_SOFTWARE_COULD_ADDRESS_ALT' | translate}}"
                                 title="{{'CUSTOMSOFTWAR_IMG_CUSTOM_SOFTWARE_COULD_ADDRESS_TITLE' | translate}}"
                                 class="wow bounceIn" d
                                 ata-wow-delay=".5s" />
                        </div>
                        <div class="col-lg-10 col-md-6 pull-left">
                            <h3 class="black-text">{{'CUSTOMSOFTWAR_IMG_CUSTOM_SOFTWARE_COULD_ADDRESS' | translate}}</h3>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <p>&nbsp;</p>
                    <p [innerHTML]="'CUSTOMSOFTWAR_FIRST_STEP' | translate"></p>
                    <p [innerHTML]="'CUSTOMSOFTWAR_SECOND_STEP' | translate"></p>
                    <p>&nbsp;</p>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </article>
    <div class="clearfix"></div>
    <div class="bottom-banner text-center">
        <div class="container">
            <h5>{{'CUSTOMSOFTWAR_WE_COMBINE_CUTTING_EDGE_TECHNOLOGY_H5' | translate}}</h5>
        </div>
    </div>
</section>
<!--END coontent-->
