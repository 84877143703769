<section id="pageTitle"></section>
<!--content-->
<section id="content" 
         class="notFound" 
         content="Renet Consulting Not Found Page" 
         [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h2 class="black-text">{{'NOT_FOUND_H2' | translate}}</h2>
                <div class="not-found-img-block">
                    <img src="/assets/images/404-page-not-found-cogwheel-img.png?v={{version}}"
                         alt="{{'IMG_NOT_FOUND_ALT' | translate}}"
                         title="{{'IMG_NOT_FOUND_TITLE' | translate}}" />
                </div>
                <h3 class="black-text">{{'NOT_FOUND_H3' | translate}}</h3>
                <p>{{'NOT_FOUND_GO' | translate}} 
                <a preventDefault href="/" (click)="back()">{{'NOT_FOUND_BACK' | translate}}</a> 
                {{'NOT_FOUND_OR_VISIT' | translate}} 
                <a [routerLink]="[prefix, 'home']">{{'NOT_FOUND_HOME' | translate}}</a> 
                {{'NOT_FOUND_PAGE' | translate}}</p>
            </div>
        </div>
    </div>  
</section>
<!--END content-->
