<section id="pageTitle"></section>
<section id="solution" 
         class="iAmSapienContent" 
         [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <article>
        <div class="container">
            <div class="row block-box">
                <div class="col-lg-6 text-center pull-left">
                    <img class="wow bounceInLeft"
                         data-wow-delay=".2s"
                         src="/assets/images/iamsapien-img-1.png?v={{version}}"
                         alt="{{'IAMSAPIEN_IMG_WEB_APPLICATION_ALT' | translate}}"
                         title="{{'IAMSAPIEN_IMG_WEB_APPLICATION_TITLE' | translate}}" />
                </div>
                <div class="col-lg-6 wow bounceInRight pull-left" data-wow-delay=".3s">
                    <h2 class="text-center white-text">{{'IAMSAPIEN_SOCIAL_MEDIA_PLATFORM_H2' | translate}}</h2>
                    <h3 class="black-text thin-text white-text" [innerHTML]="'IAMSAPIEN_SOCIAL_MEDIA_PLATFORM_H3' | translate"></h3>
                    <h3 class="white-text thin-text white-text">{{'IAMSAPIEN_LEARNED_FROM_EXPERIENCE_H3' | translate}}</h3>
                    <p class="text-center white-text">
                        <a target="_blank" href="https://iamsapien.com/" class="btn btn-secondary btn-lg">{{'GO_TO_WEBSITE_BUTTON' | translate}}</a>
                    </p>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </article>
</section>
<!--content-->
<section id="content" 
         content="iAmSapien is one of Renet Consulting projects of developing custom web application" 
         [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <p [innerHTML]="'IAMSAPIEN_IT_WAS_THEN_THAT' | translate"></p>
                <p>&nbsp;</p>
                <p>{{'IAMSAPIEN_AFTER_MANY_MEETINGS' | translate}}</p>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="hr-line"></div>
    <div class="clearfix"></div>
    <div class="container">
        <h3 class="black-text text-center">{{'IAMSAPIEN_A_VERY_POPULAR_CHOICE_H3' | translate}}</h3>
        <br />
        <br />
        <div class="row block-box">
            <div class="col-md-6 pull-left">
                <ul class="pb-0">
                    <li class="mb-0">{{'IAMSAPIEN_A_VERY_POPULAR_CHOICE_LIST_1' | translate}}</li>
                </ul>
            </div>
            <div class="col-md-6 pull-left pb-0">
                <ul class="pb-0">
                    <li class="mb-0">{{'IAMSAPIEN_A_VERY_POPULAR_CHOICE_LIST_2' | translate}}</li>
                </ul>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="hr-line"></div>
    <div class="clearfix"></div>
    <div class="container">
        <div class="row block-box">
            <div class="col-lg-12">
                <p [innerHTML]="'IAMSAPIEN_IT_WAS_ALSO_DECIDED' | translate"></p>
                <p>&nbsp;</p>
                <p>{{'IAMSAPIEN_A_UNIQUE_ANGLE_ON_MANY_WEB_APP' | translate}}</p>
                <p>&nbsp;</p>
                <p [innerHTML]="'IAMSAPIEN_IN_THE_FALL_OF' | translate"></p>
                <p>&nbsp;</p>
                <p [innerHTML]="'IAMSAPIEN_BEING_EARLY_ADOPTERS' | translate"></p>
                <p>&nbsp;</p>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
    <div class="bottom-banner text-center position-relative">
        <img class="wow bounceInUp m-0"
             data-wow-delay=".2s"
             src="/assets/images/iamsapien-img-2.png?v={{version}}"
             alt="{{'IAMSAPIEN_IMG_WEB_APPLICATION_ALT' | translate}}"
             title="{{'IAMSAPIEN_IMG_WEB_APPLICATION_TITLE' | translate}}" />
    </div>
</section>
<!--END coontent-->
