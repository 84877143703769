import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PwaUpdatesComponent } from './pwa-updates.component';

@NgModule({
    declarations: [PwaUpdatesComponent],
    imports: [MatSnackBarModule, MatButtonModule],
    exports: [PwaUpdatesComponent]
})
export class PwaUpdatesModule { }
