import { Location } from '@angular/common';
import { Component, Inject, Injector, OnDestroy, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateBase } from '~/bases/translate.base';
import { Response } from 'express';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { isPlatformBrowser } from '@angular/common';
import { VERSION } from '~/tokens/version.token';

@Component({
    selector: 'not-found',
    templateUrl: './not.found.component.html',
    styleUrls: ['./not.found.component.scss']
})
export class NotFoundComponent extends TranslateBase implements OnInit, OnDestroy {

    constructor(
        @Inject(Injector) injector: Injector,
        @Inject(Location) private location: Location,
        @Inject(Meta) private metaService: Meta,
        @Optional() @Inject(RESPONSE) private response: Response,
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(VERSION) public version: string,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.setMeta();
        if (!isPlatformBrowser(this.platformId)) {
            this.response.status(404);
        }
    }

    setMeta = (): void => {
        this.metaService.updateTag({ name: 'IS_NOT_FOUND', content: '' });
    }

    back = (): void => {
        this.location.back();
    }
}
