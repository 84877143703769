import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { TranslateBase } from '~/bases/translate.base';
import { VERSION } from '~/tokens/version.token';

@Component({
    selector: 'helen-doroshenko-profile',
    templateUrl: './helen.doroshenko.component.html',
    styleUrls: [
        '../../../our.team/our.team.component.scss',
        './helen.doroshenko.component.scss'
    ]
})
export class HelenDoroshenkoProfileComponent extends TranslateBase implements OnInit, OnDestroy {

    constructor(
        injector: Injector,
        @Inject(VERSION) public version: string,
    ) {
        super(injector);
    }
}
