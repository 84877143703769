<section id="pageTitle"></section>
<section id="solution"
         class="portfolioContent"
         [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <div class="container">
        <div class="row d-flex align-items-stretch">
            <div class="col-lg-6 pull-left">
                <h2 class="text-center black-text">{{'PROMISE_TITLE' | translate}}</h2>
                <h3 class="thin-text">{{'THE_GOAL' | translate}}</h3>
                <p>{{'PROMISE_GOAL_DESCRIPTION' | translate}}</p>
                <p>{{'PROMISE_GOAL_DESCRIPTION_1' | translate}}</p>
                <p>&nbsp;</p>
            </div>
            <div class="col-lg-6 pull-left wow bounceInRight" data-wow-delay=".3s">
            </div>
            <div class="col-lg-6 pull-left">
                <h3 class="thin-text">{{'THE_RESULT' | translate}}</h3>
                <p>{{'PROMISE_DESCRIPTION_0' | translate}}</p>
            </div>
            <div class="col-lg-6 pull-left">
                <h3 class="d-lg-block d-md-none">&nbsp;</h3>
                <p>{{'PROMISE_DESCRIPTION_1' | translate}}</p>
                <p>{{'PROMISE_DESCRIPTION_2' | translate}}</p>
                <p>&nbsp;</p>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</section>
<article>
    <!--content-->
    <section id="content" class="portfolio-container" [class.russian]="prefix === '/ru' || prefix === '/ua'" content="Renet Consulting example of a custom software">
        <div class="video-bg">
            <div class="container">
                <div class="video-container">
                    <div class="video-border">
                        <video autoplay loop muted>
                            <source src="/assets/video/promise.mp4" type="video/mp4">
                        </video>
                    </div>
                    <img src="/assets/images/profiles/helen/portfolio/promise-monitor.png?v={{version}}" alt="{{'PROMISE_TITLE' | translate}}" />
                </div>
            </div>
        </div>
        <div class="container">
            <img src="/assets/images/profiles/helen/portfolio/promise-pages.png?v={{version}}" alt="{{'PROMISE_TITLE' | translate}}" />
            <img src="/assets/images/profiles/helen/portfolio/promise-devices.png?v={{version}}" class="devices d-xs-none wow bounceInRight" data-wow-delay=".4s" alt="{{'PROMISE_TITLE' | translate}}" />
            <div class="other-works text-lg-center text-md-left text-sm-left">
                <p>
                    {{'SEE_PROFILE' | translate}} <a href="#" [routerLink]="[prefix, 'helen-doroshenko', 'profile']">{{'SEE_HELENAS_PROFILE' | translate}}</a> {{'SEE_PROFILE_2' | translate}}
                    <button type="button"
                            class="btn btn-primary btn-lg wow pulse animated"
                            data-wow-delay="300ms"
                            data-wow-iteration="infinite"
                            data-wow-duration="2s"
                            [routerLink]="[prefix, 'contact-us']">
                        {{'CONTACT_BUTTON' | translate}}
                    </button>
                </p>
            </div>
        </div>
        <div class="clearfix"></div>
    </section>
    <!--END coontent-->
</article>
