<section id="pageTitle"></section>
<section id="content" class="ourTeamContent" [class.russian]="prefix === '/ru' || prefix === '/ua'" content="Renet Consulting multicultural web development and business team">
    <article>
        <div class="container">
            <h2 class="text-center">{{'ELENA_DOROSHENKO_TITLE' | translate}}</h2>
            <br />
            <br />
            <div class="clearfix"></div>
            <div class="row d-flex align-items-stretch bd-highlight">
                <div class="col-lg-4 bd-highlight pull-left align-self-center our-team">
                    <img src="/assets/images/Elena-Doroshenko-img.jpg?v={{version}}" width="120" height="120" alt="{{'OURTEAM_IMG_ELENA_DOROSHENKO_ALT' | translate}}" title="{{'OURTEAM_IMG_ELENA_DOROSHENKO_TITLE' | translate}}" class="wow bounceIn" data-wow-delay=".6s" />
                    <h3>{{'OURTEAM_IMG_ELENA_DOROSHENKO' | translate}}</h3>
                    <h4 class="mb-lg-0 mb-md-3 mb-sm-3">{{'OURTEAM_IMG_ELENA_DOROSHENKO_POSITION' | translate}}</h4>
                </div>
                <div class="col-lg-8 bd-highlight pull-left align-self-center our-team">
                    <p>{{'ELENA_DOROSHENKO_P_0' | translate}}</p>
                    <p class="mb-0">{{'ELENA_DOROSHENKO_P_1' | translate}}</p>
                </div>
            </div>
            <div class="hr-line"></div>
            <div class="container">
            </div>
            <div class="col-lg-12 bd-highlight pull-left">
                <h3 class="thin-text text-center black-text mb-5">{{'ELENA_DOROSHENKO_PROJECTS_TITLE_0' | translate}}</h3>
                <div class="row hover01 column column-2">
                    <div class="col-lg-4 px-0" [routerLink]="[prefix, 'projects', 'yzpn']">
                        <figure class="wow bounceInLeft" data-wow-delay=".2s">
                            <video autoplay loop muted>
                                <source src="/assets/video/yzpn.mp4" type="video/mp4">
                            </video>
                        </figure>
                        <span><strong>{{'YZPN_TITLE' | translate}}</strong></span>
                    </div>
                    <div class="col-lg-4 px-0" [routerLink]="[prefix, 'projects', 'promise']">
                        <figure class="wow bounceIn" data-wow-delay=".3s">
                            <video autoplay loop muted>
                                <source src="/assets/video/promise.mp4" type="video/mp4">
                            </video>
                        </figure>
                        <span><strong>{{'PROMISE_TITLE' | translate}}</strong></span>
                    </div>
                    <div class="col-lg-4 px-0" [routerLink]="[prefix, 'projects', 'pulse-electronics']">
                        <figure class="wow bounceInRight" data-wow-delay=".4s">
                            <img src="/assets/images/profiles/helen/profile/pulse-electronics.jpg?v={{version}}" alt="{{'PULSE_ELECTRONICS_TITLE' | translate}}" />
                        </figure>
                        <span><strong>{{'PULSE_ELECTRONICS_TITLE' | translate}}</strong></span>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 bd-highlight pull-left">
                <h3 class="thin-text text-center black-text my-5">{{'ELENA_DOROSHENKO_PROJECTS_TITLE_1' | translate}}</h3>
                <div class="row hover01 column column-2">
                    <div class="col-lg-4 px-0" [routerLink]="[prefix, 'helen-doroshenko', 'prev-projects', 'onegin-blues']">
                        <figure class="wow bounceInLeft" data-wow-delay=".5s"><img src="/assets/images/profiles/helen/profile/onegin-blues.jpg?v={{version}}" alt="{{'ONEGIN_BLUES_ALT' | translate}}" /></figure>
                        <span><strong>{{'ONEGIN_BLUES_SPAN' | translate}}</strong></span>
                    </div>
                    <div class="col-lg-4 px-0" [routerLink]="[prefix, 'helen-doroshenko', 'prev-projects', 'ad-empire-media']">
                        <figure class="wow bounceIn" data-wow-delay=".6s"><img src="/assets/images/profiles/helen/profile/aem.jpg?v={{version}}" alt="{{'AD_EMPIRE_MEDIA' | translate}}" /></figure>
                        <span><strong>{{'AD_EMPIRE_MEDIA' | translate}}</strong></span>
                    </div>
                    <div class="col-lg-4 px-0" [routerLink]="[prefix, 'helen-doroshenko', 'prev-projects', 'ecology']">
                        <figure class="wow bounceInRight" data-wow-delay=".7s"><img src="/assets/images/profiles/helen/profile//ecology.jpg?v={{version}}" alt="{{'ECOLOGY' | translate}}" /></figure>
                        <span><strong>{{'ECOLOGY' | translate}}</strong></span>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
            <br />
            <br />
            <div class="text-center">
                <div class="wow zoomIn" data-wow-duration="1s" data-wow-offset="100">
                    <div class="wow tada" data-wow-delay="1s" data-wow-duration="1s">
                        <div class="wow rubberBand" data-wow-delay="2000ms" data-wow-duration="1s">
                            <button type="button" class="btn btn-primary btn-lg wow pulse animated" data-wow-delay="300ms" data-wow-iteration="infinite" data-wow-duration="2s" [routerLink]="[prefix, 'contact-us']">
                                {{'CONTACT_BUTTON' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </article>
</section>
<!--END coontent-->
