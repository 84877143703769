import { ApplicationRef, Component, HostListener, Inject, Injector, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, first, map, mergeMap } from 'rxjs/operators';
import { TranslateBase } from '~/bases/translate.base';
import { AppSettingsService } from '~/services/app-settings.service';
import { LinkService } from '~/services/link.service';
import { MetadataService } from '~/services/metadata.service';
import { ScrollingService } from '~/services/scrolling.service';
import { TranslatorService } from '~/services/translator.service';
import { BASE_URL } from '~/tokens/base-url.token';
import { IS_BROWSER } from '~/tokens/is-browser.token';
import { VERSION } from '~/tokens/version.token';
import { WOW_FACTORY } from '~/tokens/wow-factory.token';

declare var gtag;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [LinkService]
})
export class AppComponent extends TranslateBase implements OnInit, OnDestroy {

    isActive: boolean;
    wow;

    constructor(
        @Inject(Injector) injector: Injector,
        @Inject(WOW_FACTORY) private WOWFactory,
        @Inject(IS_BROWSER) private isBrowser,
        @Inject(VERSION) public version: string,
        @Inject(BASE_URL) public baseUrl: string,
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private route: ActivatedRoute,
        @Inject(TranslatorService) public translatorService: TranslatorService,
        @Inject(AppSettingsService) private settingsService: AppSettingsService,
        @Inject(ScrollingService) public scrollingService: ScrollingService,
        @Inject(MetadataService) private metadataService: MetadataService,
        @Inject(LinkService) public linkService: LinkService,
        @Inject(ApplicationRef) private applicationRef: ApplicationRef,
    ) {
        super(injector);

        const navEndEvents$ = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            );

        navEndEvents$.subscribe((event: NavigationEnd) => {
            gtag('config', 'G-6VYV42R7NB', {
                page_path: event.urlAfterRedirects
            });
        });
    }

    ngOnInit() {
        console.log('APP VERSION', this.version);
        super.ngOnInit();
        this.subscription.add(this.router.events.pipe(
            filter(i => i instanceof NavigationEnd),
            map(() => this.route),
            map(i => {
                while (i.firstChild) { i = i.firstChild; }
                return i;
            }),
            filter(i => i.outlet === 'primary'),
            mergeMap(i => i.data))
            .subscribe(e => {
                this.metadataService.updateTag(e);
                this.updateWOW();
                this.setLinks();
                this.scrollToTop();
            }));
        this.onStable();
    }

    @HostListener('document:scroll') scroll = (): void => {
        if (this.isBrowser) {
            const scrollY: number = window.pageYOffset;
            let positionForHideBtn: number = this.settingsService.positionHideGoUpBtn;
            try {
                positionForHideBtn = document.documentElement.clientHeight;
            } catch (e) { }
            if (scrollY > positionForHideBtn) {
                this.isActive = true;
            } else {
                this.isActive = false;
            }
        }
    }

    onStable = (): void => {
        this.subscription.add(this.applicationRef.isStable.pipe(filter(i => i), first()).subscribe(this.setWOW));
    }

    setWOW = (): void => {
        if (this.isBrowser && this.WOWFactory) {
            this.wow = new this.WOWFactory();
            this.wow.init();
        }
    }

    updateWOW = (): void => {
        if (this.wow && this.wow.sync) {
            this.wow.sync();
        }
    }

    scrollToTop = (): void => {
        if (this.isBrowser) {
            this.scrollingService.scrollToAsync({ scrollTarget: '#header-menu' });
        }
    }

    setLinks = (): void => {
        const language: string = this.translatorService.activated;
        if (typeof language === 'string') {
            const russian = 'ru';
            const english = 'en';
            const hreflang: string = (language === russian) ? english : russian;
            const languageUrl = `${this.baseUrl}/${language}/`;
            const hreflangUrl = `${this.baseUrl}/${hreflang}/`;
            const href: string = `${this.baseUrl}${this.router.url}`.replace(languageUrl, hreflangUrl);
            this.linkService.set({ id: 'alternateLanguage', rel: 'alternate', href, hreflang });
        }
    }
}
