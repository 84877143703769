<section id="pageTitle"></section>
<section id="solution" [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <article>
        <div class="container">
            <div class="row block-box">
                <div class="col-lg-12 pull-left">
                    <h2 class="text-center black-text">{{'RBR_TITLE' | translate}}</h2>
                </div>
                <div class="col-lg-6 pull-left">
                    <img class="wow bounceInLeft" data-wow-delay=".2s" src="/assets/images/bg-rockawaybeachresort.jpg?v={{version}}" alt="{{'RBR_IMG_WEBSITE_ON_A_DESKTOP_ALT' | translate}}" title="{{'RBR_IMG_WEBSITE_ON_A_DESKTOP_TITLE' | translate}}" />
                </div>
                <div class="col-lg-6 wow bounceInRight pull-left" data-wow-delay=".3s">
                    <h3 class="black-text thin-text">
                        <span [innerHTML]="'RBR_CLIENT' | translate"></span>
                        <strong><a [routerLink]="[prefix, 'projects', 'promise']">ProMISe™</a></strong>
                        {{'RBR_FOR_THE_RESORT' | translate}}
                    </h3>
                    <p class="text-center">
                        <a target="_blank" href="http://ezrbr.com" class="btn btn-secondary btn-lg">{{'GO_TO_WEBSITE_BUTTON' | translate}}</a>
                    </p>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </article>
</section>
<!--content-->
<section id="content" class="solutionContent" [class.russian]="prefix === '/ru' || prefix === '/ua'" content="Rockaway Beach Resort is one of Renet Consulting most loyal clients">
    <div class="container">
        <div class="row">
            <div class="col-md-6 pull-left">
                <p [innerHTML]="'RBR_IT_SERVICE' | translate"></p>
                <p>&nbsp;</p>
                <p>{{'RBR_AS_A_SONICWALL_PARTNER' | translate}}</p>
                <p>&nbsp;</p>
                <p [innerHTML]="'RBR_SUCCESSFULLY_UPGRADED' | translate"></p>
            </div>
            <div class="col-md-6 pull-left">
                <p>{{'RBR_AT_THE_END_OF' | translate}}</p>
                <p>&nbsp;</p>
                <p>
                    <span [innerHTML]="'RBR_SINCE_THE_SITE_REQUIREMENTS' | translate"></span>
                    <a href="http://ezrbr.com" target="_blank">http://ezrbr.com</a>
                    {{'RBR_FOR_MORE_INFORMATION' | translate}}
                </p>
                <p></p>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</section>
<!--END coontent-->
