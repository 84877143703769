<section id="pageTitle"></section>
<section id="content" class="aboutContent" [attr.content]="'ABOUT_ATTR_CONTENT' | translate" [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <article>
        <div class="container-fluid">
            <div class="container">
                <div class="row wow bounceInLeft block-box" data-wow-delay=".2s">
                    <div class="col-lg-2 text-center pull-left">
                        <img src="/assets/images/your-idea-your-budget.png?v={{version}}" alt="{{'ABOUT_IMG_YOUR_IDEA' | translate}}" title="{{'ABOUT_IMG_YOUR_IDEA_TITLE' | translate}}" />
                    </div>
                    <div class="col-lg-10 pull-left">
                        <h2>{{'ABOUT_SLOGAN' | translate}}</h2>
                        <br />
                        <h3 class="thin-text">{{'ABOUT_HISTORY' | translate}}</h3>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </article>
    <article>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2 class="text-center">{{'ABOUT_TODAY' | translate}}</h2>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="hr-line"></div>
        <div class="clearfix"></div>
        <div class="container">
            <div class="row wow bounceInRight block-box" data-wow-delay=".3s">
                <div class="col-lg-2 text-center pull-left">
                    <img src="/assets/images/we-build-custom-software.png?v={{version}}" alt="{{'ABOUT_IMG_WE_BUILD_ALT' | translate}}" title="{{'ABOUT_IMG_WE_BUILD_TITLE' | translate}}" />
                </div>
                <div class="col-lg-10 pull-left">
                    <h3 class="black-text thin-text">{{'ABOUT_ABOUT_US_START' | translate}}<strong>{{'ABOUT_ABOUT_US_END' | translate}}</strong>.</h3>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </article>
</section>
