import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { VERSION } from '~/tokens/version.token';

@Injectable({
    providedIn: 'root'
})
export class TranslateHttpService implements TranslateLoader {

    private prefix = '/assets/i18n/';
    private suffix = '.json';

    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(VERSION) private version: string,
    ) { }

    getTranslation(lang: string): Observable<any> {
        return this.http
            .get<any>(`${this.prefix}${lang}${this.suffix}?v=${this.version}`);
    }
}
