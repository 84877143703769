<section id="pageTitle"></section>
<!--content-->
<section id="content" class="ourTeamContent" [class.russian]="prefix === '/ru' || prefix === '/ua'" content="Renet Consulting multicultural web development and business team">
    <article>
        <div class="container">
            <h2 class="text-center">{{'OURTEAM_SUBTITLE' | translate}}</h2>
            <br />
            <br />
            <div class="clearfix"></div>
            <div class="hr-line-thin"></div>
            <div class="clearfix"></div>
            <div class="row d-flex align-items-stretch bd-highlight">
              <!-- item -->
              <div class="col-lg-6 bd-highlight pull-left right-border our-team">
                <img src="/assets/images/Alexsander-Alstotsky-img.jpg?v={{version}}"
                     width="120"
                     height="120"
                     alt="{{'OURTEAM_IMG_ALEXANDER_ALTOTSKY_ALT' | translate}}"
                     title="{{'OURTEAM_IMG_ALEXANDER_ALTOTSKY_TITLE' | translate}}"
                     class="wow bounceIn"
                     data-wow-delay=".1s" />
                <h3>{{'OURTEAM_IMG_ALEXANDER_ALTOTSKY' | translate}}</h3>
                <h4>{{'OURTEAM_IMG_ALEXANDER_ALTOTSKY_POSITION' | translate}}</h4>
                <p>{{'OURTEAM_IMG_ALEXANDER_ALTOTSKY_P' | translate}}</p>
                <p>{{'OURTEAM_IMG_ALEXANDER_ALTOTSKY_P_2' | translate}}</p>
              </div>
              <!-- item -->
              <div class="col-lg-6 bd-highlight pull-left our-team">
                <img src="/assets/images/Andrey-Medvedev-img.jpg?v={{version}}"
                     width="120"
                     height="120"
                     alt="{{'OURTEAM_IMG_ANDREY_MEDVEDEV_ALT' | translate}}"
                     title="{{'OURTEAM_IMG_ANDREY_MEDVEDEV_TITLE' | translate}}"
                     class="wow bounceIn"
                     data-wow-delay=".2s" />
                <h3>{{'OURTEAM_IMG_ANDREY_MEDVEDEV' | translate}}</h3>
                <h4>{{'OURTEAM_IMG_ANDREY_MEDVEDEV_POSITION' | translate}}</h4>
                <p>{{'OURTEAM_IMG_ANDREY_MEDVEDEV_P' | translate}}</p>
                <p>{{'OURTEAM_IMG_ANDREY_MEDVEDEV_P_2' | translate}}</p>
              </div>
              <!-- item -->
              <div class="col-lg-6 bd-highlight pull-left right-border our-team">
                <img src="/assets/images/Arthur-Zhadan-img.jpg?v={{version}}"
                     width="120"
                     height="120"
                     alt="{{'OURTEAM_IMG_ARTHUR_ZHADAN_ALT' | translate}}"
                     title="{{'OURTEAM_IMG_ARTHUR_ZHADAN_TITLE' | translate}}"
                     class="wow bounceIn"
                     data-wow-delay=".3s" />
                <h3>{{'OURTEAM_IMG_ARTHUR_ZHADAN' | translate}}</h3>
                <h4>{{'OURTEAM_IMG_ARTHUR_ZHADAN_POSITION' | translate}}</h4>
                <p>{{'OURTEAM_IMG_ARTHUR_ZHADAN_P' | translate}}</p>
                <p>{{'OURTEAM_IMG_ARTHUR_ZHADAN_P_2' | translate}}</p>
                <p>{{'OURTEAM_IMG_ARTHUR_ZHADAN_P_3' | translate}}</p>
              </div>
              <!-- item -->
              <div class="col-lg-6 bd-highlight pull-left our-team">
                <img src="/assets/images/Viacheslav-Stohul-img.jpg?v={{version}}"
                     width="120"
                     height="120"
                     alt="{{'OURTEAM_IMG_VIACHESLAV_STOHUL_ALT' | translate}}"
                     title="{{'OURTEAM_IMG_VIACHESLAV_STOHUL_TITLE' | translate}}"
                     class="wow bounceIn"
                     data-wow-delay=".4s" />
                <h3>{{'OURTEAM_IMG_VIACHESLAV_STOHUL' | translate}}</h3>
                <h4>{{'OURTEAM_IMG_VIACHESLAV_STOHUL_POSITION' | translate}}</h4>
                <p>{{'OURTEAM_IMG_VIACHESLAV_STOHUL_P' | translate}}</p>
                <p>{{'OURTEAM_IMG_VIACHESLAV_STOHUL_P_2' | translate}}</p>
                <p>{{'OURTEAM_IMG_VIACHESLAV_STOHUL_P_3' | translate}}</p>
              </div>
              <!-- item -->
              <div class="col-lg-6 bd-highlight pull-left right-border our-team">
                <img src="/assets/images/Daryna-Froiuk-img.jpg?v={{version}}"
                     width="120"
                     height="120"
                     alt="{{'OURTEAM_IMG_DARYNA_FROIUK_ALT' | translate}}"
                     title="{{'OURTEAM_IMG_DARYNA_FROIUK_TITLE' | translate}}"
                     class="wow bounceIn"
                     data-wow-delay=".5s" />
                <h3>{{'OURTEAM_IMG_DARYNA_FROIUK' | translate}}</h3>
                <h4>{{'OURTEAM_IMG_DARYNA_FROIUK_POSITION' | translate}}</h4>
                <p>{{'OURTEAM_IMG_DARYNA_FROIUK_P' | translate}}</p>
                <p>{{'OURTEAM_IMG_DARYNA_FROIUK_P_2' | translate}}</p>
              </div>
              <!-- item -->
              <div class="col-lg-6 bd-highlight pull-left our-team">
                <img src="/assets/images/Illia-Antonov.jpg?v={{version}}"
                     width="120"
                     height="120"
                     alt="{{'OURTEAM_IMG_ILLIA_ANTONOV_ALT' | translate}}"
                     title="{{'OURTEAM_IMG_ILLIA_ANTONOV_TITLE' | translate}}"
                     class="wow bounceIn"
                     data-wow-delay=".6s" />
                <h3>{{'OURTEAM_IMG_ILLIA_ANTONOV' | translate}}</h3>
                <h4>{{'OURTEAM_IMG_ILLIA_ANTONOV_POSITION' | translate}}</h4>
                <p>{{'OURTEAM_IMG_ILLIA_ANTONOV_P' | translate}}</p>
                <p>{{'OURTEAM_IMG_ILLIA_ANTONOV_P_2' | translate}}</p>
              </div>
  <!-- item -->
              <div class="col-lg-6 bd-highlight pull-left right-border our-team">
                <img src="/assets/images/Vladislav-Astakhov-img.jpg?v={{version}}"
                     width="120"
                     height="120"
                     alt="{{'OURTEAM_IMG_VLADISLAV_ASTAKHOV_ALT' | translate}}"
                     title="{{'OURTEAM_IMG_VLADISLAV_ASTAKHOV_TITLE' | translate}}"
                     class="wow bounceIn"
                     data-wow-delay=".7s" />
                <h3>{{'OURTEAM_IMG_VLADISLAV_ASTAKHOV' | translate}}</h3>
                <h4>{{'OURTEAM_IMG_VLADISLAV_ASTAKHOV_POSITION' | translate}}</h4>
                <p>{{'OURTEAM_IMG_VLADISLAV_ASTAKHOV_P' | translate}}</p>
                <p>{{'OURTEAM_IMG_VLADISLAV_ASTAKHOV_P_2' | translate}}</p>
              </div>
              <!-- item -->
              <div class="col-lg-6 bd-highlight pull-left our-team">
                <img src="/assets/images/Elena-Doroshenko-img.jpg?v={{version}}"
                     width="120"
                     height="120"
                     alt="{{'OURTEAM_IMG_ELENA_DOROSHENKO_ALT' | translate}}"
                     title="{{'OURTEAM_IMG_ELENA_DOROSHENKO_TITLE' | translate}}"
                     class="wow bounceIn"
                     data-wow-delay=".8s" />
                <h3>{{'OURTEAM_IMG_ELENA_DOROSHENKO' | translate}}</h3>
                <h4>{{'OURTEAM_IMG_ELENA_DOROSHENKO_POSITION' | translate}}</h4>
                <p>{{'OURTEAM_IMG_ELENA_DOROSHENKO_P' | translate}}</p>
                <p>{{'OURTEAM_IMG_ELENA_DOROSHENKO_P_2' | translate}}</p>
                <p>{{'OURTEAM_IMG_ELENA_DOROSHENKO_P_3' | translate}}</p>
                <p class="text-center mb-5">
                  <button type="button"
                          class="btn btn-outline-primary btn-lg wow pulse animated"
                          data-wow-delay="300ms"
                          data-wow-iteration="infinite"
                          data-wow-duration="2s"
                          [routerLink]="[prefix, 'helen-doroshenko']">
                    {{'SEE_WORKS' | translate}}
                  </button>
                </p>
              </div>
              <!-- item -->
              <div class="col-lg-6 bd-highlight pull-left right-border our-team">
                <img src="/assets/images/no-photo-img.png?v={{version}}"
                     width="120"
                     height="120"
                     alt="{{'OURTEAM_IMG_JAMIE_SAMOILOFF_ALT' | translate}}"
                     title="{{'OURTEAM_IMG_JAMIE_SAMOILOFF_TITLE' | translate}}"
                     class="wow bounceIn"
                     data-wow-delay=".9s" />
                <h3>{{'OURTEAM_IMG_JAMIE_SAMOILOFF' | translate}}</h3>
                <h4>{{'OURTEAM_IMG_JAMIE_SAMOILOFF_POSITION' | translate}}</h4>
                <p>{{'OURTEAM_IMG_JAMIE_SAMOILOFF_P' | translate}}</p>
                <p>{{'OURTEAM_IMG_JAMIE_SAMOILOFF_P_2' | translate}}</p>
              </div>
              <!-- item -->
              <div class="col-lg-6 bd-highlight pull-left our-team">
                <img src="/assets/images/Arie-Baratt-img.jpg?v={{version}}"
                     width="120"
                     height="120"
                     alt="{{'OURTEAM_IMG_ARIE_BARATT_ALT' | translate}}"
                     title="{{'OURTEAM_IMG_ARIE_BARATT_TITLE' | translate}}"
                     class="wow bounceIn"
                     data-wow-delay="1s" />
                <h3>{{'OURTEAM_IMG_ARIE_BARATT' | translate}}</h3>
                <h4>{{'OURTEAM_IMG_ARIE_BARATT_POSITION' | translate}}</h4>
                <p>{{'OURTEAM_IMG_ARIE_BARATT_P' | translate}}</p>
                <p>{{'OURTEAM_IMG_ARIE_BARATT_P_2' | translate}}</p>
                <p>{{'OURTEAM_IMG_ARIE_BARATT_P_3' | translate}}</p>
              </div>

              <!--<div class="col-lg-6 bd-highlight pull-left our-team"></div>-->
              <div class="clearfix"></div>
            </div>
            <br />
            <br />
            <div class="text-center">
                <div class="wow zoomIn" data-wow-duration="1s" data-wow-offset="100">
                    <div class="wow tada" data-wow-delay="1s" data-wow-duration="1s">
                        <div class="wow rubberBand" data-wow-delay="2000ms" data-wow-duration="1s">
                            <button type="button"
                                    class="btn btn-primary btn-lg wow pulse animated"
                                    data-wow-delay="300ms"
                                    data-wow-iteration="infinite"
                                    data-wow-duration="2s"
                                    [routerLink]="[prefix, 'contact-us']">
                                {{'CONTACT_BUTTON' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </article>
</section>
<!--END coontent-->
