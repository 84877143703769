import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { TranslateBase } from '~/bases/translate.base';
import { VERSION } from '~/tokens/version.token';

@Component({
    selector: 'iamsapien',
    templateUrl: './iamsapien.component.html',
    styleUrls: ['./iamsapien.component.scss']
})
export class IamsapienComponent extends TranslateBase implements OnInit, OnDestroy {

    constructor(
        injector: Injector,
        @Inject(VERSION) public version: string,
    ) {
        super(injector);
    }
}
