<section id="pageTitle" class="safariHeader" [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h1>{{'CLOUDCOMPUTING_TITLE' | translate}}</h1>
                <div class="wow zoomIn" data-wow-duration="1s" data-wow-offset="100">
                    <div class="wow tada" data-wow-delay="1s" data-wow-duration="1s">
                        <div class="wow rubberBand" data-wow-delay="2000ms" data-wow-duration="1s">
                            <button type="button"
                                    class="btn btn-primary btn-lg wow pulse animated"
                                    data-wow-delay="300ms"
                                    data-wow-iteration="infinite"
                                    data-wow-duration="2s"
                                    [routerLink]="[prefix, 'contact-us']">
                                {{'CONTACT_BUTTON' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
</section>

<!--content-->
<section id="content" content="We have experience with cloud computing such as Microsoft Azure and Amazon Web Services" [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <article>
        <div class="container">
            <div class="row block-box">
                <div class="col-lg-12">
                    <h2 class="text-center">{{'CLOUDCOMPUTING_CAN_ASSIST_YOU_H2' | translate}}</h2>
                    <br />
                    <h3 class="black-text text-center">{{'CLOUDCOMPUTING_CAN_ASSIST_YOU_H3' | translate}}</h3>
                    <br />
                    <br />
                </div>
                <div class="col-md-6 pull-left">
                    <ul>
                        <li [innerHTML]="'CLOUDCOMPUTING_LIST_1' | translate"></li>
                        <li [innerHTML]="'CLOUDCOMPUTING_LIST_2' | translate"></li>
                        <li [innerHTML]="'CLOUDCOMPUTING_LIST_3' | translate"></li>
                        <li [innerHTML]="'CLOUDCOMPUTING_LIST_4' | translate"></li>
                    </ul>
                </div>
                <div class="col-md-6 pull-left">
                    <ul>
                        <li [innerHTML]="'CLOUDCOMPUTING_LIST_5' | translate"></li>
                        <li [innerHTML]="'CLOUDCOMPUTING_LIST_6' | translate"></li>
                        <li [innerHTML]="'CLOUDCOMPUTING_LIST_7' | translate"></li>
                        <li [innerHTML]="'CLOUDCOMPUTING_LIST_8' | translate"></li>
                    </ul>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="bottom-banner text-center">
            <div class="container">
                <h5>{{'CLOUDCOMPUTING_CAN_ASSIST_YOU_H5' | translate}}</h5>
            </div>
        </div>
    </article>
</section>
<!--END coontent-->
