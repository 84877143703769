import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { TranslateBase } from '~/bases/translate.base';
import { VERSION } from '~/tokens/version.token';

@Component({
    selector: 'pulse-electronics',
    templateUrl: './pulse.electronics.component.html',
    styleUrls: ['./pulse.electronics.component.scss']
})
export class PulseElectronicsComponent extends TranslateBase implements OnInit, OnDestroy {

    constructor(
        injector: Injector,
        @Inject(VERSION) public version: string,
    ) {
        super(injector);
    }
}
