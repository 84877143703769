/* tslint:disable:max-line-length */
import { Route } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { CareersComponent } from './components/careers/careers.component';
import { CloudComputingComponent } from './components/cloud.computing/cloud.computing.component';
import { CustomSoftwareComponent } from './components/custom.software/custom.software.component';
import { HistoryComponent } from './components/history/history.component';
import { HomeComponent } from './components/home/home.component';
import { IamsapienComponent } from './components/iamsapien/iamsapien.component';
import { ITConsultingComponent } from './components/it.consulting/it.consulting.component';
import { NotFoundComponent } from './components/not.found/not.found.component';
import { OurTeamComponent } from './components/our.team/our.team.component';
import { PrinciplesComponent } from './components/principles/principles.component';
import { CFIComponent } from './components/profiles/alex.sirant/portfolio/cfi/cfi.component';
import { AEMComponent } from './components/profiles/helen.doroshenko/portfolio/aem/aem.component';
import { EcologyComponent } from './components/profiles/helen.doroshenko/portfolio/ecology/ecology.component';
import { OneginBluesComponent } from './components/profiles/helen.doroshenko/portfolio/onegin.blues/onegin.blues.component';
import { PromiseProjectComponent } from './components/profiles/helen.doroshenko/portfolio/promise/promise.component';
import { PulseElectronicsComponent } from './components/profiles/helen.doroshenko/portfolio/pulse.electronics/pulse.electronics.component';
import { YZPNComponent } from './components/profiles/helen.doroshenko/portfolio/yzpn/yzpn.component';
import { HelenDoroshenkoProfileComponent } from './components/profiles/helen.doroshenko/profile/helen.doroshenko.component';
import { PromiseComponent } from './components/promise/promise.component';
import { RockAwayBeachResortComponent } from './components/rockawaybeachresort/rockawaybeachresort.component';
import { TechnologiesComponent } from './components/technologies/technologies.component';
import { WebApplicationsComponent } from './components/web.applications/web.applications.component';

export const EN_ROUTES: Route[] = [
    {
        path: 'home', component: HomeComponent,
        data: {
            title: 'Software Development Company - Renet Consulting',
            meta: [
                { name: 'description', content: 'Renet Consulting is a custom software development company with over 20 years experience. Providing design, web, mobile and cloud development, custom software and custom IT solutions. Based in Portland, OR' },
                { name: 'keywords', content: 'Portland Renet Consulting, custom software development, website development, Portland, web development, database development, web application development, mobile application development, online development, web design, usability engineering, Angular, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, web application development, mobile web app, website to app ' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/en/home' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Renet Consulting, a custom software company' },
                { property: 'og:description', content: 'Providing enterprise IT consulting services nationally, including web design, web and mobile applications, web based applications, project management, custom software, and custom IT solutions. Based in Portland, OR.' },
                { property: 'og:image', content: '/images/bg-header.jpg' }
            ]
        }
    },
    {
        path: 'about', component: AboutComponent,
        data: {
            title: 'About Us',
            meta: [
                { name: 'description', content: 'Renet Consulting has been working with clients for over 20 years, building custom web applications, working on web programming, developing web based applications nationally and locally, in Portland, OR.' },
                { name: 'keywords', content: 'Portland Renet Consulting, custom software development, website development, Portland, web development, database development, web application development, mobile application development, online development, web design, usability engineering, Angular, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, web application development, mobile web app, website to app ' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/en/about' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'About Us' },
                { property: 'og:description', content: 'Renet Consulting has been working with clients for over 20 years, building custom web applications, working on web programming, developing web based applications nationally and locally, in Portland, OR.' },
                { property: 'og:image', content: '/images/we-build-custom-software.png' }
            ]
        }
    },
    {
        path: 'history', component: HistoryComponent,
        data: {
            title: 'History of Renet Consulting',
            meta: [
                { name: 'description', content: 'Renet Consulting has provided QUALITY service and FAST web application development, custom software and web design for over 20 years nationally and locally in Portland, OR.' },
                { name: 'keywords', content: 'Portland Renet Consulting, custom software development, website development, Portland, web development, database development, web application development, mobile application development, online development, web design, usability engineering, Angular, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, web application development, mobile web app, website to app ' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/en/history' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'History of Renet Consulting' },
                { property: 'og:description', content: 'Renet Consulting has provided QUALITY service and FAST web application development, custom software and web design for over 20 years nationally and locally in Portland, OR.' },
                { property: 'og:image', content: '/images/bg-header.jpg' }
            ]
        }
    },
    {
        path: 'contact-us', loadChildren: () => import('./components/contact/contact.module').then(m => m.ContactModule),
        data: {
            title: 'Contact Renet Consulting',
            meta: [
                { name: 'description', content: 'Renet Consulting is proficient in web design, web application development, mobile web app, web programming and custom software nationally and locally, in Portland, OR.' },
                { name: 'keywords', content: 'Portland Renet Consulting, custom software development, website development, Portland, web development, database development, web application development, mobile application development, online development, web design, usability engineering, Angular, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, web application development, mobile web app, website to app.' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/en/contact-us' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Contact Renet Consulting' },
                { property: 'og:description', content: 'Renet Consulting is proficient in web design, web application development, mobile web app, web programming and custom software nationally and locally, in Portland, OR.' },
                { property: 'og:image', content: '/images/bg-contact-us.jpg' }
            ]
        }
    },
    {
        path: 'principles', component: PrinciplesComponent,
        data: {
            title: 'Renet Consulting core principles',
            meta: [
                { name: 'description', content: 'Renet Consulting has several core principles when it comes to developing custom software' },
                { name: 'keywords', content: 'Portland Renet Consulting, custom software development, website development, Portland, web development, database development, web application development, mobile application development, online development, web design, usability engineering, Angular, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, web application development, mobile web app, website to app ' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/en/principles' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Renet Consulting core principles' },
                { property: 'og:description', content: 'Renet Consulting has several core principles when it comes to developing custom software' },
                { property: 'og:image', content: '/images/individuals-over-tools.png' }
            ]
        }
    },
    {
        path: 'web-applications', component: WebApplicationsComponent,
        data: {
            title: 'Custom Web applications',
            meta: [
                { name: 'description', content: 'Renet Consulting is proficient in web application developmenr, web design, mobile web app, web programming and custom software' },
                { name: 'keywords', content: 'Portland Renet Consulting, custom software development, website development, Portland, web development, database development, web application development, mobile application development, online development, web design, usability engineering, Angular, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, web application development, mobile web app, website to app ' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/en/web-applications' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Custom Web applications' },
                { property: 'og:description', content: 'Renet Consulting is proficient in web application developmenr, web design, mobile web app, web programming and custom software' },
                { property: 'og:image', content: '/images/web-application-img.png' }
            ]
        }
    },
    {
        path: 'it-consulting', component: ITConsultingComponent,
        data: {
            title: 'Custom IT services',
            meta: [
                { name: 'description', content: 'Besides custom software development, Renet Consulting also offers IT services including remote management' },
                { name: 'keywords', content: 'Portland Renet Consulting, custom software development, website development, Portland, web development, database development, web application development, mobile application development, online development, web design, usability engineering, Angular, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, web application development, mobile web app, website to app ' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/en/it-consulting' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Custom IT services' },
                { property: 'og:description', content: 'Besides custom software development, Renet Consulting also offers IT services including remote management' },
                { property: 'og:image', content: '/images/server-management.png' }
            ]
        }
    },
    {
        path: 'custom-software', component: CustomSoftwareComponent,
        data: {
            title: 'Custom Software Development',
            meta: [
                { name: 'description', content: 'Renet Consulting offers custom software development, web application design, web programming, web application development' },
                { name: 'keywords', content: 'Portland Renet Consulting, custom software development, website development, Portland, web development, database development, web application development, mobile application development, online development, web design, usability engineering, Angular, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, web application development, mobile web app, website to app ' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/en/custom-software' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Custom Software Development' },
                { property: 'og:description', content: 'Renet Consulting offers custom software development, web application design, web programming, web application development' },
                { property: 'og:image', content: '/images/you-need-custom-software.png' }
            ]
        }
    },
    {
        path: 'our-team', component: OurTeamComponent,
        data: {
            title: 'Renet Consulting - Our Team',
            meta: [
                { name: 'description', content: 'Renet Consulting Team of skilled web developers, web application designers, custom software developers' },
                { name: 'keywords', content: 'Portland Renet Consulting, custom software development, website development, Portland, web development, database development, web application development, mobile application development, online development, web design, usability engineering, Angular, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, web application development, mobile web app, website to app ' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/en/our-team' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Renet Consulting - Our Team' },
                { property: 'og:description', content: 'Renet Consulting Team of skilled web developers, web application designers, custom software developers' },
                { property: 'og:image', content: '/images/bg-header.jpg' }
            ]
        }
    },
    {
        path: 'cloud-computing', component: CloudComputingComponent,
        data: {
            title: 'Cloud Computing Services',
            meta: [
                { name: 'description', content: 'Besides custom software development, Renet Consulting also offers Cloud Computing services on Microsoft Azure or Amazon Web Services' },
                { name: 'keywords', content: 'Portland Renet Consulting, custom software development, website development, Portland, web development, database development, web application development, mobile application development, online development, web design, usability engineering, Angular, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, web application development, mobile web app, website to app ' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/en/cloud-computing' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Cloud Computing Services' },
                { property: 'og:description', content: 'Besides custom software development, Renet Consulting also offers Cloud Computing services on Microsoft Azure or Amazon Web Services' },
                { property: 'og:image', content: '/images/bg-header.jpg' }
            ]
        }
    },
    {
        path: 'technologies', component: TechnologiesComponent,
        data: {
            title: 'Web Development Technologies we are working with',
            meta: [
                { name: 'description', content: 'Custom web development, web app mobile, web design technologies such as Angular, Javascript, Typescript, WordPress, .NET Core, jQuery, SQL, Renet Consulting' },
                { name: 'keywords', content: 'Portland Renet Consulting, custom software development, website development, Portland, web development, database development, web application development, mobile application development, online development, web design, usability engineering, Angular, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, web application development, mobile web app, website to app ' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/en/technologies' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Cloud Computing Services' },
                { property: 'og:description', content: 'Besides custom software development, Renet Consulting also offers Cloud Computing services on Microsoft Azure or Amazon Web Services' },
                { property: 'og:image', content: '/images/technologies-logo/angular-js-logo.png' }
            ]
        }
    },
    {
        path: 'careers', component: CareersComponent,
        data: {
            title: 'Web Development Careers at Renet Consulting',
            meta: [
                { name: 'description', content: 'Web Developers Careers at Renet Consulting nationally or locally in Portland, OR' },
                { name: 'keywords', content: 'Portland Renet Consulting, custom software development, website development, Portland, web development, database development, web application development, mobile application development, online development, web design, usability engineering, Angular, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, web application development, mobile web app, website to app ' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/en/careers' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Web Development Careers at Renet Consulting' },
                { property: 'og:description', content: 'Web Developers Careers at Renet Consulting nationally or locally in Portland, OR' },
                { property: 'og:image', content: '/images/join-our-web-development-team.png' }
            ]
        }
    },
    {
        path: 'not-found', component: NotFoundComponent,
        data: {
            title: 'Not Found Page at Renet Consulting',
            meta: [
                { name: 'description', content: 'Not Found Page at Renet Consulting' },
                { name: 'keywords', content: 'Portland Renet Consulting, custom software development, website development, Portland, web development, database development, web application development, mobile application development, online development, web design, usability engineering, Angular, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, web application development, mobile web app, website to app ' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/en/not-found' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Not Found Page at Renet Consulting' },
                { property: 'og:description', content: 'Not Found Page at Renet Consulting' },
                { property: 'og:image', content: '/images/404-page-not-found.png' }
            ]
        }
    },
    {
        path: 'request-a-quote', loadChildren: () => import('./components/request-quote/request-quote.module').then(m => m.RequestQuoteModule),
        data: {
            title: 'Request a Quote Page at Renet Consulting',
            meta: [
                { name: 'description', content: 'Request a Quote. We can design, develop and build a website using WordPress or Angular/.NET core. We can transfer your website to mobile.' },
                { name: 'keywords', content: 'Portland Renet Consulting, custom software development, website development, Portland, web development, database development, web application development, mobile application development, online development, web design, usability engineering, Angular, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, web application development, mobile web app, website to app ' },
                { property: 'og:site_name', content: 'Renet Consulting' },
                { property: 'og:url', content: '//renetusa.com/en/request-a-quote' },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: 'Request a Quote' },
                { property: 'og:description', content: 'Request a Quote. We can design, develop and build a website using WordPress or Angular/.NET core. We can transfer your website to mobile.' },
                { property: 'og:image', content: '/images/request-a-quote-img.jpg' }
            ]
        }
    },
    {
        path: 'helen-doroshenko',
        children: [
            { path: '', redirectTo: 'profile', pathMatch: 'full' },

            { path: 'profile', component: HelenDoroshenkoProfileComponent, data: { title: 'Helen Doroshenko profile' }, },
            { path: 'promise-project', component: PromiseProjectComponent, data: { title: 'ProMISe, a custom web based application example.' } },

            {
                path: 'prev-projects',
                children: [
                    { path: '', redirectTo: 'onegin-blues', pathMatch: 'full' },

                    { path: 'onegin-blues', component: OneginBluesComponent, data: { title: 'Onegin-blues' } },
                    { path: 'ad-empire-media', component: AEMComponent, data: { title: 'Ad Empire Media' } },
                    { path: 'ecology', component: EcologyComponent, data: { title: 'Ecology' } },

                    { path: '**', redirectTo: 'onegin-blues' }
                ]
            },

            { path: '**', redirectTo: 'profile' }
        ]
    },
    {
        path: 'projects',
        children: [
            { path: '', redirectTo: 'carroll-food-intolerance-app', pathMatch: 'full' },

            { path: 'carroll-food-intolerance-app', component: CFIComponent, data: { title: 'Carroll Food Intolerance App', } },
            {
                path: 'iamsapien', component: IamsapienComponent,
                data: {
                    title: 'iAmSapinen, a custom web app example developed by Renet Consulting',
                    meta: [
                        { name: 'description', content: 'Renet Consulting"\s example of web application design and web aplication development, custom web programming' },
                        { name: 'keywords', content: 'Portland Renet Consulting, custom software development, website development, Portland, web development, database development, web application development, mobile application development, online development, web design, usability engineering, Angular, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, web application development, mobile web app, website to app ' },
                        { property: 'og:site_name', content: 'Renet Consulting' },
                        { property: 'og:url', content: '//renetusa.com/en/iamsapien' },
                        { property: 'og:type', content: 'article' },
                        { property: 'og:title', content: 'iAmSapinen, a custom web app example developed by Renet Consulting' },
                        { property: 'og:description', content: 'Renet Consulting"\s example of web application design and web aplication development, custom web programming' },
                        { property: 'og:image', content: '/images/bg-iamsapien.jpg' }
                    ]
                }
            },
            {
                path: 'rockawaybeachresort', component: RockAwayBeachResortComponent,
                data: {
                    title: 'Rockaway Beach Resort, one of Renet Consulting clients',
                    meta: [
                        { name: 'description', content: 'Renet Consulting web based application, web design and web aplication development, custom web programming' },
                        { name: 'keywords', content: 'Portland Renet Consulting, custom software development, website development, Portland, web development, database development, web application development, mobile application development, online development, web design, usability engineering, Angular, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, web application development, mobile web app, website to app ' },
                        { property: 'og:site_name', content: 'Renet Consulting' },
                        { property: 'og:url', content: '//renetusa.com/en/rockawaybeachresort' },
                        { property: 'og:type', content: 'article' },
                        { property: 'og:title', content: 'Rockaway Beach Resort, one of Renet Consulting clients' },
                        { property: 'og:description', content: 'Renet Consulting web based application, web design and web aplication development, custom web programming' },
                        { property: 'og:image', content: '/images/bg-rockawaybeachresort.jpg' }
                    ]
                }
            },
            {
                path: 'promise', component: PromiseComponent,
                data: {
                    title: 'ProMISe, a custom web based application example developed by Renet Consulting',
                    meta: [
                        { name: 'description', content: 'Renet Consulting custom web development project ProMISe' },
                        { name: 'keywords', content: 'Portland Renet Consulting, custom software development, website development, Portland, web development, database development, web application development, mobile application development, online development, web design, usability engineering, Angular, AngularJS, Dot Net Core, .NET Core, Entity Framework, JavaScript, Wordpress, web application development, mobile web app, website to app ' },
                        { property: 'og:site_name', content: 'Renet Consulting' },
                        { property: 'og:url', content: '//renetusa.com/en/promise' },
                        { property: 'og:type', content: 'article' },
                        { property: 'og:title', content: 'ProMISe, a custom web based application example developed by Renet Consulting' },
                        { property: 'og:description', content: 'Renet Consulting custom web development project ProMISe' },
                        { property: 'og:image', content: '/images/bg-promise.jpg' }
                    ]
                }
            },
            { path: 'yzpn', component: YZPNComponent, data: { title: 'YZPN' } },
            { path: 'pulse-electronics', component: PulseElectronicsComponent, data: { title: 'Pulse Electronics' } },

            { path: '**', redirectTo: 'carroll-food-intolerance-app' }
        ]
    }
];
