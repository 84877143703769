<section id="pageTitle" class="safariHeader" [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h1>{{'PRINCIPLES_TITLE' | translate}}</h1>
                <div class="wow zoomIn" data-wow-duration="1s" data-wow-offset="100">
                    <div class="wow tada" data-wow-delay="1s" data-wow-duration="1s">
                        <div class="wow rubberBand" data-wow-delay="2000ms" data-wow-duration="1s">
                            <button type="button"
                                    class="btn btn-primary btn-lg wow pulse animated"
                                    data-wow-delay="300ms"
                                    data-wow-iteration="infinite"
                                    data-wow-duration="2s"
                                    [routerLink]="[prefix, 'contact-us']">
                                {{'CONTACT_BUTTON' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
</section>

<!--content-->
<section id="content" class="principlesContent" [class.russian]="prefix === '/ru' || prefix === '/ua'" content="Renet Consulting core principles of web development business">
    <article>
        <div class="container">
            <h2 class="text-center" [innerHTML]="'PRINCIPLES_SUBTITLE' | translate"></h2>
            <br />
            <div class="row block-box">
                <div class="col-lg-3 col-md-6 text-center pull-left">
                    <img src="/assets/images/individuals-over-tools.png?v={{version}}"
                         alt="{{'PRINCIPLES_IMG_INDIVIDUALS_OVER_TOOLS_ALT' | translate}}"
                         title="{{'PRINCIPLES_IMG_INDIVIDUALS_OVER_TOOLS_TITLE' | translate}}"
                         class="wow bounceIn"
                         data-wow-delay=".2s" />
                    <h3>{{'PRINCIPLES_IMG_INDIVIDUALS_OVER_TOOLS' | translate}}</h3>
                </div>

                <div class="col-lg-3 col-md-6 text-center pull-left">
                    <img src="/assets/images/working-software-over-documentation.png?v={{version}}"
                         alt="{{'PRINCIPLES_IMG_WORKING_SOFTWARE_OVER_DOCUMENTATION_ALT' | translate}}"
                         title="{{'PRINCIPLES_IMG_WORKING_SOFTWARE_OVER_DOCUMENTATION_TITLE' | translate}}"
                         class="wow bounceIn" data-wow-delay=".3s" />
                    <h3>{{'PRINCIPLES_IMG_WORKING_SOFTWARE_OVER_DOCUMENTATION' | translate}}</h3>
                </div>

                <div class="col-lg-3 col-md-6 text-center pull-left">
                    <img src="/assets/images/customer-collaboration-over-contract-negotiation.png?v={{version}}"
                         alt="{{'PRINCIPLES_IMG_CUSTOMER_COLLABORATION_OVER_CONTRACT_NEGOTIATION_ALT' | translate}}"
                         title="{{'PRINCIPLES_IMG_CUSTOMER_COLLABORATION_OVER_CONTRACT_NEGOTIATION_TITLE' | translate}}"
                         class="wow bounceIn"
                         data-wow-delay=".4s" />
                    <h3>{{'PRINCIPLES_IMG_CUSTOMER_COLLABORATION_OVER_CONTRACT_NEGOTIATION' | translate}}</h3>
                </div>

                <div class="col-lg-3 col-md-6 text-center pull-left">
                    <img src="/assets/images/flexibility-with-changes.png?v={{version}}"
                         alt="{{'PRINCIPLES_IMG_FLEXIBILITY_WITH_CHANGES_OVER_FOLLOWING_A_PLAN_ALT' | translate}}"
                         title="{{'PRINCIPLES_IMG_FLEXIBILITY_WITH_CHANGES_OVER_FOLLOWING_A_PLAN_TITLE' | translate}}"
                         class="wow bounceIn"
                         data-wow-delay=".5s" />
                    <h3>{{'PRINCIPLES_IMG_FLEXIBILITY_WITH_CHANGES_OVER_FOLLOWING_A_PLAN' | translate}}</h3>
                </div>
            </div>
        </div>
    </article>
    <div class="clearfix"></div>
    <div class="hr-line"></div>
    <div class="clearfix"></div>
    <article>
        <div class="container">
            <div class="row block-box">
                <div class="col-lg-12">
                    <h2 class="black-text text-center">{{'PRINCIPLES_WE_VALUE_THE_ITEMS_ON_THE_LEFT_MORE_H2' | translate}}</h2>
                    <br />
                    <h3 class="black-text text-center">{{'PRINCIPLES_BASED_ON_THE_ABOVE_VALUES_WE_FOLLOW_THESE_PRINCIPLES_H3' | translate}}</h3>
                    <br />
                    <br />
                    <div class="row block-box">
                        <div class="col-lg-3 col-md-6 pull-left">
                            <ul>
                                <li>{{'PRINCIPLES_OUR_HIGHEST_PRIORITY_IS_LIST' | translate}}</li>
                                <li>{{'PRINCIPLES_WELCOME_CHANGING_REQUIREMENTS_LIST' | translate}}</li>
                                <li>{{'PRINCIPLES_DELIVER_WORKING_SOFTWARE_FREQUENTLY_LIST' | translate}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-3 col-md-6 pull-left">
                            <ul>
                                <li>{{'PRINCIPLES_BUSINESS_PEOPLE_AND_DEVELOPERS_MUST_LIST' | translate}}</li>
                                <li>{{'PRINCIPLES_BUILD_PROJECTS_AROUND_MOTIVATED_INDIVIDUALS_LIST' | translate}}</li>
                                <li>{{'PRINCIPLES_THE_MOST_EFFICIENT_AND_EFFECTIVE_METHOD_LIST' | translate}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-3 col-md-6 pull-left">
                            <ul>
                                <li>{{'PRINCIPLES_WORKING_SOFTWARE_IS_LIST' | translate}}</li>
                                <li>{{'PRINCIPLES_AGILE_PROCESSES_PROMOTE_SUSTAINABLE_DEVELOPMENT_LIST' | translate}}</li>
                                <li>{{'PRINCIPLES_CONTINUOUS_ATTENTION_LIST' | translate}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-3 col-md-6 pull-left">
                            <ul>
                                <li>{{'PRINCIPLES_SIMPLICITY_THE_ART_OF_MAXIMIZING_LIST' | translate}}</li>
                                <li>{{'PRINCIPLES_THE_BEST_ARCHITECTURES_LIST' | translate}}</li>
                                <li>{{'PRINCIPLES_AT_REGULAR_INTERVALS_LIST' | translate}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </article>
    <div class="clearfix"></div>
    <div class="bottom-banner text-center">
        <div class="container">
            <h5>{{'PRINCIPLES_WE_COMBINE_CUTTING_EDGE_TECHNOLOGY_H5' | translate}}</h5>
        </div>
    </div>
</section>
<!--END coontent-->
