import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[preventDefault]'
})
export class PreventDefaultDirective {

    constructor() { }

    @HostListener('click', ['$event']) preventDefault = (e): void => e.preventDefault();
}
