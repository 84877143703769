<div class="clearfix"></div>
<footer [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 pull-left">
                <ul class="list-inline text-left">
                    <li class="nav-item dropdown" ngbDropdown>
                        <a preventDefault class="nav-link dropdown-toggle" id="fc1" ngbDropdownToggle>{{'COMPANY_LINK' | translate}}</a>
                        <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="fc1">
                            <a class="dropdown-item" [routerLink]="[prefix, 'about']">{{'COMPANY_ABOUT_LINK' | translate}}</a>
                            <a class="dropdown-item" [routerLink]="[prefix, 'history']">{{'COMPANY_HISTORY_LINK' | translate}}</a>
                            <a class="dropdown-item" [routerLink]="[prefix, 'principles']">{{'COMPANY_PRINCIPLES_LINK' | translate}}</a>
                            <a class="dropdown-item" [routerLink]="[prefix, 'our-team']">{{'COMPANY_OUR_TEAM_LINK' | translate}}</a>
                            <a class="dropdown-item" [routerLink]="[prefix, 'careers']">{{'COMPANY_CAREERS_LINK' | translate}}</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown" ngbDropdown>
                        <a preventDefault class="nav-link dropdown-toggle" id="fc2" ngbDropdownToggle>{{'SOLUTION_LINK' | translate}}</a>
                        <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="fc2">
                            <a class="dropdown-item" [routerLink]="[prefix, 'projects', 'rockawaybeachresort']">{{'SOLUTION_RBR_LINK' | translate}}</a>
                            <a class="dropdown-item" [routerLink]="[prefix, 'projects', 'promise']">ProMISe™</a>
                            <a class="dropdown-item" [routerLink]="[prefix, 'projects', 'iamsapien']">{{'SOLUTION_IAMSAPIEN_LINK' | translate}}</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown" ngbDropdown>
                        <a preventDefault class="nav-link dropdown-toggle" id="fc3" ngbDropdownToggle>{{'SERVICES_LINK' | translate}}</a>
                        <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="fc2">
                            <a class="dropdown-item" [routerLink]="[prefix, 'web-applications']">{{'SERVICES_WEB_APP_DEVELOPMENT_LINK' | translate}}</a>
                            <a class="dropdown-item" [routerLink]="[prefix, 'custom-software']" [innerHTML]="'SERVICES_CUSTOM_SOFTWARE_DEVELOPMENT_LINK' | translate"></a>
                            <a class="dropdown-item" [routerLink]="[prefix, 'it-consulting']">{{'SERVICES_IT_CONSULTING_LINK' | translate}}</a>
                            <a class="dropdown-item" [routerLink]="[prefix, 'cloud-computing']">{{'SERVICES_CLOUD_COMPUTING_LINK' | translate}}</a>
                        </div>
                    </li>
                    <li>
                        <a class="nav-link" [routerLink]="[prefix, 'contact-us']">{{'CONTACT_US_LINK' | translate}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link request-a-quote-link" [routerLink]="[prefix, 'request-a-quote']">{{'REQUEST_A_QUOTE_LINK' | translate}}</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-4 pull-right">
                <p>
                    © {{currentYear}} Renet Consulting, Inc.<br />
                    <span class="contacts phone">{{'CONTACT_US_FREE' | translate}} +1-800-367-9886</span>
                </p>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</footer>
