<nav id="mainNav" class="navbar navbar-light navbar-default navbar-expand-lg" [ngClass]="{ 'affix-top': !barFixed, affix: barFixed }" [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <div class="container">
       <div class="stop-war">
           <span>{{'STOP_WAR' | translate}}</span>
           <span>{{'IN_UKRAINE' | translate}}</span></div>
        <a title="{{'HEADER_LOGO_TITLE' | translate}}" class="navbar-brand pull-left" [routerLink]="[prefix, 'home']">
             <img src="/assets/images/Renet-Consulting-logo.png?v={{version}}" alt="{{'HEADER_LOGO_ALT' | translate}}" title="{{'HEADER_LOGO_TITLE' | translate}}" />
        </a>
        <button class="navbar-toggler navbar-toggler-right ml-auto" type="button" (click)="toggleCollapsed()">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav" [class.collapsed]="collapsed">
            <ul class="navbar-nav pull-right" *ngIf="collapsed || !isMobile">
                <li class="nav-item dropdown" ngbDropdown>
                    <a class="nav-link dropdown-toggle" id="dropdownBasic2" ngbDropdownToggle>{{'COMPANY_LINK' | translate}}</a>
                    <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownBasic2">
                        <a class="dropdown-item" (click)="toggleCollapsed()" [routerLink]="[prefix, 'about']">{{'COMPANY_ABOUT_LINK' | translate}}</a>
                        <a class="dropdown-item" (click)="toggleCollapsed()" [routerLink]="[prefix, 'history']">{{'COMPANY_HISTORY_LINK' | translate}}</a>
                        <a class="dropdown-item" (click)="toggleCollapsed()" [routerLink]="[prefix, 'principles']">{{'COMPANY_PRINCIPLES_LINK' | translate}}</a>
                        <a class="dropdown-item" (click)="toggleCollapsed()" [routerLink]="[prefix, 'our-team']">{{'COMPANY_OUR_TEAM_LINK' | translate}}</a>
                        <a class="dropdown-item" (click)="toggleCollapsed()" [routerLink]="[prefix, 'careers']">{{'COMPANY_CAREERS_LINK' | translate}}</a>
                    </div>
                </li>
                <li class="nav-item dropdown" ngbDropdown>
                    <a class="nav-link dropdown-toggle" id="dropdownBasic3" ngbDropdownToggle>{{'SOLUTION_LINK' | translate}}</a>
                    <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownBasic3">
                        <a class="dropdown-item" (click)="toggleCollapsed()" [routerLink]="[prefix, 'projects', 'rockawaybeachresort']">{{'SOLUTION_RBR_LINK' | translate}}</a>
                        <a class="dropdown-item" (click)="toggleCollapsed()" [routerLink]="[prefix, 'projects', 'promise']">ProMISe™</a>
                        <a class="dropdown-item" (click)="toggleCollapsed()" [routerLink]="[prefix, 'projects', 'iamsapien']">{{'SOLUTION_IAMSAPIEN_LINK' | translate}}</a>
                    </div>
                </li>
                <li class="nav-item dropdown" ngbDropdown>
                    <a class="nav-link dropdown-toggle" id="dropdownBasic4" ngbDropdownToggle>{{'SERVICES_LINK' | translate}}</a>
                    <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownBasic4">
                        <a class="dropdown-item" (click)="toggleCollapsed()" [routerLink]="[prefix, 'web-applications']">{{'SERVICES_WEB_APP_DEVELOPMENT_LINK' | translate}}</a>
                        <a class="dropdown-item" (click)="toggleCollapsed()" [routerLink]="[prefix, 'custom-software']" [innerHTML]="'SERVICES_CUSTOM_SOFTWARE_DEVELOPMENT_LINK' | translate"></a>
                        <a class="dropdown-item" (click)="toggleCollapsed()" [routerLink]="[prefix, 'it-consulting']">{{'SERVICES_IT_CONSULTING_LINK' | translate}}</a>
                        <a class="dropdown-item" (click)="toggleCollapsed()" [routerLink]="[prefix, 'cloud-computing']">{{'SERVICES_CLOUD_COMPUTING_LINK' | translate}}</a>
                    </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="toggleCollapsed()" [routerLink]="[prefix, 'contact-us']">{{'CONTACT_US_LINK' | translate}}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link request-a-quote-link" (click)="toggleCollapsed()" [routerLink]="[prefix, 'request-a-quote']">{{'REQUEST_A_QUOTE_LINK' | translate}}</a>
                </li>
                <li class="nav-item dropdown globe" ngbDropdown>
                    <a class="nav-link dropdown-toggle" title="{{'LANGUAGE_IMG_GLOBE_TITLE' | translate}}" id="dropdownBasic5" ngbDropdownToggle>
                        <img src="/assets/images/globe-icon.png?v={{version}}" *ngIf="!isMobile" alt="{{'LANGUAGE_IMG_GLOBE_ALT' | translate}}" title="{{'LANGUAGE_IMG_GLOBE_TITLE' | translate}}" />
                        <span *ngIf="isMobile">{{'GLOBE_ICON' | translate}}</span>
                    </a>
                    <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownBasic4">
                        <a class="dropdown-item" (click)="setLanguage('en');toggleCollapsed()">English</a>
                        <a class="dropdown-item" (click)="setLanguage('ru');toggleCollapsed()">Русский</a>
                        <a class="dropdown-item" (click)="setLanguage('ua');toggleCollapsed()">Українська</a>
                    </div>
                </li>
            </ul>
        </div>
        <div class="clearfix"></div>
    </div>
    <div class="clearfix"></div>
</nav>
<div class="clearfix"></div>
