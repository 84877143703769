import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class MetadataService {

    defaultTitle = 'angular.net';

    constructor(
        @Inject(Title) private title: Title,
        @Inject(Meta) private meta: Meta,
    ) { }

    updateTag = (value: { [name: string]: any }): void => {
        if (value != null) {
            const title = (value.title != null) ? value.title : this.defaultTitle;
            this.title.setTitle(title);
            const metaData: Array<MetaDefinition> = (Array.isArray(value.meta)) ? value.meta : [];
            metaData.forEach(x => this.meta.updateTag(x));
        }
    }
}
