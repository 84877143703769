import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { NgbCarouselModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ApiPrefixInterceptor, ExtractErrorInterceptor } from '@renet-consulting/interceptors';
import { NgxTrackerHttpService, NgxTrackerModule, NgxTrackerService } from '@renet-consulting/ngx-tracking-analytics';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { VERSION } from '~/tokens/version.token';
import { ROUTES } from './app.routes';
import { AboutComponent } from './components/about/about.component';
import { AngularComponent } from './components/angular/angular.component';
import { AppComponent } from './components/app/app.component';
import { CareersComponent } from './components/careers/careers.component';
import { CloudComputingComponent } from './components/cloud.computing/cloud.computing.component';
import { CustomSoftwareComponent } from './components/custom.software/custom.software.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HistoryComponent } from './components/history/history.component';
import { HomeComponent } from './components/home/home.component';
import { IamsapienComponent } from './components/iamsapien/iamsapien.component';
import { ITConsultingComponent } from './components/it.consulting/it.consulting.component';
import { NotFoundComponent } from './components/not.found/not.found.component';
import { OurTeamComponent } from './components/our.team/our.team.component';
import { PrinciplesComponent } from './components/principles/principles.component';
import { CFIComponent } from './components/profiles/alex.sirant/portfolio/cfi/cfi.component';
import { AEMComponent } from './components/profiles/helen.doroshenko/portfolio/aem/aem.component';
import { EcologyComponent } from './components/profiles/helen.doroshenko/portfolio/ecology/ecology.component';
import { OneginBluesComponent } from './components/profiles/helen.doroshenko/portfolio/onegin.blues/onegin.blues.component';
import { PromiseProjectComponent } from './components/profiles/helen.doroshenko/portfolio/promise/promise.component';
import { PulseElectronicsComponent } from './components/profiles/helen.doroshenko/portfolio/pulse.electronics/pulse.electronics.component';
import { YZPNComponent } from './components/profiles/helen.doroshenko/portfolio/yzpn/yzpn.component';
import { HelenDoroshenkoProfileComponent } from './components/profiles/helen.doroshenko/profile/helen.doroshenko.component';
import { PromiseComponent } from './components/promise/promise.component';
import { PwaUpdatesModule } from './components/pwa-updates/pwa-updates.module';
import { RockAwayBeachResortComponent } from './components/rockawaybeachresort/rockawaybeachresort.component';
import { TechnologiesComponent } from './components/technologies/technologies.component';
import { TranslateComponent } from './components/translate/translate.component';
import { WebApplicationsComponent } from './components/web.applications/web.applications.component';
import { PreventDefaultDirective } from './directives/prevent-default.directive';
import { AppSettingsService } from './services/app-settings.service';
import { InitService } from './services/init.service';
import { TrackerHttpService } from './services/tracker.http.service';
import { TranslateHttpService } from './services/translate-http.loader';
import { BASE_URL } from './tokens/base-url.token';
import { GzipInterceptor } from './services/gzip.interceptor';

const initFactory = (service: InitService) => () => service.init();

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        AboutComponent,
        AngularComponent,
        CareersComponent,
        CloudComputingComponent,
        CustomSoftwareComponent,
        FooterComponent,
        HeaderComponent,
        HistoryComponent,
        HomeComponent,
        IamsapienComponent,
        ITConsultingComponent,
        NotFoundComponent,
        OurTeamComponent,
        PrinciplesComponent,
        PromiseComponent,
        RockAwayBeachResortComponent,
        TechnologiesComponent,
        WebApplicationsComponent,
        TranslateComponent,
        HelenDoroshenkoProfileComponent,
        OneginBluesComponent,
        AEMComponent,
        EcologyComponent,
        YZPNComponent,
        PromiseProjectComponent,
        PulseElectronicsComponent,
        CFIComponent,
        PreventDefaultDirective
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ExtractErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, deps: [BASE_URL], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: GzipInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory: initFactory, deps: [InitService], multi: true },
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        CommonModule,
        HttpClientModule,
        NgbCarouselModule,
        NgbDropdownModule,
        NgxPageScrollModule,
        NgxPageScrollCoreModule.forRoot({ duration: AppSettingsService.duration }),
        RouterModule.forRoot(ROUTES, {
    useHash: false,
    preloadingStrategy: PreloadAllModules,
    initialNavigation: 'enabledNonBlocking',
    relativeLinkResolution: 'legacy'
}),
        TranslateModule.forRoot({
            loader: { provide: TranslateLoader, useClass: TranslateHttpService, deps: [HttpClient, VERSION] }
        }),
        NgxTrackerModule.forRoot({
            loader: { provide: NgxTrackerHttpService, useClass: TrackerHttpService, deps: [NgxTrackerService, HttpClient] }
        }),
        PwaUpdatesModule
    ],
})
export class AppSharedModule { }
