import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { Subscription } from 'rxjs';

@Component({
    selector: 'pwa-updates',
    templateUrl: './pwa-updates.component.html',
})
export class PwaUpdatesComponent implements OnInit, OnDestroy {

    @ViewChild('templateRef', { static: true }) templateRef: TemplateRef<any>;
    readonly subscription = new Subscription();

    constructor(
        @Inject(MatSnackBar) private snackBar: MatSnackBar,
        @Inject(SwUpdate) @Optional() private updates: SwUpdate,
        @Inject(DOCUMENT) private document: any
    ) { }

    ngOnInit(): void {
        if (this.updates) {
            this.subscription.add(this.updates.available.subscribe(this.prompt));
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    prompt = (): void => {
        this.snackBar.openFromTemplate(this.templateRef, { horizontalPosition: 'right', verticalPosition: 'top' });
    }

    update = () => this.updates && this.updates.activateUpdate().then(() => this.document.location.reload());
}
