import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NgxTrackerService, TrackerModel } from '@renet-consulting/ngx-tracking-analytics';

@Injectable({
    providedIn: 'root'
})
export class TrackerHttpService {

    readonly keyId: string = 'Encrypt-Key';
    readonly ivId: string = 'Encrypt-Iv';

    constructor(
        @Inject(NgxTrackerService) private trackerService: NgxTrackerService,
        @Inject(HttpClient) private http: HttpClient
    ) { }

    addTracker = (item: TrackerModel) => {
        const keyId: string = this.trackerService.randomValue;
        const ivId: string = this.trackerService.randomValue;
        const value: string = this.trackerService.encrypt(item, keyId, ivId);
        return this.http
            .get(`/api/ra?model=${value}`, {
                responseType: 'text',
                headers: new HttpHeaders({
                    [this.keyId]: keyId,
                    [this.ivId]: ivId
                })
            });
    }
}
