import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { TranslateBase } from '~/bases/translate.base';

@Component({
    selector: 'footer-menu',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends TranslateBase implements OnInit, OnDestroy {

    currentYear: number = new Date().getFullYear();
    isOpenMenu: boolean;

    constructor(
        injector: Injector
    ) {
        super(injector);
    }
}
