<section id="pageTitle"></section>
<section id="solution" class="portfolioContent" [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <div class="container-fluid">
        <div class="title-bg">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <h2 class="text-center black-text">{{'PULSE_ELECTRONICS_TITLE' | translate}}</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row d-flex align-items-stretch">
                <div class="col-lg-6 pull-left">
                    <p>&nbsp;</p>
                    <h3 class="thin-text">{{'THE_GOAL' | translate}}</h3>
                    <p>{{'PULSE_ELECTRONICS_GOAL_DESCRIPTION' | translate}}</p>
                    <p>&nbsp;</p>
                    <h3 class="thin-text">{{'THE_RESULT' | translate}}</h3>
                    <p>{{'PULSE_ELECTRONICS_DESCRIPTION_0' | translate}}</p>
                    <p>{{'PULSE_ELECTRONICS_DESCRIPTION_1' | translate}}</p>
                    <p>{{'PULSE_ELECTRONICS_DESCRIPTION_2' | translate}}</p>
                    <p>&nbsp;</p>
                </div>
                <div class="col-lg-6 pull-left text-right d-flex align-items-lg-end align-items-md-end align-items-sm-center flex-column">
                    <span class="text-center mt-auto d-block">
                        <a target="_blank" href="http://antennas.pulseelectronics.com/catalogue/" class="btn btn-secondary btn-lg">{{'GO_TO_WEBSITE_BUTTON' | translate}}</a>
                    </span>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</section>
<article>
    <!--content-->
    <section id="content" class="portfolio-container" [class.russian]="prefix === '/ru' || prefix === '/ua'" content="Renet Consulting example of a custom software">
        <img src="/assets/images/profiles/helen/portfolio/pulse-electronics.jpg?v={{version}}" alt="{{'PULSE_ELECTRONICS_ALT' | translate}}" />
        <div class="other-works text-center">
            <p>
                {{'SEE_PROFILE' | translate}} <a href="#" [routerLink]="[prefix, 'helen-doroshenko', 'profile']">{{'SEE_HELENAS_PROFILE' | translate}}</a> {{'SEE_PROFILE_2' | translate}}
                <button type="button"
                        class="btn btn-primary btn-lg wow pulse animated"
                        data-wow-delay="300ms"
                        data-wow-iteration="infinite"
                        data-wow-duration="2s"
                        [routerLink]="[prefix, 'contact-us']">
                    {{'CONTACT_BUTTON' | translate}}
                </button>
            </p>
        </div>
        <div class="clearfix"></div>
    </section>
    <!--END coontent-->
</article>
