import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PageScrollOptions, PageScrollService } from 'ngx-page-scroll-core';
import { IS_BROWSER } from '../tokens/is-browser.token';

@Injectable({
    providedIn: 'root'
})
export class ScrollingService {

    constructor(
        @Inject(IS_BROWSER) private isBrowser: boolean,
        @Inject(Router) private router: Router,
        @Inject(DOCUMENT) private document: any,
        @Inject(PageScrollService) private pageScrollService: PageScrollService,
    ) { }

    scrollTo = (options: Partial<PageScrollOptions>) => this.isBrowser && this.pageScrollService.scroll({
        ...options as PageScrollOptions,
        document: this.document
    })

    scrollToAsync = (options: Partial<PageScrollOptions>) => setTimeout(() => this.scrollTo(options), 4);

    scrollToPageAsync = (link, options: Partial<PageScrollOptions>) => {
        this.router.navigate([link]).then(() => this.scrollToAsync(options));
    }
}
