<section id="pageTitle"></section>
<section id="solution" class="portfolioContent" [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <div class="container">
        <div class="row d-flex align-items-stretch">
            <div class="col-lg-6 pull-left">
                <h2 class="text-center black-text">{{'YZPN_TITLE' | translate}}</h2>
                <h3 class="thin-text">{{'THE_GOAL' | translate}}</h3>
                <p>{{'YZPN_GOAL_DESCRIPTION' | translate}}</p>
                <p>&nbsp;</p>
                <h3 class="thin-text">{{'THE_RESULT' | translate}}</h3>
                <p>{{'YZPN_DESCRIPTION_0' | translate}}</p>
                <p>{{'YZPN_DESCRIPTION_1' | translate}}</p>
                <p>{{'YZPN_DESCRIPTION_2' | translate}}</p>
                <p>{{'YZPN_DESCRIPTION_3' | translate}}</p>
                <p>&nbsp;</p>
            </div>
            <div class="col-lg-6 pull-left wow bounceInRight d-flex align-items-end justify-content-center" data-wow-delay=".3s">
                <span class="text-center">
                    <a target="_blank" href="http://yzpntile.com/" class="btn btn-secondary btn-lg">{{'GO_TO_WEBSITE_BUTTON' | translate}}</a>
                </span>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</section>
<article>
    <!--content-->
    <section id="content" class="portfolio-container" [class.russian]="prefix === '/ru' || prefix === '/ua'" content="Renet Consulting example of a custom software">
        <div class="container">
            <video autoplay loop muted>
                <source src="/assets/video/yzpn.mp4" type="video/mp4" />
            </video>
            <img src="/assets/images/profiles/helen/portfolio/yzpn.jpg?v={{version}}" alt="{{'YZPN_TITLE' | translate}}" />
            <img src="/assets/images/profiles/helen/portfolio/yzpn-pages.png?v={{version}}" alt="{{'YZPN_TITLE' | translate}}" />
            <img src="/assets/images/profiles/helen/portfolio/yzpn-devices.png?v={{version}}" class="devices d-xs-none wow bounceInRight" data-wow-delay=".4s" alt="{{'YZPN_TITLE' | translate}}" />
            <div class="other-works text-lg-center text-md-left text-sm-left">
                <p>
                    {{'SEE_PROFILE' | translate}} <a href="#" [routerLink]="[prefix, 'helen-doroshenko', 'profile']">{{'SEE_HELENAS_PROFILE' | translate}}</a> {{'SEE_PROFILE_2' | translate}}
                    <button type="button"
                            class="btn btn-primary btn-lg wow pulse animated"
                            data-wow-delay="300ms"
                            data-wow-iteration="infinite"
                            data-wow-duration="2s"
                            [routerLink]="[prefix, 'contact-us']">
                        {{'CONTACT_BUTTON' | translate}}
                    </button>
                </p>
            </div>
        </div>
        <div class="clearfix"></div>
    </section>
    <!--END coontent-->
</article>
