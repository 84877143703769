<section id="pageTitle" class="safariHeader" [class.russian]="prefix === '/ru' || prefix === '/ua'">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h1>
                    <span class="blue-text">{{'WEBAPPLICATIONS_TITLE' | translate}}</span><br /><br />
                    {{'WEBAPPLICATIONS_TITLE_2' | translate}}
                </h1>
                <div class="wow zoomIn" data-wow-duration="1s" data-wow-offset="100">
                    <div class="wow tada" data-wow-delay="1s" data-wow-duration="1s">
                        <div class="wow rubberBand" data-wow-delay="2000ms" data-wow-duration="1s">
                            <button type="button"
                                    class="btn btn-primary btn-lg wow pulse animated"
                                    data-wow-delay="300ms"
                                    data-wow-iteration="infinite"
                                    data-wow-duration="2s"
                                    [routerLink]="[prefix, 'contact-us']">
                                {{'CONTACT_BUTTON' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
</section>

<!--content-->
<section id="content" class="webApplication" [class.russian]="prefix === '/ru' || prefix === '/ua'" content="Renet Consulting builds custom web applications">
    <article>
        <div class="container">
            <h2 class="text-center">{{'WEBAPPLICATIONS_UNCOVERING_BETTER_WAYS_H2' | translate}}</h2>
            <br />
            <h3 class="black-text text-center">{{'WEBAPPLICATIONS_WE_HAVE_COME_TO_VALUE_H3' | translate}}</h3>
            <br />
            <br />
            <div class="row">
                <div class="col-lg-12">
                    <div class="row web-application-content wow bounceInLeft block-box" data-wow-delay=".2s">
                        <div class="col-lg-2 col-md-12 text-center pull-left">
                            <img src="/assets/images/web-application-img.png?v={{version}}"
                                 alt="{{'WEBAPPLICATIONS_IMG_WEB_APPLICATION_ALT' | translate}}"
                                 title="{{'WEBAPPLICATIONS_IMG_WEB_APPLICATION_TITLE' | translate}}" />
                        </div>
                        <div class="col-lg-10 col-md-12 pull-left">
                            <h3 class="black-text thin-text" [innerHTML]="'WEBAPPLICATIONS_WEB_SITES_H3' | translate"></h3>
                        </div>
                    </div>
                    <p>&nbsp;</p>
                    <p>{{'WEBAPPLICATIONS_WEB_SITES_P' | translate}}</p>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </article>
    <div class="clearfix"></div>
    <div class="hr-line"></div>
    <div class="clearfix"></div>
    <div class="container">
        <div class="row block-box">
            <div class="col-lg-12">
                <h3 class="black-text text-center">{{'WEBAPPLICATIONS_GOOD_WEB_APPLICATION_SHOULD_H3' | translate}}</h3>
                <br />
                <div class="row block-box">
                    <div class="col-md-4 pull-left">
                        <ul>
                            <li>{{'WEBAPPLICATIONS_GOOD_WEB_APPLICATION_SHOULD_LIST_1' | translate}}</li>
                            <li>{{'WEBAPPLICATIONS_GOOD_WEB_APPLICATION_SHOULD_LIST_2' | translate}}</li>
                        </ul>
                    </div>
                    <div class="col-md-4 pull-left">
                        <ul>
                            <li>{{'WEBAPPLICATIONS_GOOD_WEB_APPLICATION_SHOULD_LIST_3' | translate}}</li>
                            <li>{{'WEBAPPLICATIONS_GOOD_WEB_APPLICATION_SHOULD_LIST_4' | translate}}</li>
                        </ul>
                    </div>
                    <div class="col-md-4 pull-left">
                        <ul>
                            <li>{{'WEBAPPLICATIONS_GOOD_WEB_APPLICATION_SHOULD_LIST_5' | translate}}</li>
                            <li>{{'WEBAPPLICATIONS_GOOD_WEB_APPLICATION_SHOULD_LIST_6' | translate}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="hr-line"></div>
    <div class="clearfix"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <p>{{'WEBAPPLICATIONS_WHETHER_YOU_ALREADY_HAVE' | translate}}</p>
                <p>&nbsp;</p>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="bottom-banner text-center">
        <div class="container">
            <h5>{{'WEBAPPLICATIONS_NO_PROJECT_IS_TOO_SMALL_H5' | translate}}</h5>
        </div>
    </div>
</section>
<!--END coontent-->
